import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-3 rounded-lg shadow-xl border border-slate-700">
        <p className="text-teal-300 font-medium mb-1">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} className="text-sm text-teal-300/90 py-0.5">
            {entry.name}: {entry.value}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const MixBarChart = ({ data }) => {
  const { gradient, theme } = useSidebarContext();
  return (
    <div className={`w-full h-[300px] ${gradient} rounded-xl p-4 shadow-lg`}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={theme.mui.light} opacity={0.2} />
          <XAxis
            dataKey="name"
            tick={{ fill: theme.mui.main }}
            axisLine={{ stroke: theme.mui.light }}
          />
          <YAxis tick={{ fill: theme.mui.main }} axisLine={{ stroke: theme.mui.light }} />
          <Tooltip content={<CustomTooltip />} />

          <Bar
            dataKey="totalSoldPlots"
            name="Sold Plots"
            fill="#06b6d4"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="totalBookedPlots"
            name="Booked Plots"
            fill="#14b8a6"
            radius={[4, 4, 0, 0]}
          />
          <Bar
            dataKey="totalCustomers"
            name="Total Customers"
            fill="#0ea5e9"
            radius={[4, 4, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MixBarChart;
