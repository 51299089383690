import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchEntries,
  createEntry,
  updateExistingEntry,
  removeEntry,
  fetchAllAccounts
} from '../store/slices/khataSlice'
import { format } from 'date-fns'
import colors from '../theme/colors'

const KhataBook = () => {
  const dispatch = useDispatch()
  const khataState = useSelector(state => state.khata) || {
    entries: [],
    accounts: [],
    loading: false
  }
  const { entries = [], accounts = [], loading = false } = khataState

  const [khataBy, setKhataBy] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [showNewAccountForm, setShowNewAccountForm] = useState(false)
  const [newAccountName, setNewAccountName] = useState('')
  const [formData, setFormData] = useState({
    khataBy: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    detail: '',
    credit: 0,
    debit: 0,
    remaining: 0
  })
  const [editMode, setEditMode] = useState(false)
  const [editId, setEditId] = useState(null)
  const [viewMode, setViewMode] = useState('accounts') // "accounts" or "entries"

  // Load all accounts when the component mounts
  useEffect(() => {
    dispatch(fetchAllAccounts())
  }, [dispatch])

  // Load entries for a specific account when khataBy changes
  useEffect(() => {
    if (khataBy && viewMode === 'entries') {
      dispatch(fetchEntries(khataBy))
    }
  }, [dispatch, khataBy, viewMode])

  // Search for an account
  const handleSearch = e => {
    e.preventDefault()
    if (searchQuery) {
      // Check if account exists
      const accountExists = accounts.some(
        account => account.name.toLowerCase() === searchQuery.toLowerCase()
      )

      if (accountExists) {
        setKhataBy(searchQuery)
        setViewMode('entries')
      } else {
        // Ask to create new account
        setNewAccountName(searchQuery)
        setShowNewAccountForm(true)
      }
    }
  }

  // Create a new account
  const handleCreateNewAccount = e => {
    e.preventDefault()

    // Instead of creating the account immediately, set up the form
    setKhataBy(newAccountName)
    setViewMode('entries')
    setShowNewAccountForm(false)
    setNewAccountName('')

    // Show the form with focus on credit/debit fields
    setFormData({
      khataBy: newAccountName,
      date: format(new Date(), 'yyyy-MM-dd'),
      detail: 'Account opened',
      credit: 0,
      debit: 0,
      remaining: 0
    })
    setShowForm(true)
  }

  // Select an existing account
  const handleAccountSelect = accountName => {
    setKhataBy(accountName)
    setViewMode('entries')
  }

  // Go back to accounts list
  const handleBackToAccounts = () => {
    setViewMode('accounts')
    setKhataBy('')
    setSearchQuery('')
  }

  // Handle form input changes
  const handleInputChange = e => {
    const { name, value } = e.target
    let updatedFormData = { ...formData, [name]: value }

    // Calculate remaining amount for new transaction
    if (name === 'credit' || name === 'debit') {
      const lastEntry =
        entries.length > 0 ? entries[entries.length - 1] : { remaining: 0 }
      const credit =
        name === 'credit'
          ? parseFloat(value) || 0
          : parseFloat(formData.credit) || 0
      const debit =
        name === 'debit'
          ? parseFloat(value) || 0
          : parseFloat(formData.debit) || 0

      let newRemaining
      if (editMode) {
        const currentEntry = entries.find(entry => entry._id === editId)
        const previousRemaining = currentEntry
          ? currentEntry.remaining - currentEntry.credit + currentEntry.debit
          : lastEntry.remaining
        newRemaining = previousRemaining + credit - debit
      } else {
        newRemaining = lastEntry.remaining + credit - debit
      }

      updatedFormData.remaining = parseFloat(newRemaining.toFixed(2))
    }

    setFormData(updatedFormData)
  }

  // Submit the form (create or update entry)
  const handleSubmit = async e => {
    e.preventDefault()
    try {
      if (editMode) {
        await dispatch(
          updateExistingEntry({
            id: editId,
            updatedEntry: formData
          })
        ).unwrap()
      } else {
        const entryData = { ...formData, khataBy }
        await dispatch(createEntry(entryData)).unwrap()
      }

      // Refresh entries after the state has been updated
      await dispatch(fetchEntries(khataBy))
      // Update accounts list
      await dispatch(fetchAllAccounts())

      resetForm()
    } catch (error) {
      console.error('Error handling form submit:', error)
    }
  }

  // Edit an entry
  const handleEdit = entry => {
    setFormData({
      khataBy: entry.khataBy,
      date: format(new Date(entry.date), 'yyyy-MM-dd'),
      detail: entry.detail,
      credit: entry.credit,
      debit: entry.debit,
      remaining: entry.remaining
    })
    setEditMode(true)
    setEditId(entry._id)
    setShowForm(true)
  }

  // Delete an entry
  const handleDelete = async id => {
    if (window.confirm('Are you sure you want to delete this entry?')) {
      try {
        await dispatch(removeEntry(id))
        // Refresh entries list after deleting
        await dispatch(fetchEntries(khataBy))
        // Also update accounts list to reflect the new balance
        await dispatch(fetchAllAccounts())
      } catch (error) {
        console.error('Error deleting entry:', error)
      }
    }
  }

  // Reset form
  const resetForm = () => {
    setFormData({
      khataBy: '',
      date: format(new Date(), 'yyyy-MM-dd'),
      detail: '',
      credit: 0,
      debit: 0,
      remaining: 0
    })
    setShowForm(false)
    setEditMode(false)
    setEditId(null)
  }

  // Filter entries based on search query
  const filteredEntries = entries.filter(entry =>
    entry.detail.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // Filter accounts based on search query
  const filteredAccounts = accounts.filter(account =>
    account.name.toLowerCase().includes(searchQuery.toLowerCase())
  )

  // Calculate current balance
  const calculateBalance = () => {
    if (entries.length === 0) return 0
    return entries[entries.length - 1].remaining
  }

  // Calculate totals
  const totalCredit = entries.reduce((sum, entry) => sum + entry.credit, 0)
  const totalDebit = entries.reduce((sum, entry) => sum + entry.debit, 0)

  return (
    <div
      className={`min-h-screen bg-gradient-to-br ${colors.bgGradientFrom} ${colors.bgGradientVia} ${colors.bgGradientTo} ${colors.textPrimary}`}
    >
      <div className='container mx-auto px-4 py-8'>
        {/* Header Section with Logo and Search */}
        <div className='flex flex-col md:flex-row justify-between items-center mb-8'>
          <h1
            className={`text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} mb-4 md:mb-0`}
          >
            Khata Book
          </h1>

          <form onSubmit={handleSearch} className='w-full md:w-auto flex'>
            <input
              type='text'
              placeholder={
                viewMode === 'accounts'
                  ? 'Search or create account'
                  : 'Search transactions'
              }
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              className={`px-4 py-2 rounded-l-lg ${colors.cardBg} border ${colors.inputBorder} ${colors.textPrimary} focus:outline-none focus:ring-2 ${colors.inputFocusRing} w-full md:w-64`}
            />
            <button
              type='submit'
              className={`px-4 py-2 bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} rounded-r-lg font-medium hover:opacity-90 transition-opacity`}
            >
              {viewMode === 'accounts' ? 'Find/Create' : 'Search'}
            </button>
          </form>
        </div>

        {/* New Account Form */}
        {showNewAccountForm && (
          <div
            className={`mb-8 ${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl p-6 shadow-2xl border ${colors.cardBorder}`}
          >
            <h2
              className={`text-2xl font-semibold mb-4 ${colors.textHighlight}`}
            >
              Create New Account
            </h2>
            <p className='mb-4'>
              Account "{newAccountName}" does not exist yet. Would you like to
              create it?
            </p>

            <div className='flex gap-4'>
              <button
                onClick={handleCreateNewAccount}
                className={`px-6 py-2 bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} rounded-lg font-medium hover:opacity-90 transition-opacity`}
              >
                Create Account
              </button>

              <button
                onClick={() => {
                  setShowNewAccountForm(false)
                  setNewAccountName('')
                }}
                className={`px-6 py-2 bg-gradient-to-r ${colors.btnSecondaryFrom} ${colors.btnSecondaryTo} rounded-lg font-medium hover:opacity-90 transition-opacity`}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Entries View */}
        {viewMode === 'entries' && khataBy && (
          <div className='mb-8'>
            <button
              onClick={handleBackToAccounts}
              className={`mb-4 px-4 py-2 ${colors.cardBg} ${colors.cardBgOpacity} border ${colors.inputBorder} rounded-lg font-medium hover:${colors.cardBgHover} transition-opacity flex items-center`}
            >
              <span>← Back to Accounts</span>
            </button>

            <div
              className={`${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl p-6 shadow-2xl border ${colors.cardBorder}`}
            >
              <div className='flex flex-col md:flex-row justify-between items-center mb-6'>
                <h2 className='text-2xl font-semibold mb-4 md:mb-0'>
                  Account:{' '}
                  <span className={colors.textHighlight}>{khataBy}</span>
                </h2>
                <div className='grid grid-cols-3 gap-4 w-full md:w-auto'>
                  <div
                    className={`${colors.cardBg} p-3 rounded-lg text-center`}
                  >
                    <p className={colors.textMuted}>Total Credit</p>
                    <p className={colors.positive}>
                      Rs.{totalCredit.toFixed(2)}
                    </p>
                  </div>
                  <div
                    className={`${colors.cardBg} p-3 rounded-lg text-center`}
                  >
                    <p className={colors.textMuted}>Total Debit</p>
                    <p className={colors.negative}>
                      Rs.{totalDebit.toFixed(2)}
                    </p>
                  </div>
                  <div
                    className={`${colors.cardBg} p-3 rounded-lg text-center`}
                  >
                    <p className={colors.textMuted}>Balance</p>
                    <p
                      className={`font-bold ${colors.getBalanceColor(
                        calculateBalance()
                      )}`}
                    >
                      Rs.{calculateBalance().toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              <button
                onClick={() => setShowForm(!showForm)}
                className={`px-4 py-2 bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} rounded-lg font-medium hover:opacity-90 transition-opacity`}
              >
                {showForm ? 'Cancel' : 'Add New Entry'}
              </button>

              {showForm && (
                <form
                  onSubmit={handleSubmit}
                  className={`mt-6 p-6 ${colors.cardBg} ${colors.cardBgOpacity} rounded-lg border ${colors.inputBorder}`}
                >
                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6'>
                    <div>
                      <label
                        className={`block text-sm font-medium mb-2 ${colors.textSecondary}`}
                      >
                        Date
                      </label>
                      <input
                        type='date'
                        name='date'
                        value={formData.date}
                        onChange={handleInputChange}
                        className={`w-full px-4 py-2 rounded-lg ${colors.inputBg} border ${colors.inputBorder} ${colors.textPrimary} ${colors.inputFocusBorder} focus:ring-1 ${colors.inputFocusRing} transition-all`}
                        required
                      />
                    </div>
                    <div>
                      <label
                        className={`block text-sm font-medium mb-2 ${colors.textSecondary}`}
                      >
                        Detail
                      </label>
                      <input
                        type='text'
                        name='detail'
                        value={formData.detail}
                        onChange={handleInputChange}
                        placeholder='Transaction details'
                        className={`w-full px-4 py-2 rounded-lg ${colors.inputBg} border ${colors.inputBorder} ${colors.textPrimary} ${colors.inputFocusBorder} focus:ring-1 ${colors.inputFocusRing} transition-all`}
                        required
                      />
                    </div>
                    <div>
                      <label
                        className={`block text-sm font-medium mb-2 ${colors.textSecondary}`}
                      >
                        Credit (Rs.)
                      </label>
                      <input
                        type='number'
                        name='credit'
                        value={formData.credit}
                        onChange={handleInputChange}
                        placeholder='0'
                        className={`w-full px-4 py-2 rounded-lg ${colors.inputBg} border ${colors.inputBorder} ${colors.textPrimary} ${colors.inputFocusBorder} focus:ring-1 ${colors.inputFocusRing} transition-all`}
                      />
                    </div>
                    <div>
                      <label
                        className={`block text-sm font-medium mb-2 ${colors.textSecondary}`}
                      >
                        Debit (Rs.)
                      </label>
                      <input
                        type='number'
                        name='debit'
                        value={formData.debit}
                        onChange={handleInputChange}
                        placeholder='0'
                        className={`w-full px-4 py-2 rounded-lg ${colors.inputBg} border ${colors.inputBorder} ${colors.textPrimary} ${colors.inputFocusBorder} focus:ring-1 ${colors.inputFocusRing} transition-all`}
                      />
                    </div>
                    <div>
                      <label
                        className={`block text-sm font-medium mb-2 ${colors.textSecondary}`}
                      >
                        Remaining (Rs.)
                      </label>
                      <input
                        type='number'
                        name='remaining'
                        value={formData.remaining}
                        readOnly
                        className={`w-full px-4 py-2 rounded-lg ${colors.inputBgDisabled} border ${colors.inputBorder} ${colors.textPrimary} cursor-not-allowed`}
                      />
                    </div>
                  </div>
                  <button
                    type='submit'
                    className={`px-6 py-2 bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} rounded-lg font-medium hover:opacity-90 transition-opacity`}
                  >
                    {editMode ? 'Update Entry' : 'Save Entry'}
                  </button>
                </form>
              )}
            </div>
          </div>
        )}

        {/* Loading State */}
        {loading ? (
          <div className='flex justify-center items-center py-20'>
            <div
              className={`animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-teal-500`}
            ></div>
          </div>
        ) : viewMode === 'entries' && khataBy ? (
          entries.length > 0 ? (
            <div
              className={`overflow-x-auto ${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl shadow-2xl border ${colors.cardBorder}`}
            >
              <table className='w-full'>
                <thead>
                  <tr
                    className={`border-b ${colors.tableBorderColor} ${colors.tableHeaderBg} ${colors.cardBgOpacity}`}
                  >
                    <th
                      className={`px-6 py-4 text-left text-xs font-medium ${colors.textSecondary} uppercase tracking-wider`}
                    >
                      Date
                    </th>
                    <th
                      className={`px-6 py-4 text-left text-xs font-medium ${colors.textSecondary} uppercase tracking-wider`}
                    >
                      Detail
                    </th>
                    <th
                      className={`px-6 py-4 text-right text-xs font-medium ${colors.textSecondary} uppercase tracking-wider`}
                    >
                      Credit (Rs.)
                    </th>
                    <th
                      className={`px-6 py-4 text-right text-xs font-medium ${colors.textSecondary} uppercase tracking-wider`}
                    >
                      Debit (Rs.)
                    </th>
                    <th
                      className={`px-6 py-4 text-right text-xs font-medium ${colors.textSecondary} uppercase tracking-wider`}
                    >
                      Remaining (Rs.)
                    </th>
                    <th
                      className={`px-6 py-4 text-center text-xs font-medium ${colors.textSecondary} uppercase tracking-wider`}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className={`divide-y ${colors.tableBorderColor}`}>
                  {filteredEntries.map(entry => (
                    <tr
                      key={entry._id}
                      className={`${colors.tableRowHover} transition-colors`}
                    >
                      <td className='px-6 py-4 whitespace-nowrap'>
                        {format(new Date(entry.date), 'dd MMM yyyy')}
                      </td>
                      <td className='px-6 py-4'>{entry.detail}</td>
                      <td
                        className={`px-6 py-4 text-right ${colors.positive} font-medium`}
                      >
                        {entry.credit > 0 ? entry.credit.toFixed(2) : '-'}
                      </td>
                      <td
                        className={`px-6 py-4 text-right ${colors.negative} font-medium`}
                      >
                        {entry.debit > 0 ? entry.debit.toFixed(2) : '-'}
                      </td>
                      <td
                        className={`px-6 py-4 text-right font-semibold ${colors.getBalanceColor(
                          entry.remaining
                        )}`}
                      >
                        {entry.remaining.toFixed(2)}
                      </td>
                      <td className='px-6 py-4 whitespace-nowrap text-center'>
                        <button
                          onClick={() => handleEdit(entry)}
                          className='text-teal-400 hover:text-teal-300 mr-3 transition-colors'
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(entry._id)}
                          className='text-red-400 hover:text-red-300 transition-colors'
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className={`${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl p-8 text-center shadow-2xl border ${colors.cardBorder}`}
            >
              <p className={`text-xl mb-4 ${colors.textHighlight}`}>
                No entries found for {khataBy}.
              </p>
              <button
                onClick={() => setShowForm(true)}
                className={`mt-4 px-6 py-3 bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} rounded-lg font-medium hover:opacity-90 transition-opacity`}
              >
                Add First Entry
              </button>
            </div>
          )
        ) : viewMode === 'accounts' ? (
          <div>
            <h2
              className={`text-2xl font-semibold mb-6 ${colors.textHighlight}`}
            >
              Your Accounts
            </h2>

            {filteredAccounts.length > 0 ? (
              <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                {filteredAccounts.map(account => (
                  <div
                    key={account.name}
                    onClick={() => handleAccountSelect(account.name)}
                    className={`${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl p-6 border ${colors.cardBorder} shadow-2xl transition-all cursor-pointer hover:${colors.cardBgHover} hover:${colors.cardBorderHover} transform hover:-translate-y-1`}
                  >
                    <h3
                      className={`text-xl font-semibold mb-4 ${colors.textHighlight}`}
                    >
                      {account.name}
                    </h3>
                    <div
                      className={`mb-4 pb-4 border-b ${colors.tableBorderColor}`}
                    >
                      <div className='flex justify-between items-center'>
                        <span className={colors.textSecondary}>Balance:</span>
                        <span
                          className={`text-lg font-bold ${colors.getBalanceColor(
                            account.balance
                          )}`}
                        >
                          Rs.{account.balance.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className='grid grid-cols-2 gap-2 text-sm'>
                      <div>
                        <p className={`${colors.textMuted} mb-1`}>
                          Total Entries
                        </p>
                        <p className='text-teal-400 font-medium'>
                          {account.totalEntries}
                        </p>
                      </div>
                      <div>
                        <p className={`${colors.textMuted} mb-1`}>
                          Last Update
                        </p>
                        <p className={colors.textSecondary}>
                          {format(new Date(account.lastUpdate), 'dd MMM yyyy')}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className={`${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl p-8 text-center shadow-2xl border ${colors.cardBorder}`}
              >
                <p className={`text-xl mb-4 ${colors.textHighlight}`}>
                  No accounts found.
                </p>
                <p className={`${colors.textSecondary} mb-6`}>
                  Create your first account by entering an account name in the
                  search box above
                </p>
              </div>
            )}
          </div>
        ) : (
          <div
            className={`${colors.cardBg} ${colors.cardBgOpacity} backdrop-filter backdrop-blur-sm rounded-xl p-12 text-center shadow-2xl border ${colors.cardBorder}`}
          >
            <div className='max-w-md mx-auto'>
              <p className={`text-3xl mb-4 font-bold ${colors.textHighlight}`}>
                Welcome to your Khata Book!
              </p>
              <p className={`${colors.textSecondary} mb-8`}>
                Your digital ledger for tracking all your accounts and
                transactions
              </p>
              <form onSubmit={handleSearch} className='flex'>
                <input
                  type='text'
                  placeholder='Enter account name'
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  className={`px-4 py-3 rounded-l-lg ${colors.inputBg} border ${colors.inputBorder} ${colors.textPrimary} focus:outline-none focus:ring-2 ${colors.inputFocusRing} w-full`}
                />
                <button
                  type='submit'
                  className={`px-6 py-3 bg-gradient-to-r ${colors.btnPrimaryFrom} ${colors.btnPrimaryTo} rounded-r-lg font-medium hover:opacity-90 transition-opacity`}
                >
                  Find/Create
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      <footer
        className={`mt-12 py-6 text-center ${colors.textMuted} text-sm border-t ${colors.tableBorderColor}`}
      >
        <div className={`container mx-auto px-4  ${colors.textFooter}`}>
          <p>
            Khata Book &copy; {new Date().getFullYear()} | Your Digital Ledger
            by Autorobos
          </p>
        </div>
      </footer>
    </div>
  )
}

export default KhataBook
