import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import messageApi from "../API/messageApi";

const canChat = (activeUserRole, targetUserRole) => {
  const roleAccess = {
    Admin: ["Admin", "Employee", "Customer", "Investor"],
    Customer: ["Admin", "Employee"],
    Employee: ["Admin", "Employee", "Customer"],
    Investor: ["Admin"],
  };

  return roleAccess[activeUserRole]?.includes(targetUserRole) || false;
};

// Async thunk for fetching filtered users based on role
export const fetchFilteredUsers = createAsyncThunk(
  "messages/fetchFilteredUsers",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const activeUser = state.auth.activeUser;
      const allUsers = state.auth.users;

      if (!activeUser || !allUsers) {
        throw new Error("User data not available");
      }

      // Filter users based on role access
      const filteredUsers = allUsers.filter(
        (user) =>
          user._id !== activeUser._id && // Exclude self
          user.status === "active" && // Only active users
          canChat(activeUser.role, user.role) // Check role-based access
      );

      return filteredUsers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendMessage = createAsyncThunk(
  "messages/sendMessage",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const sender = state.auth.activeUser;
      const receiver = state.auth.users.find((u) => u._id === data.receiver);

      if (!sender || !receiver) {
        throw new Error("Sender or receiver not found");
      }

      if (!canChat(sender.role, receiver.role)) {
        throw new Error("You don't have permission to message this user");
      }

      const response = await messageApi.sendMessageWithAttachments(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendImageMessage = createAsyncThunk(
  "messages/sendImageMessage",
  async (data, { rejectWithValue }) => {
    try {
      const response = await messageApi.sendCapturedImage(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to send image message"
      );
    }
  }
);

export const fetchConversation = createAsyncThunk(
  "messages/fetchConversation",
  async ({ userId, page = 1, limit = 50 }, { rejectWithValue }) => {
    try {
      const response = await messageApi.getConversation(userId, page, limit);
      return { ...response, userId };
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch conversation"
      );
    }
  }
);

export const fetchAllMessages = createAsyncThunk(
  "messages/fetchAllMessages",
  async (_, { rejectWithValue }) => {
    try {
      const response = await messageApi.getAllMessages();
      return response.messages;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch all messages"
      );
    }
  }
);

// Initial state
const initialState = {
  conversations: {},
  filteredUsers: [],
  currentConversation: null,
  loading: false,
  error: null,
  search: {
    query: "",
    results: [],
  },
  pagination: {
    page: 1,
    limit: 50,
    hasMore: true,
  },
};

// Slice
const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.search.query = action.payload;
      state.search.results = state.filteredUsers.filter((user) =>
        user.username.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
    clearSearch: (state) => {
      state.search.query = "";
      state.search.results = [];
    },

    setCurrentConversation(state, action) {
      state.currentConversation = action.payload;
    },
    addNewMessages(state, action) {
      const { userId, messages } = action.payload;
      if (!state.conversations[userId]) {
        state.conversations[userId] = [];
      }
      // Add new messages and remove duplicates based on message ID
      const existingIds = new Set(
        state.conversations[userId].map((msg) => msg._id)
      );
      const uniqueNewMessages = messages.filter(
        (msg) => !existingIds.has(msg._id)
      );
      state.conversations[userId].push(...uniqueNewMessages);
      // Sort messages by timestamp
      state.conversations[userId].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    },
    resetMessageState(state) {
      return initialState;
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Send Message
      .addCase(fetchFilteredUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFilteredUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.filteredUsers = action.payload;
        state.error = null;
      })
      .addCase(fetchFilteredUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Handle sendMessage
      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        const { receiver } = action.payload;
        if (!state.conversations[receiver]) {
          state.conversations[receiver] = [];
        }
        state.conversations[receiver].push(action.payload);
      })
      .addCase(sendMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Send Image Message
      .addCase(sendImageMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendImageMessage.fulfilled, (state, action) => {
        state.loading = false;
        const { receiver } = action.payload;
        if (!state.conversations[receiver]) {
          state.conversations[receiver] = [];
        }
        state.conversations[receiver].push(action.payload);
      })
      .addCase(sendImageMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch Conversation
      .addCase(fetchConversation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchConversation.fulfilled, (state, action) => {
        state.loading = false;
        const { messages, userId, page, limit } = action.payload;
        state.conversations[userId] = messages;
        state.pagination = {
          page,
          limit,
          hasMore: messages.length === limit,
        };
      })
      .addCase(fetchConversation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Fetch All Messages
      .addCase(fetchAllMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllMessages.fulfilled, (state, action) => {
        state.loading = false;
        state.allMessages = action.payload;
      })
      .addCase(fetchAllMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions
export const {
  setCurrentConversation,
  addNewMessages,
  resetMessageState,
  clearError,
  setSearchQuery,
  clearSearch,
} = messageSlice.actions;

// Selectors
export const selectCurrentConversation = (state) =>
  state.messages.currentConversation;

export const selectConversationMessages = (state, userId) =>
  state.messages.conversations[userId] || [];

export const selectAllMessages = (state) => state.messages.allMessages;

export const selectMessageLoading = (state) => state.messages.loading;

export const selectMessageError = (state) => state.messages.error;

export const selectPagination = (state) => state.messages.pagination;
export const selectFilteredUsers = (state) => state.messages.filteredUsers;
export const selectSearchResults = (state) => state.messages.search.results;
export const selectCanChatWithUser = (state, targetUserId) => {
  const activeUser = state.auth.activeUser;
  const targetUser = state.auth.users.find((u) => u._id === targetUserId);
  if (!activeUser || !targetUser) return false;
  return canChat(activeUser.role, targetUser.role);
};
// Export reducer
export default messageSlice.reducer;
