import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Building,
  Home,
  Edit,
  Settings,
  Mail,
  Phone,
  Lock,
  Key,
  EyeOff,
  Eye,
  Check,
  X
} from 'lucide-react'
import {
  checkActiveUser,
  resetPassword,
  updateUserInfo
} from '../../store/slices/authSlice'
import { fetchSocieties } from '../../store/slices/societySlice'
import { Transition } from '@headlessui/react'
import Swal from 'sweetalert2'

const PasswordStrengthIndicator = ({ password }) => {
  const hasLength = password.length >= 8
  const hasUpperCase = /[A-Z]/.test(password)
  const hasLowerCase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password)

  const calculateStrength = () => {
    let strength = 0
    if (hasLength) strength++
    if (hasUpperCase) strength++
    if (hasLowerCase) strength++
    if (hasNumber) strength++
    if (hasSymbol) strength++

    if (strength <= 2) return { text: 'Weak', color: 'bg-red-500' }
    if (strength <= 4) return { text: 'Medium', color: 'bg-yellow-500' }
    return { text: 'Strong', color: 'bg-green-500' }
  }

  const strength = calculateStrength()

  return (
    <div className='space-y-4 mt-4'>
      <div className='space-y-2'>
        <div className='flex justify-between'>
          <span className='text-sm text-slate-400'>Password Strength:</span>
          <span
            className={`text-sm ${
              strength.text === 'Weak'
                ? 'text-red-400'
                : strength.text === 'Medium'
                ? 'text-yellow-400'
                : 'text-green-400'
            }`}
          >
            {strength.text}
          </span>
        </div>
        <div className='h-2 w-full bg-slate-700 rounded-full overflow-hidden'>
          <div
            className={`h-full ${strength.color} transition-all duration-300`}
            style={{ width: `${(calculateStrength().strength / 5) * 100}%` }}
          />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-2 text-sm'>
        <div className='flex items-center gap-2'>
          {hasLength ? (
            <Check size={16} className='text-green-400' />
          ) : (
            <X size={16} className='text-red-400' />
          )}
          <span className='text-slate-400'>8+ characters</span>
        </div>
        <div className='flex items-center gap-2'>
          {hasUpperCase ? (
            <Check size={16} className='text-green-400' />
          ) : (
            <X size={16} className='text-red-400' />
          )}
          <span className='text-slate-400'>Uppercase letter</span>
        </div>
        <div className='flex items-center gap-2'>
          {hasNumber ? (
            <Check size={16} className='text-green-400' />
          ) : (
            <X size={16} className='text-red-400' />
          )}
          <span className='text-slate-400'>Number</span>
        </div>
        <div className='flex items-center gap-2'>
          {hasSymbol ? (
            <Check size={16} className='text-green-400' />
          ) : (
            <X size={16} className='text-red-400' />
          )}
          <span className='text-slate-400'>Special character</span>
        </div>
      </div>
    </div>
  )
}

const UserProfile = () => {
  const dispatch = useDispatch()
  const activeUser = useSelector(state => state.auth.activeUser)
  console.log(activeUser)
  const [isEditing, setIsEditing] = useState(false)
  const [userForm, setUserForm] = useState(null)
  const [profilePicture, setProfilePicture] = useState(null)
  const [showResetModal, setShowResetModal] = useState(false)

  const [passwordForm, setPasswordForm] = useState({
    password: '',
    confirmPassword: ''
  })
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    // Fetch the active user and societies
    dispatch(checkActiveUser())
    dispatch(fetchSocieties())
  }, [dispatch])

  useEffect(() => {
    if (activeUser) {
      // Initialize form with user data
      setUserForm({
        username: activeUser.username,
        role: activeUser.role,
        profile_picture:
          activeUser.profile_picture || '/api/placeholder/150/150',
        societies: activeUser.societies || [],
        adminData: activeUser.additionalData || {
          name: '',
          contactInfo: { phone: '', email: '', cnic: '', address: '' }
        },
        customerData: activeUser.additionalData || {
          name: '',
          contactInfo: {
            phone: '',
            email: ''
          }
        },
        employeeData: activeUser.additionalData || {
          name: '',
          designation: '',
          personalDetails: {
            phone: '',
            address: ''
          }
        },
        investorData: activeUser.additionalData || {
          name: '',
          investmentAmount: 0,
          contactInfo: {
            phone: '',
            email: ''
          }
        }
      })
    }
  }, [activeUser])

  if (!userForm) return <p>Loading...</p>

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      setProfilePicture(file) // Save the file object
      setUserForm(prev => ({
        ...prev,
        profile_picture: URL.createObjectURL(file) // Show preview using a URL
      }))
    }
  }

  const handleEditProfile = async () => {
    if (isEditing && profilePicture) {
      try {
        if (!activeUser?.id) {
          console.error('User ID is missing')
          return
        }

        // Prepare FormData
        const formData = new FormData()
        formData.append('profile_picture', profilePicture)
        // Append other form fields as necessary
        formData.append('username', userForm.username)
        formData.append('role', userForm.role)

        // Dispatch the action with FormData
        const result = await dispatch(
          updateUserInfo({ userId: activeUser.id, updatedData: formData })
        ).unwrap()

        console.log('Profile updated successfully:', result)

        // Update the user form state with the new profile picture URL
        setUserForm(prev => ({
          ...prev,
          profile_picture: result.profile_picture || prev.profile_picture
        }))
        setProfilePicture(null)
      } catch (error) {
        console.error('Error updating profile:', error)
        alert(`Error: ${error.message || 'An unknown error occurred'}`)
      }
    }
    setIsEditing(!isEditing)
  }

  const handlePasswordReset = async e => {
    e.preventDefault()
    setError('')

    const hasUpperCase = /[A-Z]/.test(passwordForm.password)
    const hasLowerCase = /[a-z]/.test(passwordForm.password)
    const hasNumber = /\d/.test(passwordForm.password)
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(passwordForm.password)

    if (passwordForm.password !== passwordForm.confirmPassword) {
      setError('Passwords do not match')
      return
    }

    if (passwordForm.password.length < 8) {
      setError('Password must be at least 8 characters long')
      return
    }

    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSymbol) {
      setError(
        'Password must contain uppercase, lowercase, number, and special character'
      )
      return
    }

    try {
      await dispatch(
        resetPassword({
          userId: activeUser.id,
          newPassword: passwordForm.password
        })
      ).unwrap()

      setShowResetModal(false)
      setPasswordForm({ password: '', confirmPassword: '' })

      await Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Password has been reset successfully',
        background: '#1e293b',
        color: '#94a3b8',
        customClass: {
          popup: 'rounded-2xl border border-slate-700/50',
          title: 'text-teal-300',
          htmlContainer: 'text-slate-400',
          confirmButton:
            'bg-gradient-to-r from-teal-500 to-emerald-500 px-6 py-3 rounded-xl text-white hover:from-teal-600 hover:to-emerald-600 transform hover:scale-105 transition-all duration-300'
        }
      })
    } catch (error) {
      setError(error.message || 'Failed to reset password')
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Failed to reset password',
        background: '#1e293b',
        color: '#94a3b8',
        customClass: {
          popup: 'rounded-2xl border border-slate-700/50',
          title: 'text-red-400',
          htmlContainer: 'text-slate-400',
          confirmButton:
            'bg-gradient-to-r from-teal-500 to-emerald-500 px-6 py-3 rounded-xl text-white hover:from-teal-600 hover:to-emerald-600 transform hover:scale-105 transition-all duration-300'
        }
      })
    }
  }

  const { name, contactInfo, designation, personalDetails } =
    userForm.role === 'Customer'
      ? userForm.customerData
      : userForm.role === 'Employee'
      ? userForm.employeeData
      : userForm.role === 'Investor'
      ? userForm.investorData
      : userForm.role === 'Admin'
      ? userForm.adminData
      : {}

  return (
    <div className='min-h-screen bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 p-6'>
      <div className='max-w-7xl mx-auto'>
        {/* Profile Header */}
        <div className='relative bg-gradient-to-r from-slate-800/80 to-slate-900/80 backdrop-blur-xl rounded-2xl shadow-2xl p-8 mb-8 border border-slate-700/50'>
          <div className='relative flex flex-col md:flex-row items-start md:items-center gap-8'>
            <div className='relative group'>
              <img
                src={userForm.profile_picture}
                alt='Profile'
                className='relative w-36 h-36 rounded-full border-4 border-slate-800'
              />
              {isEditing && (
                <label className='absolute bottom-2 right-2 bg-gradient-to-r from-teal-500 to-emerald-500 p-2 rounded-full cursor-pointer shadow-lg transform hover:scale-110 transition-transform duration-300'>
                  <Edit size={18} className='text-white' />
                  <input
                    type='file'
                    className='hidden'
                    onChange={handleFileChange}
                  />
                </label>
              )}
            </div>
            <div className='flex-1 space-y-4'>
              <div className='flex items-center gap-3'>
                <h1 className='text-4xl font-bold bg-gradient-to-r from-teal-300 to-emerald-300 text-transparent bg-clip-text'>
                  {name || 'No Name'}
                </h1>
                <span className='bg-gradient-to-r from-teal-500/20 to-emerald-500/20 text-teal-300 px-3 py-1 rounded-full text-sm font-medium border border-teal-500/20'>
                  {userForm.role}
                </span>
              </div>
              {userForm.role === 'Employee' && (
                <p className='text-teal-300/80 text-lg mt-2'>
                  {designation || 'No Designation'}
                </p>
              )}

              {userForm.role === 'Employee' && (
                <div className='flex items-center gap-4 mt-3'>
                  <div className='flex items-center gap-2 text-slate-400'>
                    <Mail size={16} className='text-teal-400' />
                    <span>{personalDetails?.cnic || 'No CNIC'}</span>
                  </div>
                  <div className='flex items-center gap-2 text-slate-400'>
                    <Phone size={16} className='text-teal-400' />
                    <span>{personalDetails?.phone || 'No Phone'}</span>
                  </div>
                  <div className='flex items-center gap-2 text-slate-400'>
                    <Mail size={16} className='text-teal-400' />
                    <span>{personalDetails?.address || 'No Address'}</span>
                  </div>
                </div>
              )}
              {userForm.role !== 'Employee' && (
                <div className='flex items-center gap-4 mt-3'>
                  <div className='flex items-center gap-2 text-slate-400'>
                    <Mail size={16} className='text-teal-400' />
                    <span>{contactInfo?.email || 'No Email'}</span>
                  </div>
                  <div className='flex items-center gap-2 text-slate-400'>
                    <Phone size={16} className='text-teal-400' />
                    <span>{contactInfo?.phone || 'No Phone'}</span>
                  </div>
                </div>
              )}
              <div className='flex gap-4 mt-4'>
                <button
                  onClick={handleEditProfile}
                  className='bg-gradient-to-r from-teal-500 to-emerald-500 text-white px-6 py-3 rounded-xl flex items-center gap-2 transform hover:scale-105 transition-all duration-300 shadow-lg'
                >
                  <Settings size={18} />
                  {isEditing ? 'Save Changes' : 'Edit Profile'}
                </button>

                <button
                  onClick={() => setShowResetModal(true)}
                  className='bg-gradient-to-r from-teal-500 to-emerald-500 text-white px-6 py-3 rounded-xl flex items-center gap-2 transform hover:scale-105 transition-all duration-300 shadow-lg'
                >
                  <Lock size={18} />
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Assigned Societies */}
        <div className='bg-gradient-to-r from-slate-800/80 to-slate-900/80 backdrop-blur-xl rounded-2xl shadow-2xl p-6 border border-slate-700/50'>
          <h2 className='text-2xl font-bold text-teal-300 mb-6 flex items-center gap-2'>
            <Building className='text-teal-400' />
            Assigned Societies
          </h2>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            {userForm.societies && userForm.societies.length > 0 ? (
              userForm.societies.map((society, idx) => (
                <div
                  key={idx}
                  className='bg-slate-800/50 rounded-xl p-4 flex items-center gap-3 transform hover:scale-105 transition-all duration-300 border border-slate-700/50 hover:border-teal-500/50'
                >
                  <Home className='text-teal-400' size={24} />
                  <span className='text-slate-300'>{society.name}</span>
                </div>
              ))
            ) : (
              <div className='text-slate-300 col-span-full'>
                No societies assigned
              </div>
            )}
          </div>
        </div>

        <Transition show={showResetModal} as={React.Fragment}>
          <div className='fixed inset-0 z-50 overflow-y-auto'>
            <div className='flex min-h-screen items-center justify-center p-4'>
              <Transition.Child
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-slate-900/75 transition-opacity' />
              </Transition.Child>

              <Transition.Child
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-0 scale-95'
                enterTo='opacity-100 translate-y-0 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 scale-100'
                leaveTo='opacity-0 translate-y-0 scale-95'
                className='relative z-50 w-full max-w-lg'
              >
                <div className='relative transform overflow-hidden rounded-2xl bg-gradient-to-r from-slate-800/95 to-slate-900/95 backdrop-blur-xl px-4 pt-5 pb-4 text-left shadow-2xl transition-all sm:p-6 border border-slate-700/50'>
                  <div className='absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-teal-500 via-emerald-500 to-teal-500'></div>

                  <div className='sm:flex sm:items-start'>
                    <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gradient-to-r from-teal-500/20 to-emerald-500/20 sm:mx-0 sm:h-10 sm:w-10'>
                      <Key className='h-6 w-6 text-teal-300' />
                    </div>
                    <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                      <h3 className='text-lg font-medium leading-6 text-teal-300'>
                        Reset Password
                      </h3>
                      <div className='mt-2'>
                        <p className='text-sm text-slate-400'>
                          Please enter your new password below. Make sure it's
                          at least 8 characters long.
                        </p>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handlePasswordReset} className='mt-5 sm:mt-6'>
                    <div className='relative'>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={passwordForm.password}
                        onChange={e =>
                          setPasswordForm(prev => ({
                            ...prev,
                            password: e.target.value
                          }))
                        }
                        className='w-full px-4 py-3 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-300 text-slate-300 placeholder-slate-500'
                        placeholder='New Password'
                      />
                      <button
                        type='button'
                        onClick={() => setShowPassword(!showPassword)}
                        className='absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 hover:text-teal-300 transition-colors duration-300'
                      >
                        {showPassword ? (
                          <EyeOff size={18} />
                        ) : (
                          <Eye size={18} />
                        )}
                      </button>
                    </div>

                    <div className='relative mt-2'>
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={passwordForm.confirmPassword}
                        onChange={e =>
                          setPasswordForm(prev => ({
                            ...prev,
                            confirmPassword: e.target.value
                          }))
                        }
                        className='w-full px-4 py-3 bg-slate-800/50 border border-slate-700 rounded-xl focus:ring-2 focus:ring-teal-500 focus:border-transparent transition-all duration-300 text-slate-300 placeholder-slate-500'
                        placeholder='Confirm Password'
                      />
                      <button
                        type='button'
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        className='absolute right-3 top-1/2 -translate-y-1/2 text-slate-400 hover:text-teal-300 transition-colors duration-300'
                      >
                        {showConfirmPassword ? (
                          <EyeOff size={18} />
                        ) : (
                          <Eye size={18} />
                        )}
                      </button>
                    </div>

                    {error && (
                      <p className='text-red-400 text-sm mt-2'>{error}</p>
                    )}

                    <PasswordStrengthIndicator
                      password={passwordForm.password}
                    />

                    <div className='mt-6 grid grid-cols-2 gap-4'>
                      <button
                        type='button'
                        onClick={() => setShowResetModal(false)}
                        className='w-full px-4 py-3 rounded-xl bg-slate-800 text-slate-300 hover:bg-slate-700 transition-all duration-300'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='w-full px-4 py-3 rounded-xl bg-gradient-to-r from-teal-500 to-emerald-500 text-white hover:from-teal-600 hover:to-emerald-600 transform hover:scale-105 transition-all duration-300'
                      >
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}

export default UserProfile
