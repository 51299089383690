import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { plotCancellationApi } from '../../api'

// Async thunks for plot cancellation operations
export const cancelPlot = createAsyncThunk(
  'plotCancellation/cancelPlot',
  async (options, { rejectWithValue }) => {
    try {
      const response = await plotCancellationApi.cancelPlot(options)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const createInstallmentPlan = createAsyncThunk(
  'plotCancellation/createInstallmentPlan',
  async (options, { rejectWithValue }) => {
    try {
      const response = await plotCancellationApi.createNewInstallmentPlan(
        options
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchCanceledPlotInstallments = createAsyncThunk(
  'plotCancellation/fetchCanceledInstallments',
  async (filters, { rejectWithValue }) => {
    try {
      const response = await plotCancellationApi.getCanceledPlotInstallments(
        filters
      )
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchInstallmentSummary = createAsyncThunk(
  'plotCancellation/fetchInstallmentSummary',
  async (_, { rejectWithValue }) => {
    try {
      const response =
        await plotCancellationApi.getCanceledPlotInstallmentSummary()
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Initial state
const initialState = {
  cancellationResult: null,
  installmentPlan: null,
  canceledInstallments: {
    data: [],
    totalInstallments: 0,
    loading: false,
    error: null
  },
  installmentSummary: {
    data: null,
    loading: false,
    error: null
  },
  loading: false,
  error: null
}

// Plot cancellation slice
const plotCancellationSlice = createSlice({
  name: 'plotCancellation',
  initialState,
  reducers: {
    resetCancellationState: state => {
      state.cancellationResult = null
      state.error = null
      state.loading = false
    }
  },
  extraReducers: builder => {
    // Updated Cancel Plot Handlers
    builder.addCase(cancelPlot.pending, state => {
      state.loading = true
      state.error = null
      state.cancellationResult = null
    })
    builder.addCase(cancelPlot.fulfilled, (state, action) => {
      state.loading = false
      state.cancellationResult = action.payload
      state.error = null
    })
    builder.addCase(cancelPlot.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
      state.cancellationResult = null
    })

    // Create Installment Plan
    builder.addCase(createInstallmentPlan.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createInstallmentPlan.fulfilled, (state, action) => {
      state.loading = false
      state.installmentPlan = action.payload
    })
    builder.addCase(createInstallmentPlan.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload
    })

    // Fetch Canceled Plot Installments
    builder.addCase(fetchCanceledPlotInstallments.pending, state => {
      state.canceledInstallments.loading = true
      state.canceledInstallments.error = null
    })
    builder.addCase(
      fetchCanceledPlotInstallments.fulfilled,
      (state, action) => {
        state.canceledInstallments.loading = false
        state.canceledInstallments.data = action.payload.installments
        state.canceledInstallments.totalInstallments =
          action.payload.totalInstallments
      }
    )
    builder.addCase(fetchCanceledPlotInstallments.rejected, (state, action) => {
      state.canceledInstallments.loading = false
      state.canceledInstallments.error = action.payload
    })

    // Fetch Installment Summary
    builder.addCase(fetchInstallmentSummary.pending, state => {
      state.installmentSummary.loading = true
      state.installmentSummary.error = null
    })
    builder.addCase(fetchInstallmentSummary.fulfilled, (state, action) => {
      state.installmentSummary.loading = false
      state.installmentSummary.data = action.payload
    })
    builder.addCase(fetchInstallmentSummary.rejected, (state, action) => {
      state.installmentSummary.loading = false
      state.installmentSummary.error = action.payload
    })
  }
})

export const { resetCancellationState } = plotCancellationSlice.actions
export default plotCancellationSlice.reducer
