// import React, { useEffect, useState } from 'react'
// import {
//   User,
//   Phone,
//   Mail,
//   MapPin,
//   FileText,
//   Users,
//   Heart,
//   UserCheck,
//   ChevronDown,
//   ChevronUp,
//   Calendar,
//   ClipboardList,
//   Eye
// } from 'lucide-react'
// import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'

// // Reusable Section Component
// const Section = ({ title, icon: Icon, isOpen, toggle, children }) => (
//   <div className='mb-6 bg-white rounded-xl border border-gray-100 shadow-sm transition-all hover:shadow-md'>
//     <div
//       className='flex justify-between items-center p-5 cursor-pointer group'
//       onClick={toggle}
//     >
//       <div className='flex items-center space-x-3 font-medium text-gray-800'>
//         <div className='bg-blue-50 text-blue-600 p-2 rounded-lg'>
//           <Icon size={18} />
//         </div>
//         <span className='text-lg'>{title}</span>
//       </div>
//       <div
//         className={`p-2 rounded-full transition-colors ${
//           isOpen
//             ? 'bg-blue-50 text-blue-600'
//             : 'text-gray-400 group-hover:text-blue-600'
//         }`}
//       >
//         {isOpen ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
//       </div>
//     </div>
//     {isOpen && (
//       <div className='p-5 pt-2 grid gap-5 border-t border-gray-50'>
//         {children}
//       </div>
//     )}
//   </div>
// )

// // Main Modal Component
// const CustomerDetailsModal = ({ isOpen, onClose, customer }) => {
//   const [expandedSections, setExpandedSections] = useState({
//     personal: true,
//     waris: false,
//     documents: false
//   })
//   const {  theme, gardient } = useSidebarContext()

//   const [activeImagePreview, setActiveImagePreview] = useState(null)

//   const toggleSection = section => {
//     setExpandedSections(prev => ({
//       ...prev,
//       [section]: !prev[section]
//     }))
//   }

//   useEffect(() => {
//     if (isOpen && customer) {
//       console.log('Customer details modal opened with customer:', customer)
//     }

//     // Close image preview when modal closes
//     if (!isOpen) {
//       setActiveImagePreview(null)
//     }
//   }, [isOpen, customer])

//   if (!isOpen || !customer) return null

//   return (
//     <div className='fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50 p-4'>
//       <div className='bg-white rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-auto'>
//         {/* Header */}
//         <div className='bg-gradient-to-br from-blue-600 to-indigo-700 text-white p-6 rounded-t-2xl flex justify-between items-center sticky top-0 z-10'>
//           <div className='flex items-center space-x-4'>
//             <div className='bg-white text-blue-700 rounded-full p-3 shadow-md'>
//               <User size={24} />
//             </div>
//             <div>
//               <p className='text-blue-100 text-sm font-medium'>
//                 Customer Profile
//               </p>
//               <h2 className='text-2xl font-bold'>
//                 {customer?.customerData?.name}
//               </h2>
//             </div>
//           </div>
//           <button
//             onClick={onClose}
//             aria-label='Close modal'
//             className='bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-2 transition-all hover:rotate-90'
//           >
//             <svg
//               xmlns='http://www.w3.org/2000/svg'
//               className='h-5 w-5'
//               viewBox='0 0 24 24'
//               fill='none'
//               stroke='currentColor'
//             >
//               <path
//                 strokeLinecap='round'
//                 strokeLinejoin='round'
//                 strokeWidth={2}
//                 d='M6 18L18 6M6 6l12 12'
//               />
//             </svg>
//           </button>
//         </div>

//         {/* Content */}
//         <div className='p-6 bg-gray-50'>
//           {/* Customer Summary Card */}
//           <div className='bg-white p-5 rounded-xl shadow-sm mb-6 border border-gray-100'>
//             <div className='grid md:grid-cols-3 gap-4'>
//               <InfoChip
//                 icon={Phone}
//                 value={customer?.customerData?.contactInfo?.phone}
//                 label='Contact'
//               />
//               <InfoChip
//                 icon={ClipboardList}
//                 value={customer?.customerData?.contactInfo?.cnic}
//                 label='CNIC'
//               />
//               <InfoChip
//                 icon={Calendar}
//                 value={new Date(customer.created_at).toLocaleDateString(
//                   'en-GB'
//                 )}
//                 label='Registered'
//               />
//             </div>
//           </div>

//           {/* Personal Information */}
//           <Section
//             title='Personal Information'
//             icon={User}
//             isOpen={expandedSections.personal}
//             toggle={() => toggleSection('personal')}
//           >
//             <div className='grid md:grid-cols-2 gap-6'>
//               <InfoItem
//                 label='Phone'
//                 value={customer?.customerData?.contactInfo?.phone}
//                 icon={Phone}
//               />
//               <InfoItem
//                 label='Email'
//                 value={customer?.customerData?.contactInfo?.email}
//                 icon={Mail}
//               />
//               <InfoItem
//                 label='CNIC'
//                 value={customer?.customerData?.contactInfo?.cnic}
//                 icon={FileText}
//               />
//               <InfoItem
//                 label="Father's Name"
//                 value={customer?.customerData?.contactInfo?.father_name}
//                 icon={User}
//               />
//               <InfoItem
//                 label='Address'
//                 value={customer?.customerData?.contactInfo?.address}
//                 icon={MapPin}
//               />
//               <InfoItem
//                 label='Customer ID'
//                 value={customer?.customerData?._id}
//                 icon={Users}
//               />
//               <InfoItem
//                 label='Registration Date'
//                 value={new Date(
//                   customer?.customerData?.created_at
//                 ).toLocaleDateString('en-GB')}
//                 icon={Calendar}
//               />
//             </div>
//           </Section>

//           {/* Waris Information */}
//           <Section
//             title='Nominee (Waris) Information'
//             icon={Heart}
//             isOpen={expandedSections.waris}
//             toggle={() => toggleSection('waris')}
//           >
//             <div className='grid md:grid-cols-2 gap-6'>
//               <InfoItem
//                 label='Name'
//                 value={customer?.customerData?.contactInfo?.waris_name}
//                 icon={UserCheck}
//               />
//               <InfoItem
//                 label='Relation'
//                 value={customer?.customerData?.contactInfo?.waris_relation}
//                 icon={Users}
//               />
//               <InfoItem
//                 label='Phone'
//                 value={customer?.customerData?.contactInfo?.waris_phone}
//                 icon={Phone}
//               />
//               <InfoItem
//                 label='CNIC'
//                 value={customer?.customerData?.contactInfo?.waris_cnic}
//                 icon={FileText}
//               />
//               <InfoItem
//                 label="Father's Name"
//                 value={customer?.customerData?.contactInfo?.waris_father_name}
//                 icon={User}
//               />
//               <InfoItem
//                 label='Address'
//                 value={customer?.customerData?.contactInfo?.waris_address}
//                 icon={MapPin}
//               />
//             </div>
//           </Section>

//           {/* Documents */}
//           <Section
//             title='CNIC Documents'
//             icon={FileText}
//             isOpen={expandedSections.documents}
//             toggle={() => toggleSection('documents')}
//           >
//             <ImageGrid
//               label='Customer CNIC'
//               front={customer?.customerData?.contactInfo?.id_front_img}
//               back={customer?.customerData?.contactInfo?.id_back_img}
//               onView={setActiveImagePreview}
//             />
//             <div className='mt-4 pt-4 border-t border-gray-100'>
//               <ImageGrid
//                 label='Nominee (Waris) CNIC'
//                 front={customer?.customerData?.contactInfo?.waris_id_front_img}
//                 back={customer?.customerData?.contactInfo?.waris_id_back_img}
//                 onView={setActiveImagePreview}
//               />
//             </div>
//           </Section>
//         </div>
//       </div>

//       {/* Full Image Preview Modal */}
//       {activeImagePreview && (
//         <div
//           className='fixed inset-0 bg-black bg-opacity-90 z-[60] flex items-center justify-center p-6'
//           onClick={() => setActiveImagePreview(null)}
//         >
//           <div className='max-w-4xl w-full'>
//             <div className='flex justify-end mb-2'>
//               <button
//                 className='bg-white rounded-full p-2 text-gray-800 hover:bg-gray-200 transition-colors'
//                 onClick={() => setActiveImagePreview(null)}
//               >
//                 <svg
//                   xmlns='http://www.w3.org/2000/svg'
//                   className='h-6 w-6'
//                   viewBox='0 0 24 24'
//                   fill='none'
//                   stroke='currentColor'
//                 >
//                   <path
//                     strokeLinecap='round'
//                     strokeLinejoin='round'
//                     strokeWidth={2}
//                     d='M6 18L18 6M6 6l12 12'
//                   />
//                 </svg>
//               </button>
//             </div>
//             <img
//               src={activeImagePreview}
//               alt='Document preview'
//               className='w-full object-contain max-h-[80vh] rounded-lg'
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   )
// }

// // Info Chip Component for Summary
// const InfoChip = ({ icon: Icon, value, label }) => (
//   <div className='flex items-center space-x-3 p-3 rounded-lg bg-gray-50'>
//     <div className='bg-blue-100 text-blue-700 p-2 rounded-lg'>
//       <Icon size={16} />
//     </div>
//     <div>
//       <p className='text-xs text-gray-500 font-medium'>{label}</p>
//       <p className='font-semibold text-gray-800'>{value || 'N/A'}</p>
//     </div>
//   </div>
// )

// // Reusable InfoItem Component
// const InfoItem = ({ label, value, icon: Icon }) => (
//   <div className='flex items-start space-x-3 p-4 rounded-lg bg-gray-50 border border-gray-100'>
//     <Icon size={18} className='text-blue-600 mt-1' />
//     <div>
//       <p className='text-sm text-gray-500 font-medium'>{label}</p>
//       <p className='font-medium text-gray-800'>{value || 'N/A'}</p>
//     </div>
//   </div>
// )

// // Reusable ImageGrid Component
// const ImageGrid = ({ label, front, back, onView }) => (
//   <div className='space-y-3'>
//     <p className='font-medium text-gray-700'>{label}</p>
//     <div className='grid grid-cols-2 gap-4'>
//       <ImagePreview label='Front Side' src={front} onView={onView} />
//       <ImagePreview label='Back Side' src={back} onView={onView} />
//     </div>
//   </div>
// )

// const ImagePreview = ({ label, src, onView }) => (
//   <div className='border border-gray-100 rounded-lg overflow-hidden bg-white shadow-sm hover:shadow-md transition-all group'>
//     {src ? (
//       <div className='relative'>
//         <img src={src} alt={label} className='w-full h-40 object-cover' />
//         <div className='absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center opacity-0 group-hover:opacity-100'>
//           <button
//             onClick={e => {
//               e.stopPropagation()
//               onView(src)
//             }}
//             className='bg-white rounded-full p-3 shadow-lg text-blue-600 hover:bg-blue-50 transition-colors'
//           >
//             <Eye size={18} />
//           </button>
//         </div>
//       </div>
//     ) : (
//       <div className='w-full h-40 bg-gray-100 flex items-center justify-center'>
//         <p className='text-gray-400 text-sm'>Image not available</p>
//       </div>
//     )}
//     <p className='text-xs py-3 px-4 bg-white text-center font-medium'>
//       {label}
//     </p>
//   </div>
// )

// export default CustomerDetailsModal
import React, { useEffect, useState } from 'react'
import {
  User,
  Phone,
  Mail,
  MapPin,
  FileText,
  Users,
  Heart,
  UserCheck,
  ChevronDown,
  ChevronUp,
  Calendar,
  ClipboardList,
  Eye
} from 'lucide-react'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'

// Reusable Section Component
const Section = ({ title, icon: Icon, isOpen, toggle, children }) => {
  const { theme, gradient } = useSidebarContext()

  return (
    <div
      className={`mb-6 ${gradient} rounded-xl border ${theme.tailwind.border14} shadow-sm transition-all hover:shadow-md`}
    >
      <div
        className='flex justify-between items-center p-5 cursor-pointer group'
        onClick={toggle}
      >
        <div className='flex items-center space-x-3 font-medium text-gray-800'>
          <div
            className={`${theme.tailwind.bg11} ${theme.tailwind.text} p-2 rounded-lg`}
          >
            <Icon size={18} />
          </div>
          <span className='text-lg'>{title}</span>
        </div>
        <div
          className={`p-2 rounded-full transition-colors ${
            isOpen
              ? `${theme.tailwind.bg11} ${theme.tailwind.text}`
              : `text-gray-400 group-hover:${theme.tailwind.text}`
          }`}
        >
          {isOpen ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
        </div>
      </div>
      {isOpen && (
        <div className='p-5 pt-2 grid gap-5 border-t border-gray-50'>
          {children}
        </div>
      )}
    </div>
  )
}

// Main Modal Component
const CustomerDetailsModal = ({ isOpen, onClose, customer }) => {
  const [expandedSections, setExpandedSections] = useState({
    personal: true,
    waris: false,
    documents: false
  })
  const { theme, gradient } = useSidebarContext()

  const [activeImagePreview, setActiveImagePreview] = useState(null)

  const toggleSection = section => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  useEffect(() => {
    if (isOpen && customer) {
      // console.log('Customer details modal opened with customer:', customer)
    }

    // Close image preview when modal closes
    if (!isOpen) {
      setActiveImagePreview(null)
    }
  }, [isOpen, customer])

  if (!isOpen || !customer) return null

  return (
    <div className='fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm flex justify-center items-center z-50 p-4'>
      <div
        className={`${gradient} rounded-2xl shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-auto`}
      >
        {/* Header */}
        <div
          className={`${theme.tailwind.text5} text-white p-6 rounded-t-2xl flex justify-between items-center sticky top-0 z-10`}
        >
          <div className='flex items-center space-x-4'>
            <div
              className={`bg-white ${theme.tailwind.text3} rounded-full p-3 shadow-md`}
            >
              <User size={24} />
            </div>
            <div>
              <p className={`${theme.tailwind.text2} text-sm font-medium`}>
                Customer Profile
              </p>
              <h2 className='text-2xl font-bold'>
                {customer?.customerData?.name}
              </h2>
            </div>
          </div>
          <button
            onClick={onClose}
            aria-label='Close modal'
            className='bg-white bg-opacity-20 hover:bg-opacity-30 rounded-full p-2 transition-all hover:rotate-90'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className='p-6 bg-gray-50'>
          {/* Customer Summary Card */}
          <div
            className={`bg-white p-5 rounded-xl shadow-sm mb-6 border ${theme.tailwind.border14}`}
          >
            <div className='grid md:grid-cols-3 gap-4'>
              <InfoChip
                icon={Phone}
                value={customer?.customerData?.contactInfo?.phone}
                label='Contact'
              />
              <InfoChip
                icon={ClipboardList}
                value={customer?.customerData?.contactInfo?.cnic}
                label='CNIC'
              />
              <InfoChip
                icon={Calendar}
                value={new Date(customer.created_at).toLocaleDateString(
                  'en-GB'
                )}
                label='Registered'
              />
            </div>
          </div>

          {/* Personal Information */}
          <Section
            title='Personal Information'
            icon={User}
            isOpen={expandedSections.personal}
            toggle={() => toggleSection('personal')}
          >
            <div className='grid md:grid-cols-2 gap-6'>
              <InfoItem
                label='Phone'
                value={customer?.customerData?.contactInfo?.phone}
                icon={Phone}
              />
              <InfoItem
                label='Email'
                value={customer?.customerData?.contactInfo?.email}
                icon={Mail}
              />
              <InfoItem
                label='CNIC'
                value={customer?.customerData?.contactInfo?.cnic}
                icon={FileText}
              />
              <InfoItem
                label="Father's Name"
                value={customer?.customerData?.contactInfo?.father_name}
                icon={User}
              />
              <InfoItem
                label='Address'
                value={customer?.customerData?.contactInfo?.address}
                icon={MapPin}
              />
              <InfoItem
                label='Customer ID'
                value={customer?.customerData?._id}
                icon={Users}
              />
              <InfoItem
                label='Registration Date'
                value={new Date(customer.created_at).toLocaleDateString(
                  'en-GB'
                )}
                icon={Calendar}
              />
            </div>
          </Section>

          {/* Waris Information */}
          <Section
            title='Nominee (Waris) Information'
            icon={Heart}
            isOpen={expandedSections.waris}
            toggle={() => toggleSection('waris')}
          >
            <div className='grid md:grid-cols-2 gap-6'>
              <InfoItem
                label='Name'
                value={customer?.customerData?.contactInfo?.waris_name}
                icon={UserCheck}
              />
              <InfoItem
                label='Relation'
                value={customer?.customerData?.contactInfo?.waris_relation}
                icon={Users}
              />
              <InfoItem
                label='Phone'
                value={customer?.customerData?.contactInfo?.waris_phone}
                icon={Phone}
              />
              <InfoItem
                label='CNIC'
                value={customer?.customerData?.contactInfo?.waris_cnic}
                icon={FileText}
              />
              <InfoItem
                label="Father's Name"
                value={customer?.customerData?.contactInfo?.waris_father_name}
                icon={User}
              />
              <InfoItem
                label='Address'
                value={customer?.customerData?.contactInfo?.waris_address}
                icon={MapPin}
              />
            </div>
          </Section>

          {/* Documents */}
          <Section
            title='CNIC Documents'
            icon={FileText}
            isOpen={expandedSections.documents}
            toggle={() => toggleSection('documents')}
          >
            <ImageGrid
              label='Customer CNIC'
              front={customer?.customerData?.contactInfo?.id_front_img}
              back={customer?.customerData?.contactInfo?.id_back_img}
              onView={setActiveImagePreview}
            />
            <div className='mt-4 pt-4 border-t border-gray-100'>
              <ImageGrid
                label='Nominee (Waris) CNIC'
                front={customer?.customerData?.contactInfo?.waris_id_front_img}
                back={customer?.customerData?.contactInfo?.waris_id_back_img}
                onView={setActiveImagePreview}
              />
            </div>
          </Section>
        </div>
      </div>

      {/* Full Image Preview Modal */}
      {activeImagePreview && (
        <div
          className='fixed inset-0 bg-black bg-opacity-90 z-[60] flex items-center justify-center p-6'
          onClick={() => setActiveImagePreview(null)}
        >
          <div className='max-w-4xl w-full'>
            <div className='flex justify-end mb-2'>
              <button
                className='bg-white rounded-full p-2 text-gray-800 hover:bg-gray-200 transition-colors'
                onClick={() => setActiveImagePreview(null)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>
            <img
              src={activeImagePreview}
              alt='Document preview'
              className='w-full object-contain max-h-[80vh] rounded-lg'
            />
          </div>
        </div>
      )}
    </div>
  )
}

// Info Chip Component for Summary
const InfoChip = ({ icon: Icon, value, label }) => {
  const { theme } = useSidebarContext()

  return (
    <div className='flex items-center space-x-3 p-3 rounded-lg bg-gray-50'>
      <div
        className={`${theme.tailwind.bg11} ${theme.tailwind.text} p-2 rounded-lg`}
      >
        <Icon size={16} />
      </div>
      <div>
        <p className='text-xs text-gray-500 font-medium'>{label}</p>
        <p className='font-semibold text-gray-800'>{value || 'N/A'}</p>
      </div>
    </div>
  )
}

// Reusable InfoItem Component
const InfoItem = ({ label, value, icon: Icon }) => {
  const { theme } = useSidebarContext()

  return (
    <div
      className={`flex items-start space-x-3 p-4 rounded-lg bg-gray-50 border ${theme.tailwind.border14}`}
    >
      <Icon size={18} className={`${theme.tailwind.text} mt-1`} />
      <div>
        <p className='text-sm text-gray-500 font-medium'>{label}</p>
        <p className='font-medium text-gray-800'>{value || 'N/A'}</p>
      </div>
    </div>
  )
}

// Reusable ImageGrid Component
const ImageGrid = ({ label, front, back, onView }) => (
  <div className='space-y-3'>
    <p className='font-medium text-gray-700'>{label}</p>
    <div className='grid grid-cols-2 gap-4'>
      <ImagePreview label='Front Side' src={front} onView={onView} />
      <ImagePreview label='Back Side' src={back} onView={onView} />
    </div>
  </div>
)

const ImagePreview = ({ label, src, onView }) => {
  const { theme, gradient } = useSidebarContext()

  return (
    <div
      className={`border ${theme.tailwind.border14} rounded-lg overflow-hidden ${gradient} shadow-sm hover:shadow-md transition-all group`}
    >
      {src ? (
        <div className='relative'>
          <img src={src} alt={label} className='w-full h-40 object-cover' />
          <div className='absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center opacity-0 group-hover:opacity-100'>
            <button
              onClick={e => {
                e.stopPropagation()
                onView(src)
              }}
              className={`bg-white rounded-full p-3 shadow-lg ${theme.tailwind.text} ${theme.tailwind.bgHover3} transition-colors`}
            >
              <Eye size={18} />
            </button>
          </div>
        </div>
      ) : (
        <div className='w-full h-40 bg-gray-100 flex items-center justify-center'>
          <p className='text-gray-400 text-sm'>Image not available</p>
        </div>
      )}
      <p className='text-xs py-3 px-4 bg-white text-center font-medium'>
        {label}
      </p>
    </div>
  )
}

export default CustomerDetailsModal
