import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { createBooking, getAllBookings } from '../store/slices/bookingSlice'
import { fetchAllUsers } from '../store/slices/authSlice'
import { fetchSocieties } from '../store/slices/societySlice'
import { useSnackbar } from 'notistack'
import { toWords } from 'number-to-words'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
import { ChevronLeft, ChevronRight, Filter, Search, X } from 'lucide-react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const BookingForm = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { users, status } = useSelector(state => state.auth)
  // console.log(users)
  const { activeSociety, theme, gradient } = useSidebarContext()
  console.log(activeSociety.users)
  // State for plot selection and customer search
  const [plotId, setPlotId] = useState('')
  const [localPlots, setLocalPlots] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [formattedAmount, setFormattedAmount] = useState('')
  const [amountInWords, setAmountInWords] = useState('')
  const [dealerCommissionFormatted, setDealerCommissionFormatted] = useState('')
  const [formattedInitialPayment, setFormattedInitialPayment] = useState('')
  const plotsPerPage = 10

  const [filters, setFilters] = useState({
    plotNumber: '',
    block: '',
    size: '',
    marla: '',
    category: '',
    booking_status: '',
    type: ''
  })
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false)

  // Fetch required data on component load
  useEffect(() => {
    dispatch(getAllBookings())
    if (status === 'idle' || (status === 'failed' && users.length === 0)) {
      dispatch(fetchAllUsers())
    }
    dispatch(fetchSocieties())
  }, [dispatch, status, users.length])

  useEffect(() => {
    if (activeSociety?.plots) {
      // Sort plots by their plot number in ascending order before setting them
      const sortedPlots = [...activeSociety.plots].sort((a, b) => {
        if (a.plot_number && b.plot_number) {
          return a.plot_number - b.plot_number
        }
        return 0 // Handle cases where plotNumber might be missing
      })
      setLocalPlots(sortedPlots)
    }
  }, [activeSociety?.plots])
  // console.log(activeSociety.plots);
  // console.log(activeSociety);
  const BookingValidationSchema = Yup.object().shape({
    plot: Yup.string().required('Please select a plot'),
    customer: Yup.string().required('Please select a customer'),
    paymentMode: Yup.string()
      .oneOf(['Full', 'Installment'], 'Invalid payment mode')
      .required('Payment mode is required'),
    bookingAmount: Yup.number()
      .positive('Amount must be positive')
      .required('Total plot amount is required'),

    initialPayment: Yup.number().when('paymentMode', {
      is: 'Installment',
      then: schema =>
        schema
          .required('Initial payment is required')
          .max(
            Yup.ref('bookingAmount'),
            'Initial payment cannot be more than total amount'
          ),
      otherwise: schema => schema.notRequired()
    }),
    installmentYears: Yup.number().when('paymentMode', {
      is: 'Installment',
      then: schema =>
        schema
          .positive('Installment years must be a positive number')
          .required('Installment years are required')
          .max(10, 'Installment years cannot exceed 10')
          .test(
            'is-valid-installment-years',
            'Installment years must be a positive number between 0.5 and 10 (e.g., 1, 1.5, 2, 2.5)',
            value => {
              return (
                !isNaN(parseFloat(value)) &&
                parseFloat(value) >= 0.5 &&
                parseFloat(value) <= 10 &&
                /^(\d+(\.\d)?|\d+(\.\d{1})?)$/.test(value.toString())
              )
            }
          ),
      otherwise: schema => schema.notRequired()
    }),
    receiptNo: Yup.string()
      .required('Receipt number is required')
      .max(100, 'Receipt number cannot exceed 100 characters'),
    offerTag: Yup.string(),
    booking_date: Yup.string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Date must be in DD/MM/YYYY format')
      .test('is-valid-date', 'Invalid date', value => {
        if (!value) return true // Allow null/undefined
        const [day, month, year] = value.split('/').map(Number)
        const date = new Date(year, month - 1, day)
        return (
          date.getDate() === day &&
          date.getMonth() + 1 === month &&
          date.getFullYear() === year
        )
      })
      .nullable()
  })

  const formatNumberWithCommas = value => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handlePlotAmountChange = (e, setFieldValue) => {
    let value = e.target.value.replace(/,/g, '')
    const formatted = formatNumberWithCommas(value)
    setFormattedAmount(formatted)
    setFieldValue('bookingAmount', value)

    // Convert number to words for total plot amount
    const words = toWords(Number(value))
    setAmountInWords(words.charAt(0).toUpperCase() + words.slice(1))
  }

  const handleInitialPaymentChange = (e, setFieldValue) => {
    let value = e.target.value.replace(/,/g, '')
    const formatted = formatNumberWithCommas(value)
    setFieldValue('initialPayment', value)
    setFormattedInitialPayment(formatted)
  }

  const handleDealerCommissionChange = (e, setFieldValue) => {
    let value = e.target.value.replace(/,/g, '')
    const formatted = formatNumberWithCommas(value)
    setDealerCommissionFormatted(formatted)
    setFieldValue('dealerCommission', value)
  }

  const handlePlotSelect = (id, setFieldValue) => {
    const newPlotId = plotId === id ? '' : id
    setPlotId(newPlotId)
    setFieldValue('plot', newPlotId)
  }

  const filteredPlots = localPlots.filter(plot => {
    return (
      (!filters.plotNumber || plot.plot_number.includes(filters.plotNumber)) &&
      (!filters.block || plot.block.includes(filters.block)) &&
      (!filters.size || plot.size.includes(filters.size)) &&
      (!filters.marla || plot.marla.includes(filters.marla)) &&
      (!filters.category || plot.category === filters.category) &&
      (!filters.booking_status ||
        plot.booking_status === filters.booking_status) &&
      (!filters.type || plot.plot_type === filters.type)
    )
  })
  const sortedPlots = [...filteredPlots].sort(
    (a, b) => a.plot_number - b.plot_number
  )
  const totalPages = Math.ceil(sortedPlots.length / plotsPerPage)
  const currentPlots = filteredPlots.slice(
    (currentPage - 1) * plotsPerPage,
    currentPage * plotsPerPage
  )

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1)
    }
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1)
    }
  }

  const renderPageButtons = () => {
    const getPageNumbers = () => {
      if (totalPages <= 3)
        return Array.from({ length: totalPages }, (_, i) => i + 1)

      if (currentPage <= 2) return [1, 2, 3]
      if (currentPage >= totalPages - 1)
        return [totalPages - 2, totalPages - 1, totalPages]

      return [currentPage - 1, currentPage, currentPage + 1]
    }

    const pageNumbers = getPageNumbers()

    return pageNumbers.map(pageNum => (
      <button
        key={pageNum}
        onClick={() => setCurrentPage(pageNum)}
        className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
          pageNum === currentPage
            ? `${theme.tailwind.bg13} text-white shadow-lg scale-110`
            : `bg-white/10 ${theme.tailwind.text9} hover:bg-slate-400 hover:text-white`
        }`}
      >
        {pageNum}
      </button>
    ))
  }

  useEffect(() => {
    console.log('Active Society Users:', activeSociety?.users)
  }, [activeSociety])

  const filteredUsers = (activeSociety?.users || [])
    .filter(user => user.role === 'Customer')
    .filter(user =>
      user.username.toLowerCase().includes(searchQuery.toLowerCase())
    )

  const handleSelectCustomer = (user, setFieldValue) => {
    setSearchQuery(user.username)
    setSelectedCustomer(user)
    setFieldValue('customer', user._id)
    setIsDropdownOpen(false)
  }

  const handleFilterChange = e => {
    const { name, value } = e.target
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const formatPhoneNumber = phone => {
    let cleaned = ('' + phone).replace(/\D/g, '')
    if (cleaned.startsWith('92')) {
      cleaned = '92' + cleaned.slice(2)
    }
    if (cleaned.length > 3 && cleaned.length <= 5) {
      return `+92 ${cleaned.slice(2, 5)}-${cleaned.slice(5)}`
    } else if (cleaned.length > 5) {
      return `+92 ${cleaned.slice(2, 5)}-${cleaned.slice(5, 12)}`
    }
    return `+92 ${cleaned.slice(2)}`
  }

  const selectedPlot = currentPlots.find(plot => plot._id === plotId)
  const showAlert =
    selectedPlot &&
    (selectedPlot.category === 'Corner' ||
      selectedPlot.category === 'Boulevard')

  const resetAllFields = (resetForm, setFieldValue) => {
    resetForm({
      values: {
        plot: '',
        customer: '',
        paymentMode: 'Full',
        bookingAmount: '',
        dealerCommission: '',
        dealerPhone: '',
        dealerName: '',
        initialPayment: '',
        installmentYears: '',
        receiptNo: '',
        offerTag: ''
      }
    })
    setPlotId('')
    setSearchQuery('')
    setSelectedCustomer(null)
    setIsDropdownOpen(false)
    setFormattedAmount('')
    setAmountInWords('')
    setDealerCommissionFormatted('')
    setFormattedInitialPayment('')
    setCurrentPage(1)
  }

  if (!activeSociety) {
    return (
      <div
        className={`flex items-center justify-center min-h-screen ${gradient}`}
      >
        <div
          className={`bg-black/40 border ${theme.tailwind.border5} px-12 py-10 rounded-2xl shadow-2xl transform transition-all hover:scale-105 hover:shadow-4xl`}
        >
          <h2
            className={`text-4xl font-extrabold mb-6 ${theme.tailwind.text9} tracking-wide ${theme.tailwind.drop_shadow}`}
          >
            No Active Society
          </h2>
          <p
            className={`text-xl ${theme.tailwind.text} opacity-90 tracking-wider leading-relaxed`}
          >
            Please select an active society before creating a booking.
          </p>
        </div>
      </div>
    )
  }
  return (
    <div
      className={`p-8 py-12 px-6 ${gradient} rounded-2xl ${theme.tailwind.shadows} min-h-screen`}
    >
      <div className='max-w-7xl mx-auto space-y-8'>
        <div
          className={`${gradient} shadow-2xl rounded-2xl border-2 ${theme.tailwind.border6} p-10 transform transition-all hover:scale-[1] hover:shadow-3xl`}
        >
          <div className='flex justify-between items-center'>
            <h2
              className={`text-5xl font-bold text-transparent bg-clip-text ${theme.tailwind.text8} mb-8 uppercase tracking-wide`}
            >
              {activeSociety?.name || 'Booking'} Booking
            </h2>
            <button
              onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
              className={`group relative flex items-center gap-3 px-6 py-3 ${theme.tailwind.bg10} ${theme.tailwind.text} rounded-xl ${theme.tailwind.bgHover3} transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:ring-2 ${theme.tailwind.ringHover}`}
            >
              <div
                className={`absolute inset-0 ${theme.tailwind.bg11} rounded-xl opacity-0 group-hover:opacity-100 transition-opacity`}
              ></div>
              <span className='relative z-10'>
                {isFiltersExpanded ? <X size={24} /> : <Filter size={24} />}
              </span>
              <span className='relative z-10 font-semibold'>
                {isFiltersExpanded ? 'Close Filters' : 'Open Filters'}
              </span>
            </button>
          </div>

          {isFiltersExpanded && (
            <div
              className={`grid grid-cols-3 gap-4 ${theme.tailwind.bg2} p-6 rounded-xl border ${theme.tailwind.border7} mb-6 animate-slide-down`}
            >
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Number
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    name='plotNumber'
                    value={filters.plotNumber}
                    onChange={handleFilterChange}
                    placeholder='Search Plot Number'
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  />
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Block
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    name='block'
                    value={filters.block}
                    onChange={handleFilterChange}
                    placeholder='Search Block'
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  />
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Size
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    name='size'
                    value={filters.size}
                    onChange={handleFilterChange}
                    placeholder='Search Size'
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  />
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Marla
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    name='marla'
                    value={filters.marla}
                    onChange={handleFilterChange}
                    placeholder='Search Size'
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  />
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Category
                </label>
                <div className='relative'>
                  <select
                    name='category'
                    value={filters.category}
                    onChange={handleFilterChange}
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  >
                    <option value=''>All Categories</option>
                    <option value='General'>General</option>
                    <option value='Corner'>Corner</option>
                    <option value='Park Face'>Park Face</option>
                    <option value='Boulevard'>Boulevard</option>
                  </select>
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Booking Status
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    name='booking_status'
                    value={filters.booking_status}
                    onChange={handleFilterChange}
                    placeholder='Search Status'
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  />
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
              <div className='relative'>
                <label className={`block ${theme.tailwind.text9} mb-2`}>
                  Plot Type
                </label>
                <div className='relative'>
                  <select
                    name='type'
                    value={filters.type}
                    onChange={handleFilterChange}
                    className={`w-full pl-10 pr-4 py-3 rounded-lg ${theme.tailwind.bg5} border ${theme.tailwind.border8} ${theme.tailwind.focus3} focus:ring-2 ${theme.tailwind.focusRing1} ${theme.tailwind.text9}`}
                  >
                    <option value=''>All Types</option>
                    <option value='Residential'>Residential</option>
                    <option value='Commercial'>Commercial</option>
                  </select>
                  <Search
                    className={`absolute left-3 top-1/2 -translate-y-1/2 ${theme.tailwind.text}`}
                    size={20}
                  />
                </div>
              </div>
            </div>
          )}

          <Formik
            initialValues={{
              plot: '',
              customer: '',
              paymentMode: 'Full',
              bookingAmount: '',
              dealerCommission: '',
              dealerPhone: '+92 ',
              dealerName: '',
              initialPayment: '',
              installmentYears: '',
              receiptNo: '',
              offerTag: '',
              bookingDate: ''
            }}
            validationSchema={BookingValidationSchema}
            onSubmit={async (
              values,
              { resetForm, setSubmitting, setFieldValue }
            ) => {
              try {
                const bookingDate = values.bookingDate
                  ? new Date(values.bookingDate).toISOString().split('T')[0]
                  : ''
                const bookingData = {
                  plot_id: values.plot,
                  customer_id: values.customer,
                  total_amount: parseFloat(values.bookingAmount),
                  dealer_comission: parseFloat(values.dealerCommission),
                  dealer_name: values.dealerName,
                  dealer_phone: values.dealerPhone,
                  initial_payment: parseFloat(values.initialPayment) || 0,
                  payment_mode: values.paymentMode,
                  installment_years:
                    values.paymentMode !== 'Full'
                      ? parseFloat(values.installmentYears)
                      : 0,
                  receipt_no: values.receiptNo,
                  booking_date: bookingDate,
                  offertag: values.offerTag
                }

                const createdBooking = await dispatch(
                  createBooking(bookingData)
                ).unwrap()

                if (createdBooking && createdBooking.booking) {
                  enqueueSnackbar('Booking created successfully!', {
                    variant: 'success'
                  })
                  // await dispatch(getAllBookings());
                  setLocalPlots(prevPlots => {
                    const updatedPlots = prevPlots.map(plot =>
                      plot._id === values.plot
                        ? {
                            ...plot,
                            booking_status: 'Booked',
                            status: 'Sold'
                          }
                        : plot
                    )

                    // Sort plots by plot_number in ascending order
                    return updatedPlots.sort(
                      (a, b) => a.plot_number - b.plot_number
                    )
                  })
                  await dispatch(getAllBookings())

                  resetAllFields(resetForm, setFieldValue)
                }
              } catch (err) {
                enqueueSnackbar(`Error: ${err.message || err}`, {
                  variant: 'error'
                })
              } finally {
                setSubmitting(false)
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, errors, touched }) => (
              <Form className='space-y-6'>
                <div className='space-y-4'>
                  <div
                    className={`overflow-x-auto rounded-xl shadow-2xl backdrop-blur-sm border-2 ${theme.tailwind.border7}`}
                  >
                    <div
                      className={`p-6 ${theme.tailwind.bg2} border-b-2 ${theme.tailwind.border7}`}
                    >
                      <h3
                        className={`text-3xl font-semibold text-transparent bg-clip-text ${theme.tailwind.text8}`}
                      >
                        Plots Detail
                      </h3>
                    </div>
                    <table
                      className={`min-w-full ${theme.tailwind.bg6} shadow-lg rounded-lg overflow-hidden`}
                    >
                      <thead
                        className={`${theme.tailwind.bgr2} ${theme.tailwind.text9} uppercase font-semibold`}
                      >
                        <tr>
                          {[
                            'Plot Number',
                            'Type',
                            'Block',
                            'Size (sq.ft)',
                            'Marla',
                            'Category',
                            'Booking Status',
                            'Status',
                            'Action'
                          ].map(header => (
                            <th
                              key={header}
                              className={`py-4 px-6 border-b ${theme.tailwind.border7} text-left`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='text-gray-300'>
                        {currentPlots.map((plot, index) => (
                          <tr
                            key={plot._id}
                            onClick={() => {
                              if (
                                plot.booking_status !== 'Sold' &&
                                plot.booking_status !== 'Booked' &&
                                plot.booking_status !== 'Transfer'
                              ) {
                                handlePlotSelect(plot._id, setFieldValue)
                              }
                            }}
                            className={`border-b ${
                              theme.tailwind.border9
                            } transition-colors duration-300 ${
                              plot.booking_status === 'Sold' ||
                              plot.booking_status === 'Booked' ||
                              plot.booking_status === 'Transfer'
                                ? `${theme.tailwind.bg12} text-gray-400 cursor-not-allowed`
                                : index % 2 === 0
                                ? `${theme.tailwind.bg2}`
                                : `${theme.tailwind.bg6}`
                            } ${theme.tailwind.bgHover4}`}
                          >
                            {[
                              plot.plot_number,
                              plot.plot_type,
                              plot.block,
                              plot.size,
                              plot.marla,
                              plot.category,
                              plot.booking_status,
                              plot.status
                            ].map((value, colIndex) => (
                              <td
                                key={colIndex}
                                className={`py-4 px-6 text-left ${
                                  colIndex === 5 || colIndex === 6
                                    ? 'text-sm font-semibold'
                                    : `${theme.tailwind.text9}`
                                }`}
                              >
                                {colIndex === 5 ? (
                                  <span
                                    className={`px-3 py-1 rounded-full text-xs ${
                                      value === 'Booked'
                                        ? 'bg-yellow-100 text-yellow-600'
                                        : 'bg-green-100 text-green-600'
                                    }`}
                                  >
                                    {value}
                                  </span>
                                ) : colIndex === 6 ? (
                                  <span
                                    className={`px-3 py-1 rounded-full text-xs ${
                                      value === 'Available'
                                        ? 'bg-green-100 text-green-600'
                                        : 'bg-red-100 text-red-600'
                                    }`}
                                  >
                                    {value}
                                  </span>
                                ) : (
                                  value
                                )}
                              </td>
                            ))}
                            <td className='py-4 px-6'>
                              <button
                                type='button'
                                onClick={() =>
                                  plot.booking_status !== 'Sold' &&
                                  plot.booking_status !== 'Booked' &&
                                  plot.booking_status !== 'Transfer' &&
                                  handlePlotSelect(plot._id, setFieldValue)
                                }
                                disabled={
                                  plot.booking_status === 'Sold' ||
                                  plot.booking_status === 'Booked' ||
                                  plot.booking_status === 'Transfer'
                                }
                                className={`text-sm font-semibold rounded px-3 py-1 transition-all duration-300 ${
                                  plot.booking_status === 'Sold' ||
                                  plot.booking_status === 'Booked' ||
                                  plot.booking_status === 'Transfer'
                                    ? `${theme.tailwind.bg12} text-gray-600 cursor-not-allowed`
                                    : plotId === plot._id
                                    ? 'bg-red-500 text-white hover:bg-red-600'
                                    : `${theme.tailwind.bg13} text-white ${theme.tailwind.bgHover5}`
                                }`}
                              >
                                {plotId === plot._id ? 'Cancel' : 'Select'}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className='flex justify-center items-center gap-3 sm:gap-4 mt-4 sm:mt-6 mb-4 sm:mb-5 p-2 sm:p-4'>
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className={`group flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full transition-all duration-300 ${
                          currentPage === 1
                            ? 'bg-gray-600 cursor-not-allowed'
                            : `bg-white/10 ${theme.tailwind.bgHover5}`
                        }`}
                      >
                        <ChevronLeft
                          className={`w-3 h-3 sm:w-4 sm:h-4 ${
                            currentPage === 1
                              ? 'text-gray-400'
                              : `${theme.tailwind.text9} group-hover:text-white`
                          }`}
                        />
                      </button>

                      <div className='flex items-center gap-1 sm:gap-2'>
                        {currentPage > 2 && totalPages > 3 && (
                          <>
                            <button
                              onClick={() => setCurrentPage(1)}
                              className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 ${theme.tailwind.text9} ${theme.tailwind.bgHover6} hover:text-white transition-all duration-300`}
                            >
                              1
                            </button>
                            <span className={`${theme.tailwind.text9} px-1`}>
                              ...
                            </span>
                          </>
                        )}

                        {renderPageButtons()}

                        {currentPage < totalPages - 1 && totalPages > 3 && (
                          <>
                            <span className={`${theme.tailwind.text9} px-1`}>
                              ...
                            </span>
                            <button
                              onClick={() => setCurrentPage(totalPages)}
                              className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium bg-white/10 ${theme.tailwind.text9} ${theme.tailwind.bgHover6} hover:text-white transition-all duration-300`}
                            >
                              {totalPages}
                            </button>
                          </>
                        )}
                      </div>

                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className={`group flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full transition-all duration-300 ${
                          currentPage === totalPages
                            ? 'bg-gray-600 cursor-not-allowed'
                            : `bg-white/10 ${theme.tailwind.bgHover5}`
                        }`}
                      >
                        <ChevronRight
                          className={`w-3 h-3 sm:w-4 sm:h-4 ${
                            currentPage === totalPages
                              ? 'text-gray-400'
                              : `${theme.tailwind.text9} group-hover:text-white`
                          }`}
                        />
                      </button>
                    </div>
                  </div>

                  {/* Alert Message */}
                  {showAlert && (
                    <div className='mt-4 p-4 rounded-lg border border-yellow-200 bg-yellow-50/80 shadow-sm'>
                      <p className='text-yellow-800 text-sm font-medium'>
                        This plot is categorized as {selectedPlot.category}. An
                        additional 10% charge will be added to the total amount.
                      </p>
                    </div>
                  )}
                </div>
                {/* Plot ID Field */}
                <div>
                  <label
                    className={`block ${theme.tailwind.text9} font-medium`}
                  >
                    Plot ID:
                  </label>
                  <Field
                    type='text'
                    name='plot'
                    readOnly
                    value={values.plot}
                    className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md cursor-not-allowed`}
                  />
                  <ErrorMessage
                    name='plot'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>
                {/* Customer Selection */}
                <div>
                  <label
                    className={`block ${theme.tailwind.text9} font-medium`}
                  >
                    Customer:
                  </label>
                  <Field
                    type='text'
                    name='customerSearch'
                    disabled={!values.plot}
                    value={searchQuery}
                    onChange={e => {
                      setSearchQuery(e.target.value)
                      setIsDropdownOpen(true)
                    }}
                    onFocus={() => setIsDropdownOpen(true)}
                    onBlur={() =>
                      setTimeout(() => setIsDropdownOpen(false), 200)
                    }
                    placeholder='Search customer'
                    className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                  />
                  <ErrorMessage
                    name='customer'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />

                  {/* Customer Dropdown */}
                  {isDropdownOpen && filteredUsers.length > 0 && (
                    <div
                      className={`w-full ${theme.tailwind.bg5} ${theme.tailwind.text9} rounded-md mt-1 max-h-60 overflow-y-auto shadow-lg`}
                    >
                      {filteredUsers.slice(0, 10).map(user => (
                        <div
                          key={user._id}
                          onClick={() =>
                            handleSelectCustomer(user, setFieldValue)
                          }
                          className={`px-4 py-2 cursor-pointer ${theme.tailwind.bgHover5}`}
                        >
                          {user.username}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* Payment Mode Selection */}
                <div>
                  <label
                    className={`block ${theme.tailwind.text9} font-medium`}
                  >
                    Payment Mode:
                  </label>
                  <Field
                    as='select'
                    name='paymentMode'
                    disabled={!values.plot}
                    className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                  >
                    <option value='Full'>Full</option>
                    <option value='Installment'>Installment</option>
                  </Field>
                </div>
                <div className='flex space-x-4'>
                  {/* Receipt Number Field */}
                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Receipt Number:
                    </label>
                    <Field
                      type='text'
                      name='receiptNo'
                      className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      placeholder='Enter receipt number'
                    />
                    <ErrorMessage
                      name='receiptNo'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>

                  {/* Booking Date Field */}
                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Booking Date:
                    </label>
                    <div className='w-full'>
                      <DatePicker
                        selected={
                          values.bookingDate &&
                          !isNaN(new Date(values.bookingDate).getTime())
                            ? new Date(values.bookingDate)
                            : null
                        }
                        onChange={date => {
                          // Ensure date is a Date object
                          if (date && !isNaN(date.getTime())) {
                            setFieldValue(
                              'bookingDate',
                              date.toISOString().split('T')[0]
                            )
                          } else {
                            setFieldValue('bookingDate', '')
                          }
                        }}
                        dateFormat='dd/MM/yyyy'
                        placeholderText='DD/MM/YYYY'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        wrapperClassName='w-full'
                        className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      />
                    </div>
                    <ErrorMessage
                      name='bookingDate'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>
                </div>
                <div className='flex space-x-4'>
                  {/* Total Plot Amount */}
                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Total Plot Amount:
                    </label>
                    <Field
                      type='text'
                      name='bookingAmount'
                      className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      value={formattedAmount}
                      onChange={e => handlePlotAmountChange(e, setFieldValue)}
                    />
                    <ErrorMessage
                      name='bookingAmount'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>

                  {/* Amount in Words */}
                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Amount in Words:
                    </label>

                    <Field
                      type='text'
                      readOnly
                      name='bookingAmount'
                      className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      value={amountInWords}
                    />
                  </div>
                </div>
                <div className='flex-1'>
                  <label
                    className={`block ${theme.tailwind.text9} font-medium`}
                  >
                    Offer Tag:
                  </label>
                  <Field
                    type='text'
                    name='offerTag'
                    className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                    value={values.offerTag}
                    onChange={e => setFieldValue('offerTag', e.target.value)}
                  />
                  <ErrorMessage
                    name='offerTag'
                    component='div'
                    className='text-red-500 text-sm mt-1'
                  />
                </div>

                {/* Installment Fields */}
                {values.paymentMode === 'Installment' && (
                  <>
                    <div>
                      <label
                        className={`block ${theme.tailwind.text9} font-medium`}
                      >
                        Initial Payment:
                      </label>
                      <Field
                        type='text' // Change from 'number' to 'text' to handle commas as input
                        name='initialPayment'
                        className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                        value={formattedInitialPayment} // Set the formatted value here
                        onChange={e =>
                          handleInitialPaymentChange(e, setFieldValue)
                        } // Use the handler
                      />
                      <ErrorMessage
                        name='initialPayment'
                        component='div'
                        className='text-red-500 text-sm mt-1'
                      />
                    </div>

                    <div>
                      <label
                        className={`block ${theme.tailwind.text9} font-medium`}
                      >
                        Installments Years:
                      </label>
                      <Field
                        type='number'
                        name='installmentYears'
                        className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      />
                      <ErrorMessage
                        name='installmentYears'
                        component='div'
                        className='text-red-500 text-sm mt-1'
                      />
                    </div>
                  </>
                )}
                {/* Dealer Commission */}
                <h4 className={`${theme.tailwind.text} text-lg`}>
                  Dealer Details (Optional)
                </h4>
                <div className='flex space-x-6'>
                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Dealer Commission:
                    </label>
                    <Field
                      type='text'
                      name='dealerCommission'
                      className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      value={dealerCommissionFormatted}
                      onChange={e =>
                        handleDealerCommissionChange(e, setFieldValue)
                      }
                    />
                    <ErrorMessage
                      name='dealerCommission'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>

                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Dealer Name:
                    </label>
                    <Field
                      type='text'
                      name='dealerName'
                      className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      value={values.dealerName}
                      onChange={e =>
                        setFieldValue('dealerName', e.target.value)
                      }
                    />
                    <ErrorMessage
                      name='dealerName'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>

                  <div className='flex-1'>
                    <label
                      className={`block ${theme.tailwind.text9} font-medium`}
                    >
                      Dealer Phone:
                    </label>
                    <Field
                      type='text'
                      name='dealerPhone'
                      placeholder='+92 3XX-XXXXXXX'
                      className={`w-full px-4 py-2 border ${theme.tailwind.text9} ${theme.tailwind.bg5} ${theme.tailwind.bgFocus} ${theme.tailwind.border10} rounded-md focus:outline-none focus:ring-2 ${theme.tailwind.focusRing2} transition`}
                      value={
                        values.dealerPhone
                          ? formatPhoneNumber(values.dealerPhone)
                          : ''
                      }
                      onChange={e =>
                        setFieldValue(
                          'dealerPhone',
                          formatPhoneNumber(e.target.value)
                        )
                      }
                    />

                    <ErrorMessage
                      name='dealerPhone'
                      component='div'
                      className='text-red-500 text-sm mt-1'
                    />
                  </div>
                </div>
                {/* Submit Button */}
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className={`w-full py-2 px-4 rounded-md transition ${
                    isSubmitting
                      ? `${theme.tailwind.bg5} ${theme.tailwind.text9}`
                      : `${theme.tailwind.bg13} text-white shadow-lg ${theme.tailwind.shadowing} ${theme.tailwind.bgHover5}`
                  }`}
                >
                  {isSubmitting ? 'Creating Booking...' : 'Create Booking'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default BookingForm
