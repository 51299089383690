// import React, { useState } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const SimpleLineChart = ({ data }) => {
//   const [selectedTimeFrame, setSelectedTimeFrame] = useState("Today");
//   const [selectedMonth, setSelectedMonth] = useState("");
//   const [selectedYear, setSelectedYear] = useState("");
//   const [showMonthDropdown, setShowMonthDropdown] = useState(false);
//   const [showYearDropdown, setShowYearDropdown] = useState(false);

//   const formattedData = data.map((entry) => {
//     const [month, year] = entry.name.split("-");
//     return {
//       ...entry,
//       month: parseInt(month),
//       year: year,
//     };
//   });

//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];
//   const availableYears = [...new Set(formattedData.map((entry) => entry.year))]
//     .filter((year) => year && year !== "null")
//     .sort();
//   let filteredData = formattedData;

//   if (selectedTimeFrame === "Monthly" && selectedMonth) {
//     const monthIndex = monthNames.indexOf(selectedMonth) + 1;
//     filteredData = formattedData.filter(
//       (entry) =>
//         entry.month === monthIndex &&
//         entry.year === new Date().getFullYear().toString()
//     );
//   }

//   if (selectedTimeFrame === "Yearly" && selectedYear) {
//     filteredData = formattedData
//       .filter((entry) => entry.year === selectedYear)
//       .sort((a, b) => a.month - b.month)
//       .map((entry) => ({
//         ...entry,
//         name: monthNames[entry.month - 1],
//       }));
//   }
//   const currentYear = new Date().getFullYear().toString();
//   const availableMonths = [
//     ...new Set(
//       formattedData
//         .filter((entry) => entry.year === currentYear)
//         .map((entry) => monthNames[entry.month - 1])
//     ),
//   ];

//   return (
//     <div className="w-full h-full max-w-7xl p-2 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-xl">
//       <div className="flex flex-col md:flex-row p-6 justify-between items-start md:items-center mb-6 gap-4">
//         <div>
//           <h2 className="text-2xl font-bold text-teal-300 mb-2">
//             Financial Overview
//           </h2>
//           <p className="text-slate-400">
//             Track your financial metrics over time
//           </p>
//         </div>

//         <div className="relative flex gap-2">
//           <select
//             value={selectedTimeFrame}
//             onChange={(e) => {
//               setSelectedTimeFrame(e.target.value);
//               setShowMonthDropdown(e.target.value === "Monthly");
//               setShowYearDropdown(e.target.value === "Yearly");
//               setSelectedMonth("");
//               setSelectedYear("");
//             }}
//             className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//           >
//             <option value="Today">Today</option>
//             <option value="Weekly">Last Week</option>
//             <option value="Monthly">Monthly</option>
//             <option value="Yearly">Yearly</option>
//           </select>

//           {showMonthDropdown && (
//             <select
//               value={selectedMonth}
//               onChange={(e) => setSelectedMonth(e.target.value)}
//               className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//             >
//               <option value="">Select Month</option>
//               {availableMonths.map((month) => (
//                 <option key={month} value={month}>
//                   {month}
//                 </option>
//               ))}
//             </select>
//           )}

//           {showYearDropdown && (
//             <select
//               value={selectedYear}
//               onChange={(e) => setSelectedYear(e.target.value)}
//               className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//             >
//               <option value="">Select Year</option>
//               {availableYears.map((year) => (
//                 <option key={year} value={year}>
//                   {year}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>
//       </div>

//       <div className="h-[250px] sm:h-[300px] md:h-[350px] lg:h-[380px] w-full">
//         <ResponsiveContainer width="100%" height="100%">
//           <LineChart
//             data={filteredData}
//             margin={{ top: 5, right: 10, left: 5, bottom: 5 }}
//           >
//             <CartesianGrid strokeDasharray="3 3" stroke="#475569" />
//             <XAxis
//               dataKey="name"
//               stroke="#5eead4"
//               tick={{ fill: "#5eead4" }}
//               fontSize="12"
//               tickMargin={8}
//             />
//             <YAxis
//               stroke="#5eead4"
//               tick={{ fill: "#5eead4" }}
//               fontSize="12"
//               tickMargin={8}
//             />
//             <Tooltip
//               contentStyle={{
//                 backgroundColor: "#1e293b",
//                 border: "none",
//                 borderRadius: "0.5rem",
//                 color: "#5eead4",
//                 padding: "8px",
//               }}
//             />
//             <Legend
//               wrapperStyle={{ padding: "8px 4px", color: "#5eead4" }}
//               iconSize={12}
//               align="center"
//               verticalAlign="bottom"
//             />
//             <Line
//               type="monotone"
//               dataKey="income"
//               stroke="#10b981"
//               strokeWidth={1.5}
//               dot={{ fill: "#10b981", strokeWidth: 1.5, r: 3 }}
//             />
//             <Line
//               type="monotone"
//               dataKey="expense"
//               stroke="#f43f5e"
//               strokeWidth={1.5}
//               dot={{ fill: "#f43f5e", strokeWidth: 1.5, r: 3 }}
//             />
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   );
// };

// export default SimpleLineChart;

// import React, { useState } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const SimpleLineChart = ({ data }) => {
//   const currentDate = new Date();
//   const currentMonthName = new Intl.DateTimeFormat("en-US", {
//     month: "long",
//   }).format(currentDate);

//   const [selectedTimeFrame, setSelectedTimeFrame] = useState("All");
//   const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
//   const [selectedYear, setSelectedYear] = useState("");
//   const [showMonthDropdown, setShowMonthDropdown] = useState(false);
//   const [showYearDropdown, setShowYearDropdown] = useState(false);

//   const formattedData = data.map((entry) => {
//     if (!entry.name) {
//       return {
//         ...entry,
//         month: null,
//         year: null,
//       };
//     }

//     const [month, year] = entry.name.split("-");
//     return {
//       ...entry,
//       month: parseInt(month),
//       year: year,
//     };
//   });

//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   const availableYears = [...new Set(formattedData.map((entry) => entry.year))]
//     .filter((year) => year && year !== "null")
//     .sort();

//   let filteredData = formattedData;

//   // Filter data based on selected timeframe
//   switch (selectedTimeFrame) {
//     case "Today":
//       const today = currentDate.getDate();
//       const currentMonth = currentDate.getMonth() + 1;
//       const currentYear = currentDate.getFullYear().toString();

//       filteredData = formattedData.filter((entry) => {
//         const entryDate = new Date(entry.year, entry.month - 1);
//         return entry.month === currentMonth && entry.year === currentYear;
//       });

//       // If no data for today, show empty graph with lines
//       if (filteredData.length === 0) {
//         filteredData = [{ name: "Today", income: 0, expense: 0 }];
//       }
//       break;

//     case "Weekly":
//       // Always show last week's empty graph with no data
//       filteredData = [
//         { name: "7 days ago", income: 0, expense: 0 },
//         { name: "Today", income: 0, expense: 0 },
//       ];
//       break;

//     case "Monthly":
//       const monthIndex = monthNames.indexOf(selectedMonth) + 1;
//       filteredData = formattedData.filter(
//         (entry) =>
//           entry.month === monthIndex &&
//           entry.year === currentDate.getFullYear().toString()
//       );

//       // If no data for the month, show empty graph
//       if (filteredData.length === 0) {
//         filteredData = [
//           { name: "Start of Month", income: 0, expense: 0 },
//           { name: "End of Month", income: 0, expense: 0 },
//         ];
//       }
//       break;

//     case "Yearly":
//       if (selectedYear) {
//         filteredData = formattedData
//           .filter((entry) => entry.year === selectedYear)
//           .sort((a, b) => a.month - b.month)
//           .map((entry) => ({
//             ...entry,
//             name: monthNames[entry.month - 1],
//           }));

//         // If no data for the year, show empty graph
//         if (filteredData.length === 0) {
//           filteredData = [
//             { name: "Start of Year", income: 0, expense: 0 },
//             { name: "End of Year", income: 0, expense: 0 },
//           ];
//         }
//       }
//       break;

//     case "All":
//     default:
//       // Keep all data, just ensure it's properly sorted
//       filteredData = formattedData.sort((a, b) => {
//         const dateA = new Date(a.year, a.month - 1);
//         const dateB = new Date(b.year, b.month - 1);
//         return dateA - dateB;
//       });

//       // If no data at all, show empty graph
//       if (filteredData.length === 0) {
//         filteredData = [
//           { name: "Start", income: 0, expense: 0 },
//           { name: "End", income: 0, expense: 0 },
//         ];
//       }
//       break;
//   }

//   const currentYear = currentDate.getFullYear().toString();
//   const availableMonths = [
//     ...new Set(
//       formattedData
//         .filter((entry) => entry.year === currentYear)
//         .map((entry) => monthNames[entry.month - 1])
//     ),
//   ];

//   return (
//     <div className="w-full h-full max-w-7xl p-2 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-xl">
//       <div className="flex flex-col md:flex-row p-6 justify-between items-start md:items-center mb-6 gap-4">
//         <div>
//           <h2 className="text-2xl font-bold text-teal-300 mb-2">
//             Financial Overview
//           </h2>
//           <p className="text-slate-400">
//             Track your financial metrics over time
//           </p>
//         </div>

//         <div className="relative flex gap-2">
//           <select
//             value={selectedTimeFrame}
//             onChange={(e) => {
//               const newTimeFrame = e.target.value;
//               setSelectedTimeFrame(newTimeFrame);
//               setShowMonthDropdown(newTimeFrame === "Monthly");
//               setShowYearDropdown(newTimeFrame === "Yearly");
//               if (newTimeFrame === "Monthly") {
//                 setSelectedMonth(currentMonthName);
//               } else {
//                 setSelectedMonth("");
//               }
//               setSelectedYear("");
//             }}
//             className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//           >
//             <option value="All">All Time</option>
//             <option value="Today">Today</option>
//             <option value="Weekly">Last Week</option>
//             <option value="Monthly">Monthly</option>
//             <option value="Yearly">Yearly</option>
//           </select>

//           {showMonthDropdown && (
//             <select
//               value={selectedMonth}
//               onChange={(e) => setSelectedMonth(e.target.value)}
//               className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//             >
//               {availableMonths.map((month) => (
//                 <option key={month} value={month}>
//                   {month}
//                 </option>
//               ))}
//             </select>
//           )}

//           {showYearDropdown && (
//             <select
//               value={selectedYear}
//               onChange={(e) => setSelectedYear(e.target.value)}
//               className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//             >
//               <option value="">Select Year</option>
//               {availableYears.map((year) => (
//                 <option key={year} value={year}>
//                   {year}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>
//       </div>

//       <div className="h-[250px] sm:h-[300px] md:h-[350px] lg:h-[380px] w-full">
//         <ResponsiveContainer width="100%" height="100%">
//           <LineChart
//             data={filteredData}
//             margin={{ top: 5, right: 10, left: 5, bottom: 5 }}
//           >
//             <CartesianGrid strokeDasharray="3 3" stroke="#475569" />
//             <XAxis
//               dataKey="name"
//               stroke="#5eead4"
//               tick={{ fill: "#5eead4" }}
//               fontSize="12"
//               tickMargin={8}
//             />
//             <YAxis
//               stroke="#5eead4"
//               tick={{ fill: "#5eead4" }}
//               fontSize="12"
//               tickMargin={8}
//             />
//             <Tooltip
//               contentStyle={{
//                 backgroundColor: "#1e293b",
//                 border: "none",
//                 borderRadius: "0.5rem",
//                 color: "#5eead4",
//                 padding: "8px",
//               }}
//             />
//             <Legend
//               wrapperStyle={{ padding: "8px 4px", color: "#5eead4" }}
//               iconSize={12}
//               align="center"
//               verticalAlign="bottom"
//             />
//             <Line
//               type="monotone"
//               dataKey="income"
//               stroke="#10b981"
//               strokeWidth={1.5}
//               dot={{ fill: "#10b981", strokeWidth: 1.5, r: 3 }}
//             />
//             <Line
//               type="monotone"
//               dataKey="expense"
//               stroke="#f43f5e"
//               strokeWidth={1.5}
//               dot={{ fill: "#f43f5e", strokeWidth: 1.5, r: 3 }}
//             />
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   );
// };

// export default SimpleLineChart;

// import React, { useState } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";

// const SimpleLineChart = ({ data }) => {
//   const currentDate = new Date();
//   const currentMonthName = new Intl.DateTimeFormat("en-US", {
//     month: "long",
//   }).format(currentDate);

//   const [selectedTimeFrame, setSelectedTimeFrame] = useState("All");
//   const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
//   const [selectedYear, setSelectedYear] = useState(
//     currentDate.getFullYear().toString()
//   );
//   const [showMonthDropdown, setShowMonthDropdown] = useState(false);
//   const [showYearDropdown, setShowYearDropdown] = useState(false);

//   // Format the date from "DD-MMM-YYYY" to a proper date object
//   const formattedData = data.map((entry) => {
//     if (!entry.date) {
//       return {
//         ...entry,
//         month: null,
//         year: null,
//         formattedDate: "No Date",
//       };
//     }

//     const [day, month, year] = entry.date.split("-");
//     const date = new Date(`${month}-${day}-${year}`);
//     return {
//       ...entry,
//       month: date.getMonth() + 1,
//       year: year,
//       formattedDate: new Intl.DateTimeFormat("en-US", {
//         month: "short",
//         year: "numeric",
//       }).format(date),
//     };
//   });

//   const monthNames = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   // Get all years from 2020 to current year
//   const currentYear = currentDate.getFullYear();
//   const years = Array.from({ length: currentYear - 2019 }, (_, i) =>
//     (currentYear - i).toString()
//   );

//   let filteredData = formattedData;

//   // Filter data based on selected timeframe
//   switch (selectedTimeFrame) {
//     case "Today":
//       const today = currentDate.getDate();
//       const currentMonth = currentDate.getMonth() + 1;
//       const currentYearStr = currentDate.getFullYear().toString();

//       filteredData = formattedData.filter((entry) => {
//         return entry.month === currentMonth && entry.year === currentYearStr;
//       });

//       if (filteredData.length === 0) {
//         filteredData = [{ formattedDate: "Today", income: 0, expense: 0 }];
//       }
//       break;

//     case "Monthly":
//       const monthIndex = monthNames.indexOf(selectedMonth) + 1;
//       filteredData = formattedData.filter(
//         (entry) => entry.month === monthIndex && entry.year === selectedYear
//       );

//       if (filteredData.length === 0) {
//         filteredData = [
//           {
//             formattedDate: `${selectedMonth} ${selectedYear}`,
//             income: 0,
//             expense: 0,
//           },
//         ];
//       }
//       break;

//     case "Yearly":
//       if (selectedYear) {
//         filteredData = formattedData
//           .filter((entry) => entry.year === selectedYear)
//           .sort((a, b) => a.month - b.month);

//         if (filteredData.length === 0) {
//           filteredData = [
//             { formattedDate: selectedYear, income: 0, expense: 0 },
//           ];
//         }
//       }
//       break;

//     case "All":
//     default:
//       filteredData = formattedData.sort((a, b) => {
//         if (!a.date || !b.date) return 0;
//         const dateA = new Date(a.date.split("-").reverse().join("-"));
//         const dateB = new Date(b.date.split("-").reverse().join("-"));
//         return dateA - dateB;
//       });

//       if (filteredData.length === 0) {
//         filteredData = [{ formattedDate: "No Data", income: 0, expense: 0 }];
//       }
//       break;
//   }

//   return (
//     <div className="w-full h-full max-w-7xl p-2 bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-xl">
//       <div className="flex flex-col md:flex-row p-6 justify-between items-start md:items-center mb-6 gap-4">
//         <div>
//           <h2 className="text-2xl font-bold text-teal-300 mb-2">
//             Financial Overview
//           </h2>
//           <p className="text-slate-400">
//             Track your financial metrics over time
//           </p>
//         </div>

//         <div className="relative flex flex-wrap gap-2">
//           <select
//             value={selectedTimeFrame}
//             onChange={(e) => {
//               const newTimeFrame = e.target.value;
//               setSelectedTimeFrame(newTimeFrame);
//               setShowMonthDropdown(newTimeFrame === "Monthly");
//               setShowYearDropdown(
//                 newTimeFrame === "Yearly" || newTimeFrame === "Monthly"
//               );
//               if (newTimeFrame === "Monthly") {
//                 setSelectedMonth(currentMonthName);
//                 setSelectedYear(currentDate.getFullYear().toString());
//               }
//             }}
//             className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//           >
//             <option value="All">All Time</option>
//             <option value="Today">Today</option>
//             <option value="Monthly">Monthly</option>
//             <option value="Yearly">Yearly</option>
//           </select>

//           {selectedTimeFrame === "Monthly" && (
//             <select
//               value={selectedMonth}
//               onChange={(e) => setSelectedMonth(e.target.value)}
//               className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//             >
//               {monthNames.map((month) => (
//                 <option key={month} value={month}>
//                   {month}
//                 </option>
//               ))}
//             </select>
//           )}

//           {(selectedTimeFrame === "Monthly" ||
//             selectedTimeFrame === "Yearly") && (
//             <select
//               value={selectedYear}
//               onChange={(e) => setSelectedYear(e.target.value)}
//               className="bg-slate-700 text-teal-300 px-4 py-2 rounded-lg cursor-pointer focus:ring-2 focus:ring-teal-500 w-32"
//             >
//               {years.map((year) => (
//                 <option key={year} value={year}>
//                   {year}
//                 </option>
//               ))}
//             </select>
//           )}
//         </div>
//       </div>

//       <div className="h-[250px] sm:h-[300px] md:h-[350px] lg:h-[380px] w-full">
//         <ResponsiveContainer width="100%" height="100%">
//           <LineChart
//             data={filteredData}
//             margin={{ top: 5, right: 10, left: 5, bottom: 5 }}
//           >
//             <CartesianGrid strokeDasharray="3 3" stroke="#475569" />
//             <XAxis
//               dataKey="formattedDate"
//               stroke="#5eead4"
//               tick={{ fill: "#5eead4" }}
//               fontSize="12"
//               tickMargin={8}
//             />
//             <YAxis
//               stroke="#5eead4"
//               tick={{ fill: "#5eead4" }}
//               fontSize="12"
//               tickMargin={8}
//             />
//             <Tooltip
//               contentStyle={{
//                 backgroundColor: "#1e293b",
//                 border: "none",
//                 borderRadius: "0.5rem",
//                 color: "#5eead4",
//                 padding: "8px",
//               }}
//             />
//             <Legend
//               wrapperStyle={{ padding: "8px 4px", color: "#5eead4" }}
//               iconSize={12}
//               align="center"
//               verticalAlign="bottom"
//             />
//             <Line
//               type="monotone"
//               dataKey="income"
//               stroke="#10b981"
//               strokeWidth={1.5}
//               dot={{ fill: "#10b981", strokeWidth: 1.5, r: 3 }}
//             />
//             <Line
//               type="monotone"
//               dataKey="expense"
//               stroke="#f43f5e"
//               strokeWidth={1.5}
//               dot={{ fill: "#f43f5e", strokeWidth: 1.5, r: 3 }}
//             />
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   );
// };

// export default SimpleLineChart;

import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const SimpleLineChart = ({ data }) => {
  const currentDate = new Date();
  const currentMonthName = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(currentDate);
const {  gradient, theme } = useSidebarContext();
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("All");
  const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
  const [selectedYear, setSelectedYear] = useState(
    currentDate.getFullYear().toString()
  );

  // Format the date from "DD-MMM-YYYY" to a proper date object
  const formattedData = data.map((entry) => {
    if (!entry.date) {
      return {
        ...entry,
        month: null,
        year: null,
        formattedDate: "No Date",
        day: null,
      };
    }

    const [day, month, year] = entry.date.split("-");
    const date = new Date(`${month}-${day}-${year}`);
    return {
      ...entry,
      month: date.getMonth() + 1,
      year: year,
      day: parseInt(day),
      formattedDate: new Intl.DateTimeFormat("en-US", {
        month: "short",
        year: "numeric",
        day: "numeric",
      }).format(date),
    };
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get all years from 2020 to current year
  const currentYear = currentDate.getFullYear();
  const years = Array.from({ length: currentYear - 2019 }, (_, i) =>
    (currentYear - i).toString()
  );

  let filteredData = formattedData;
  let yAxisDataKey = "formattedDate";

  // Filter data based on selected timeframe
  switch (selectedTimeFrame) {
    case "Today":
      const today = currentDate.getDate();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYearStr = currentDate.getFullYear().toString();

      filteredData = formattedData.filter((entry) => {
        return (
          entry.day === today &&
          entry.month === currentMonth &&
          entry.year === currentYearStr
        );
      });

      if (filteredData.length === 0) {
        filteredData = [{ formattedDate: "Today", income: 0, expense: 0 }];
      }
      break;

    case "Monthly":
      const monthIndex = monthNames.indexOf(selectedMonth) + 1;
      // Create an array of all days in the selected month
      const daysInMonth = new Date(
        parseInt(selectedYear),
        monthIndex,
        0
      ).getDate();
      const emptyMonthData = Array.from({ length: daysInMonth }, (_, i) => ({
        formattedDate: `${i + 1}`,
        day: i + 1,
        income: 0,
        expense: 0,
      }));

      const monthlyData = formattedData.filter(
        (entry) => entry.month === monthIndex && entry.year === selectedYear
      );

      // Merge existing data with empty days
      filteredData = emptyMonthData.map((emptyDay) => {
        const matchingDay = monthlyData.find(
          (entry) => entry.day === emptyDay.day
        );
        return matchingDay || emptyDay;
      });

      yAxisDataKey = "day";
      break;

    case "Yearly":
      if (selectedYear) {
        // Create array with all months
        const emptyYearData = monthNames.map((month, index) => ({
          formattedDate: month.substring(0, 3),
          month: index + 1,
          income: 0,
          expense: 0,
        }));

        const yearlyData = formattedData.filter(
          (entry) => entry.year === selectedYear
        );

        // Aggregate data by month
        filteredData = emptyYearData.map((emptyMonth) => {
          const monthData = yearlyData.filter(
            (entry) => entry.month === emptyMonth.month
          );
          if (monthData.length > 0) {
            return {
              ...emptyMonth,
              income: monthData.reduce((sum, entry) => sum + entry.income, 0),
              expense: monthData.reduce((sum, entry) => sum + entry.expense, 0),
            };
          }
          return emptyMonth;
        });
      }
      break;

    case "All":
    default:
      filteredData = formattedData.sort((a, b) => {
        if (!a.date || !b.date) return 0;
        const dateA = new Date(a.date.split("-").reverse().join("-"));
        const dateB = new Date(b.date.split("-").reverse().join("-"));
        return dateA - dateB;
      });

      if (filteredData.length === 0) {
        filteredData = [{ formattedDate: "No Data", income: 0, expense: 0 }];
      }
      break;
  }

  return (
    <div className={`w-full h-full max-w-7xl p-2 ${gradient} rounded-2xl shadow-xl`}>
      <div className="flex flex-col md:flex-row p-6 justify-between items-start md:items-center mb-6 gap-4">
        <div>
          <h2 className={`text-2xl font-bold ${theme.tailwind.text4} mb-2`}>
            Financial Overview
          </h2>
          <p className={`${theme.tailwind.text}`}>
            Track your financial metrics over time
          </p>
        </div>

        <div className="relative flex flex-wrap gap-2">
          <select
            value={selectedTimeFrame}
            onChange={(e) => {
              const newTimeFrame = e.target.value;
              setSelectedTimeFrame(newTimeFrame);
              if (newTimeFrame === "Monthly") {
                setSelectedMonth(currentMonthName);
                setSelectedYear(currentDate.getFullYear().toString());
              }
            }}
            className={`${theme.tailwind.bg5} ${theme.tailwind.text4} px-4 py-2 rounded-lg cursor-pointer focus:ring-2 ${theme.tailwind.focusRing} w-32`}
          >
            <option value="All">All Time</option>
            <option value="Today">Today</option>
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
          </select>

          {selectedTimeFrame === "Monthly" && (
            <select
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className={`${theme.tailwind.bg5} ${theme.tailwind.text4} px-4 py-2 rounded-lg cursor-pointer focus:ring-2 ${theme.tailwind.focusRing} w-32`}
            >
              <option value={currentMonthName}>{currentMonthName}</option>
            </select>
          )}

          {(selectedTimeFrame === "Monthly" ||
            selectedTimeFrame === "Yearly") && (
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className={`${theme.tailwind.bg5} ${theme.tailwind.text4} px-4 py-2 rounded-lg cursor-pointer focus:ring-2 ${theme.tailwind.focusRing} w-32`}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      <div className="h-[250px] sm:h-[300px] md:h-[350px] lg:h-[380px] w-full">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={filteredData}
            margin={{ top: 5, right: 10, left: 5, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke={theme.mui.cart} />
            <XAxis
              dataKey={yAxisDataKey}
              stroke={theme.mui.light}
              tick={{ fill: theme.mui.light }}
              fontSize="12"
              tickMargin={8}
            />
            <YAxis
              stroke={theme.mui.light}
              tick={{ fill: theme.mui.light }}
              fontSize="12"
              tickMargin={8}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: "#1e293b",
                border: "none",
                borderRadius: "0.5rem",
                color: theme.mui.light,
                padding: "8px",
              }}
            />
            <Legend
              wrapperStyle={{ padding: "8px 4px", color: theme.mui.light }}
              iconSize={12}
              align="center"
              verticalAlign="bottom"
            />
            <Line
              type="monotone"
              dataKey="income"
              stroke="#10b981"
              strokeWidth={1.5}
              dot={{ fill: "#10b981", strokeWidth: 1.5, r: 3 }}
            />
            <Line
              type="monotone"
              dataKey="expense"
              stroke="#f43f5e"
              strokeWidth={1.5}
              dot={{ fill: "#f43f5e", strokeWidth: 1.5, r: 3 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SimpleLineChart;
