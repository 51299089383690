// import React, { useEffect, useState } from "react";
// import {
//   AreaChart,
//   Area,
//   ResponsiveContainer,
//   BarChart,
//   XAxis,
//   YAxis,
//   Bar,
//   Tooltip,
//   Line,
//   LineChart,
// } from "recharts";

// const StatBox = ({
//   title,
//   subtitle,
//   icon,
//   chartData = [],
//   chartType,
//   className = "",
//   onClick,
// }) => {
//   const [timePeriod, setTimePeriod] = useState("Today");
//   const [isHovered, setIsHovered] = useState(false);
//   const [updatedChartData, setUpdatedChartData] = useState(chartData);

//   const handleClick = (event) => {
//     if (event.target.tagName.toLowerCase() === "select") {
//       return;
//     }
//     if (onClick) {
//       onClick();
//     }
//   };

//   useEffect(() => {
//     setUpdatedChartData(getUpdatedChartData(timePeriod));
//   }, [timePeriod, chartData]);
  
//   const getUpdatedChartData = (period) => {
//     if (!Array.isArray(chartData)) {
//       console.error("chartData is not an array:", chartData);
//       return [];
//     }

//     switch (period) {
//       case "lastMonth":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 0.8,
//           totalBookedPlots: d.totalBookedPlots * 0.8,
//         }));
//       case "lastWeek":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 0.5,
//           totalBookedPlots: d.totalBookedPlots * 0.5,
//         }));
//       case "Today":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 0.9,
//           totalBookedPlots: d.totalBookedPlots * 0.9,
//         }));
//       case "lastYear":
//         return chartData.map((d) => ({
//           ...d,
//           totalPlots: d.totalPlots * 1.5,
//           totalBookedPlots: d.totalBookedPlots * 1.5,
//         }));
//       default:
//         return chartData;
//     }
//   };
//   const formattedChartData =
//     getUpdatedChartData(timePeriod).length > 0
//       ? getUpdatedChartData(timePeriod)
//       : [{ name: "Data", totalPlots: 0, totalBookedPlots: 0 }];

//   // Generate unique gradient IDs using subtitle instead of title
//   const gradientId = `colorGradient-${subtitle.replace(
//     /\s+/g,
//     ""
//   )}-${Math.random().toString(36).substr(2, 9)}`;

//   return (
//     <div
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//       onClick={handleClick}
//       style={{ cursor: onClick ? "pointer" : "default" }}
//       className={`group relative h-[180px] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl overflow-hidden transition-all duration-700 hover:scale-[1.02] border border-slate-700/50 hover:border-teal-500/30 ${className}`}
//     >
//       <div className="relative h-full z-10">
//         <div className="flex items-center justify-between p-4">
//           <div className="flex items-center gap-3">
//             <div className="relative">
//               <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-500 rounded-xl opacity-20 group-hover:opacity-30" />
//               <div className="relative p-2 bg-gradient-to-br from-slate-800/90 to-slate-900/90 rounded-xl border border-slate-700/50 group-hover:border-teal-500/30 transform group-hover:-translate-y-1 transition-all duration-500">
//                 {React.cloneElement(icon, {
//                   className:
//                     "w-6 h-6 text-teal-300 group-hover:text-teal-200 transition-colors duration-500",
//                 })}
//               </div>
//             </div>

//             <div className="space-y-0.5">
//               <h3 className="text-lg font-bold">
//                 <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-300 via-teal-200 to-cyan-200 group-hover:from-teal-200 group-hover:via-cyan-200 group-hover:to-teal-200">
//                   {title}
//                 </span>
//               </h3>
//               <p className="text-xs text-teal-300/80 group-hover:text-teal-200/80">
//                 {subtitle}
//               </p>
//             </div>
//           </div>

//           <select
//             value={timePeriod}
//             onChange={(e) => setTimePeriod(e.target.value)}
//             className="bg-slate-800/90 text-teal-300 text-xs rounded-lg px-2 py-1 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer  hover:bg-slate-700/90"
//           >
//             <option value="Today">Today</option>
//             <option value="lastWeek">Last Week</option>
//             <option value="lastMonth">Last Month</option>
//             <option value="lastYear">Last Year</option>
//           </select>
//         </div>

//         <div className="h-24 mt-2">
//           <ResponsiveContainer width="100%" height="100%">
//             {chartType === "line" ? (
//               <LineChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Line
//                   type="monotone"
//                   dataKey="totalPlots"
//                   stroke="#2DD4BF"
//                   dot={false}
//                   strokeWidth={1}
//                   fill={`url(#${gradientId})`}
//                 />
//                 <Line
//                   type="monotone"
//                   dataKey="totalBookedPlots"
//                   stroke="#14B8A6"
//                   dot={false}
//                   strokeWidth={4}
//                   fill={`url(#${gradientId})`}
//                 />
//               </LineChart>
//             ) : 
//             chartType === "area" ? (
//               <AreaChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="5%" stopColor="#2DD4BF" stopOpacity={0.4} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.1} />
//                   </linearGradient>
//                 </defs>
//                 <Area
//                   type="monotone"
//                   dataKey="value"
//                   stroke="#2DD4BF"
//                   strokeWidth={2}
//                   fill={`url(#${gradientId})`}
//                 />
//               </AreaChart>
//             ) : (
//               <BarChart data={formattedChartData}>
//                 <defs>
//                   <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
//                     <stop offset="0%" stopColor="#2DD4BF" stopOpacity={0.8} />
//                     <stop offset="95%" stopColor="#14B8A6" stopOpacity={0.3} />
//                   </linearGradient>
//                 </defs>
//                 <XAxis dataKey="name" hide />
//                 <YAxis hide />
//                 <Tooltip
//                   contentStyle={{
//                     backgroundColor: "rgba(15, 23, 42, 0.95)",
//                     border: "1px solid rgba(45, 212, 191, 0.2)",
//                     borderRadius: "12px",
//                     padding: "8px",
//                     boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
//                   }}
//                   cursor={{ fill: "rgba(45, 212, 191, 0.1)" }}
//                 />
//                 <Bar
//                   dataKey="value"
//                   fill={`url(#${gradientId})`}
//                   radius={[8, 8, 0, 0]}
//                 />
//               </BarChart>
//             )}
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StatBox;


import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Line,
  LineChart,
} from "recharts";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const StatBox = ({
  title,
  subtitle,
  icon,
  chartData = [],
  chartType,
  className = "",
  onClick,
  showLive = false, // New prop to control live indicator visibility
}) => {
  const [timePeriod, setTimePeriod] = useState("Today");
  const [isHovered, setIsHovered] = useState(false);
  const [updatedChartData, setUpdatedChartData] = useState(chartData);
  const [isBlinking, setIsBlinking] = useState(true);
 const {  gradient, theme } = useSidebarContext();

  // Blinking effect for live indicator
  useEffect(() => {
    if (showLive) {
      const interval = setInterval(() => {
        setIsBlinking(prev => !prev);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showLive]);

  const handleClick = (event) => {
    if (event.target.tagName.toLowerCase() === "select") {
      return;
    }
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    setUpdatedChartData(getUpdatedChartData(timePeriod));
  }, [timePeriod, chartData]);
  
  const getUpdatedChartData = (period) => {
    if (!Array.isArray(chartData)) {
      console.error("chartData is not an array:", chartData);
      return [];
    }

    switch (period) {
      case "lastMonth":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 0.8,
          totalBookedPlots: d.totalBookedPlots * 0.8,
        }));
      case "lastWeek":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 0.5,
          totalBookedPlots: d.totalBookedPlots * 0.5,
        }));
      case "Today":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 0.9,
          totalBookedPlots: d.totalBookedPlots * 0.9,
        }));
      case "lastYear":
        return chartData.map((d) => ({
          ...d,
          totalPlots: d.totalPlots * 1.5,
          totalBookedPlots: d.totalBookedPlots * 1.5,
        }));
      default:
        return chartData;
    }
  };

  const formattedChartData =
    getUpdatedChartData(timePeriod).length > 0
      ? getUpdatedChartData(timePeriod)
      : [{ name: "Data", totalPlots: 0, totalBookedPlots: 0 }];

  const gradientId = `colorGradient-${subtitle.replace(
    /\s+/g,
    ""
  )}-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
      style={{ cursor: onClick ? "pointer" : "default" }}
      className={`group relative h-[180px] ${gradient} rounded-2xl overflow-hidden transition-all duration-700 hover:scale-[1.02] border ${theme.tailwind.border2} ${theme.tailwind.hoverB} ${className}`}
    >
      <div className="relative h-full z-10">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center gap-3">
            <div className="relative">
              <div className={`absolute inset-0 ${theme.tailwind.text5} rounded-xl opacity-20 group-hover:opacity-30`} />
              <div className={`relative p-2 ${theme.tailwind.bg4} rounded-xl border ${theme.tailwind.border2} ${theme.tailwind.groupHover1} transform group-hover:-translate-y-1 transition-all duration-500`}>
                {React.cloneElement(icon, {
                  className:
                    `w-6 h-6 ${theme.tailwind.text} ${theme.tailwind.groupHover3} transition-colors duration-500`,
                })}
              </div>
            </div>

            <div className="space-y-0.5">
              <h3 className="text-lg font-bold">
                <span className={`bg-clip-text text-transparent ${theme.tailwind.bgr} ${theme.tailwind.groupHoverG} ${theme.tailwind.groupHoverG2} ${theme.tailwind.groupHoverG3}`}>
                  {title}
                </span>
              </h3>
              <p className={`text-xs ${theme.tailwind.text7} ${theme.tailwind.groupHover5}`}>
                {subtitle}
              </p>
            </div>
          </div>

          {showLive ? (
             <div className={`flex items-center gap-2 px-3 py-1 ${theme.tailwind.bg2} rounded-lg border ${theme.tailwind.border2} ${theme.tailwind.hoverB} transition-all duration-300 shadow-lg ${theme.tailwind.shadow}`}>
             <div className="relative">
               <div className={`w-2 h-2 rounded-full ${isBlinking ? 'bg-green-500' : 'bg-green-700'} transition-colors duration-500`} />
               <div className={`absolute inset-0 rounded-full bg-green-500/50 animate-ping ${isBlinking ? 'opacity-100' : 'opacity-0'}`} />
             </div>
             <span className={`text-xs font-medium bg-clip-text text-transparent ${theme.tailwind.text6}`}>Live</span>
           </div>
          ) : (
            <select
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
              className={`bg-slate-800/90 text-teal-300 text-xs rounded-lg px-2 py-1 border border-slate-700 hover:border-teal-500/50 focus:outline-none focus:ring-2 focus:ring-teal-500/20 transition-all duration-300 appearance-none cursor-pointer hover:bg-slate-700/90`}
            >
              <option value="Today">Today</option>
              <option value="lastWeek">Last Week</option>
              <option value="lastMonth">Last Month</option>
              <option value="lastYear">Last Year</option>
            </select>
          )}
        </div>

        <div className="h-24 mt-2">
          <ResponsiveContainer width="100%" height="100%">
            {chartType === "line" ? (
              <LineChart data={formattedChartData}>
                <defs>
                  <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={theme.mui.main} stopOpacity={0.4} />
                    <stop offset="95%" stopColor={theme.mui.hover} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Line
                  type="monotone"
                  dataKey="totalPlots"
                  stroke={theme.mui.main}
                  dot={false}
                  strokeWidth={1}
                  fill={`url(#${gradientId})`}
                />
                <Line
                  type="monotone"
                  dataKey="totalBookedPlots"
                  stroke={theme.mui.hover}
                  dot={false}
                  strokeWidth={4}
                  fill={`url(#${gradientId})`}
                />
              </LineChart>
            ) : 
            chartType === "area" ? (
              <AreaChart data={formattedChartData}>
                <defs>
                  <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={theme.mui.main} stopOpacity={0.4} />
                    <stop offset="95%" stopColor={theme.mui.hover} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={theme.mui.main}
                  strokeWidth={2}
                  fill={`url(#${gradientId})`}
                />
              </AreaChart>
            ) : (
              <BarChart data={formattedChartData}>
                <defs>
                  <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={theme.mui.main} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={theme.mui.hover} stopOpacity={0.3} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" hide />
                <YAxis hide />
                <Tooltip
                  contentStyle={{
                    backgroundColor: "rgba(15, 23, 42, 0.95)",
                    border: `1px solid ${theme.mui.border1}`,
                    borderRadius: "12px",
                    padding: "8px",
                    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
                  }}
                  cursor={{ fill: theme.mui.fill }}
                />
                <Bar
                  dataKey="value"
                  fill={`url(#${gradientId})`}
                  radius={[8, 8, 0, 0]}
                />
              </BarChart>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default StatBox;