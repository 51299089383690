import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchEntriesAPI,
  createEntryAPI,
  updateEntryAPI,
  deleteEntryAPI,
  fetchAllAccountsAPI
} from '../../api'

// Create async thunks for better promise handling
export const fetchAllAccounts = createAsyncThunk(
  'khata/fetchAllAccounts',
  async (_, { rejectWithValue }) => {
    try {
      return await fetchAllAccountsAPI()
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const fetchEntries = createAsyncThunk(
  'khata/fetchEntries',
  async (khataBy, { rejectWithValue }) => {
    try {
      return await fetchEntriesAPI(khataBy)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const createEntry = createAsyncThunk(
  'khata/createEntry',
  async (entry, { rejectWithValue }) => {
    try {
      return await createEntryAPI(entry)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateExistingEntry = createAsyncThunk(
  'khata/updateExistingEntry',
  async ({ id, updatedEntry }, { rejectWithValue }) => {
    try {
      return await updateEntryAPI(id, updatedEntry)
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const removeEntry = createAsyncThunk(
  'khata/removeEntry',
  async (id, { rejectWithValue }) => {
    try {
      await deleteEntryAPI(id)
      return id
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

const khataSlice = createSlice({
  name: 'khata',
  initialState: { entries: [], accounts: [], loading: false, error: null },
  reducers: {},
  extraReducers: builder => {
    builder
      // fetchAllAccounts
      .addCase(fetchAllAccounts.pending, state => {
        state.loading = true
      })
      .addCase(fetchAllAccounts.fulfilled, (state, action) => {
        state.loading = false
        state.accounts = action.payload
        state.error = null
      })
      .addCase(fetchAllAccounts.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      // fetchEntries
      .addCase(fetchEntries.pending, state => {
        state.loading = true
      })
      .addCase(fetchEntries.fulfilled, (state, action) => {
        state.loading = false
        state.entries = action.payload
        state.error = null
      })
      .addCase(fetchEntries.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      // createEntry
      .addCase(createEntry.pending, state => {
        state.loading = true
      })
      .addCase(createEntry.fulfilled, (state, action) => {
        state.loading = false
        state.entries.push(action.payload)
        state.error = null
      })
      .addCase(createEntry.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      // updateExistingEntry
      .addCase(updateExistingEntry.pending, state => {
        state.loading = true
      })
      .addCase(updateExistingEntry.fulfilled, (state, action) => {
        state.loading = false
        const index = state.entries.findIndex(
          entry => entry._id === action.payload._id
        )
        if (index !== -1) {
          state.entries[index] = action.payload
        }
        state.error = null
      })
      .addCase(updateExistingEntry.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      // removeEntry
      .addCase(removeEntry.pending, state => {
        state.loading = true
      })
      .addCase(removeEntry.fulfilled, (state, action) => {
        state.loading = false
        state.entries = state.entries.filter(
          entry => entry._id !== action.payload
        )
        state.error = null
      })
      .addCase(removeEntry.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export default khataSlice.reducer
