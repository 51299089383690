import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkActiveUser, fetchAllUsers } from "../store/slices/authSlice";

const CustomIcon = ({ children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    className="w-6 h-6 text-teal-300 transform transition-transform group-hover:scale-110 duration-300"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {children}
  </svg>
);

const Icons = {
  Profile: () => (
    <CustomIcon>
      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
      <circle cx="12" cy="7" r="4" />
    </CustomIcon>
  ),
  Contact: () => (
    <CustomIcon>
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
    </CustomIcon>
  ),
  Family: () => (
    <CustomIcon>
      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </CustomIcon>
  ),
  Plot: () => (
    <CustomIcon>
      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </CustomIcon>
  ),
  ID: () => (
    <CustomIcon>
      <rect x="3" y="4" width="18" height="16" rx="2" />
      <circle cx="9" cy="10" r="2" />
      <path d="M15 8h2" />
      <path d="M15 12h2" />
      <path d="M7 16h10" />
    </CustomIcon>
  ),
};

const SingleCustomer = () => {
  const dispatch = useDispatch();
  const { activeUser, users, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkActiveUser()).then(() => {
      dispatch(fetchAllUsers());
    });
  }, [dispatch]);

  const matchedUser = users.find(
    (user) => user._id === activeUser?.id && user.role === "Customer"
  );

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 flex items-center justify-center">
        <div className="animate-pulse text-teal-300 text-xl font-bold tracking-wider">
          Loading...
        </div>
      </div>
    );
  }

  if (!matchedUser) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 flex items-center justify-center">
        <div className="text-teal-300 text-xl font-bold tracking-wider">
          No active customer found.
        </div>
      </div>
    );
  }

  const { contactInfo, name, plots } = matchedUser.customerData;

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="backdrop-blur-xl bg-slate-800/50 rounded-3xl p-8 shadow-2xl border border-slate-700 relative overflow-hidden">
          {/* Animated background gradients */}
          <div className="absolute inset-0 bg-gradient-to-br from-teal-500/20 via-transparent to-transparent animate-pulse" />
          <div className="absolute inset-0 bg-gradient-to-tl from-slate-900/50 via-transparent to-transparent" />

          <div className="relative">
            <h2 className="text-5xl font-bold mb-12 text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-teal-500 text-center">
              Customer Profile
            </h2>

            {/* General Info */}
            <Section icon={<Icons.Profile />} title="General Information">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Username" value={matchedUser.username} />
                <InfoItem label="Name" value={name} />
              </div>
            </Section>

            {/* Contact Details */}
            <Section icon={<Icons.Contact />} title="Contact Details">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Phone" value={contactInfo.phone} />
                <InfoItem label="Email" value={contactInfo.email} />
                <InfoItem label="CNIC" value={contactInfo.cnic} />
                <InfoItem label="Address" value={contactInfo.address} />
              </div>
            </Section>

            {/* Waris Details */}
            <Section icon={<Icons.Family />} title="Waris Details">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InfoItem label="Name" value={contactInfo.waris_name} />
                <InfoItem label="Relation" value={contactInfo.waris_relation} />
                <InfoItem label="Phone" value={contactInfo.waris_phone} />
                <InfoItem label="CNIC" value={contactInfo.waris_cnic} />
                <InfoItem label="Address" value={contactInfo.waris_address} />
              </div>
            </Section>

            {/* Plot Details */}
            <Section icon={<Icons.Plot />} title="Plot Details">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {plots.map((plot, index) => (
                  <PlotCard key={index} plot={plot} />
                ))}
              </div>
            </Section>

            {/* ID Card Images */}
            <Section icon={<Icons.ID />} title="ID Card Images">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <ImageCard
                  title="ID Front"
                  src={contactInfo.id_front_img}
                  alt="ID Front"
                />
                <ImageCard
                  title="ID Back"
                  src={contactInfo.id_back_img}
                  alt="ID Back"
                />
                <ImageCard
                  title="Waris ID Front"
                  src={contactInfo.waris_id_front_img}
                  alt="Waris ID Front"
                />
                <ImageCard
                  title="Waris ID Back"
                  src={contactInfo.waris_id_back_img}
                  alt="Waris ID Back"
                />
              </div>
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};

const Section = ({ icon, title, children }) => (
  <div className="mb-10 group">
    <div className="backdrop-blur-md bg-slate-900/50 rounded-2xl p-8 border border-slate-700/50 hover:border-teal-500/50 transition-all duration-500 hover:shadow-lg hover:shadow-teal-500/10">
      <h3 className="text-2xl font-semibold mb-6 flex items-center gap-3 group-hover:text-teal-300 transition-colors duration-300">
        {icon}
        <span className="bg-gradient-to-r from-teal-300 to-teal-500 bg-clip-text text-transparent">
          {title}
        </span>
      </h3>
      {children}
    </div>
  </div>
);

const InfoItem = ({ label, value }) => (
  <div className="group bg-slate-800/30 rounded-xl p-4 hover:bg-slate-800/50 transition-all duration-300">
    <p className="text-slate-400 text-sm mb-1 group-hover:text-teal-400 transition-colors duration-300">
      {label}
    </p>
    <p className="text-teal-300 font-medium text-lg group-hover:text-teal-200 transition-colors duration-300">
      {value}
    </p>
  </div>
);

const PlotCard = ({ plot }) => (
  <div className=" bg-slate-900/50 rounded-2xl p-6 border border-slate-700/50 hover:border-teal-500/50 hover:transform hover:scale-[1.02] transition-all duration-300 hover:shadow-lg hover:shadow-teal-500/10">
    <div className="space-y-4">
      <InfoItem label="Size" value={plot.size} />
      <InfoItem label="Category" value={plot.category} />
      <InfoItem label="Status" value={plot.status} />
    </div>
  </div>
);

const ImageCard = ({ title, src, alt }) => (
  <div className="group backdrop-blur-md bg-slate-900/50 rounded-2xl p-6 border border-slate-700/50 hover:border-teal-500/50 transition-all duration-300 hover:shadow-lg hover:shadow-teal-500/10">
    <h4 className="text-lg font-semibold text-teal-300 mb-4 group-hover:text-teal-200 transition-colors duration-300">
      {title}
    </h4>
    {src ? (
      <div className="overflow-hidden rounded-xl">
        <img
          src={src}
          alt={alt}
          className="w-full h-64 object-cover rounded-xl transform transition-transform duration-500 group-hover:scale-110"
        />
      </div>
    ) : (
      <div className="w-full h-64 rounded-xl bg-slate-800/50 flex items-center justify-center text-slate-500 group-hover:bg-slate-800/70 transition-colors duration-300">
        No image available
      </div>
    )}
  </div>
);

export default SingleCustomer;
