import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllSocieties,
  createSociety,
  deleteSociety,
  updateSociety,
  getSocietyById,
  fetchPlotsAPI,
  updatePlotAPI
} from '../../api'

// Utility function for error handling
const extractErrorMessage = error => {
  return error.response?.data || 'An error occurred. Please try again.'
}

// Async action to fetch all societies

// export const fetchSocieties = createAsyncThunk(
//   "societies/fetchSocieties",
//   async (_, { rejectWithValue }) => {
//     try {
//       const societies = await getAllSocieties();
//       // console.log("Fetched societies with plots:", societies); // Inspect data
//       return Array.isArray(societies) ? societies : [];
//     } catch (error) {
//       return rejectWithValue(error.response?.data || "An error occurred");
//     }
//   }
// );
// Async action to fetch all societies
export const fetchSocieties = createAsyncThunk(
  'societies/fetchSocieties',
  async (_, { rejectWithValue, getState }) => {
    const state = getState()
    if (state.societies.societies.length > 0) {
      // If societies already exist in the state, return them immediately
      return state.societies.societies
    }

    try {
      const societies = await getAllSocieties()
      return Array.isArray(societies) ? societies : []
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

// Async action to fetch society by ID
export const fetchSocietyById = createAsyncThunk(
  'societies/fetchSocietyById',
  async (societyId, { rejectWithValue }) => {
    try {
      const society = await getSocietyById(societyId)
      return society // Returns the fetched society
    } catch (error) {
      return rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Async action to create a new society
export const addSociety = createAsyncThunk(
  'societies/addSociety',
  async (societyData, { rejectWithValue }) => {
    try {
      const response = await createSociety(societyData)
      return response.society || response // Handle both response formats
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

// Async action to delete a society
export const removeSociety = createAsyncThunk(
  'societies/removeSociety',
  async (societyId, { rejectWithValue }) => {
    try {
      await deleteSociety(societyId)
      return societyId // Return the ID of the deleted society
    } catch (error) {
      return rejectWithValue(extractErrorMessage(error))
    }
  }
)

export const updateSocietyAction = createAsyncThunk(
  'societies/updateSociety',
  async (payload, { rejectWithValue }) => {
    try {
      const { _id, data } = payload // Make sure these property names match what you're passing
      const response = await updateSociety(_id, data)
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)
export const updateSocietyActionPlot = createAsyncThunk(
  'societies/updateSociety',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, data } = payload // Make sure these property names match what you're passing
      const response = await updateSociety(id, data)
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const fetchPlots = createAsyncThunk(
  'societies/fetchPlots',
  async (societyId, { rejectWithValue }) => {
    try {
      const plots = await fetchPlotsAPI(societyId) // Use the previously defined fetchPlotsAPI
      return plots // Return fetched plots
    } catch (error) {
      return rejectWithValue(extractErrorMessage(error))
    }
  }
)

// Async action to update a plot
export const updatePlot = createAsyncThunk(
  'societies/updatePlot',
  async (plotData, { rejectWithValue }) => {
    try {
      const updatedPlot = await updatePlotAPI(plotData) // Use the previously defined updatePlotAPI
      return updatedPlot // Return updated plot
    } catch (error) {
      return rejectWithValue(extractErrorMessage(error))
    }
  }
)

const societySlice = createSlice({
  name: 'societies',
  initialState: {
    societies: [],
    selectedSociety: null,
    plots: [], // Ensure plots are part of the initial state
    loading: false,
    loadingSelectedSociety: false,
    actionLoading: false,
    error: null,
    actionError: null,
    fetchStatus: 'idle'
  },
  reducers: {
    setSocietyByName: (state, action) => {
      const societyName = action.payload
      const society = state.societies.find(soc => soc.name === societyName)
      state.selectedSociety = society || null // Set selectedSociety
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSocieties.pending, state => {
        state.loading = true
        state.error = null
        state.fetchStatus = 'loading'
      })
      .addCase(fetchSocieties.fulfilled, (state, action) => {
        state.loading = false
        state.societies = action.payload
        state.error = null
        state.fetchStatus = 'idle'
      })
      .addCase(fetchSocieties.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.societies = []
        state.fetchStatus = 'idle'
      })

      .addCase(fetchSocietyById.pending, state => {
        state.loadingSelectedSociety = true
        state.error = null
      })
      .addCase(fetchSocietyById.fulfilled, (state, action) => {
        state.loadingSelectedSociety = false
        state.selectedSociety = action.payload // Store the fetched society
      })
      .addCase(fetchSocietyById.rejected, (state, action) => {
        state.loadingSelectedSociety = false
        state.error = action.payload
      })

      // Add a new society
      .addCase(addSociety.pending, state => {
        state.actionLoading = true
        state.actionError = null
      })
      .addCase(addSociety.fulfilled, (state, action) => {
        console.log('Added Society:', action.payload)
        state.actionLoading = false
        state.societies.push(action.payload)
        state.error = null
      })
      .addCase(addSociety.rejected, (state, action) => {
        state.actionLoading = false
        state.actionError = action.payload
      })

      // Remove a society
      .addCase(removeSociety.pending, state => {
        state.actionLoading = true
        state.actionError = null
      })
      .addCase(removeSociety.fulfilled, (state, action) => {
        state.actionLoading = false
        state.societies = state.societies.filter(
          society => society._id !== action.payload
        )
      })
      .addCase(removeSociety.rejected, (state, action) => {
        state.actionLoading = false
        state.actionError = action.payload
      })

      // Update a society
      .addCase(updateSocietyAction.pending, state => {
        state.actionLoading = true
        state.actionError = null
      })
      .addCase(updateSocietyAction.fulfilled, (state, action) => {
        state.actionLoading = false
        const index = state.societies.findIndex(
          society => society._id === action.payload._id
        )
        if (index !== -1) {
          state.societies[index] = action.payload // Update the society in the list
        }
      })
      .addCase(updateSocietyAction.rejected, (state, action) => {
        state.actionLoading = false
        state.actionError = action.payload
      })

      // Fetch plots
      .addCase(fetchPlots.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchPlots.fulfilled, (state, action) => {
        state.loading = false
        state.plots = action.payload // Store fetched plots
      })
      .addCase(fetchPlots.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })

      // Update a plot
      .addCase(updatePlot.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(updatePlot.fulfilled, (state, action) => {
        state.loading = false
        const updatedPlotIndex = state.plots.findIndex(
          plot => plot.id === action.payload.id
        )
        if (updatedPlotIndex !== -1) {
          state.plots[updatedPlotIndex] = action.payload // Update the plot in the list
        }
      })
      .addCase(updatePlot.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export const { setSocietyByName } = societySlice.actions

export default societySlice.reducer
