import axios from "axios";

const API_BASE_URL = "http://localhost:5000/api";

// Create axios instance with base configuration
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add request interceptor for authentication
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Message API functions
const messageApi = {
  // Send a message with attachments
  sendMessageWithAttachments: async (data) => {
    const formData = new FormData();

    // Add message content and receiver
    formData.append("content", data.content);
    formData.append("receiver", data.receiver);

    // Add any attachments
    if (data.attachments) {
      data.attachments.forEach((file) => {
        formData.append("attachments", file);
      });
    }

    const response = await api.post("/messages/attachments", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  // Send a captured image
  sendCapturedImage: async (data) => {
    const formData = new FormData();
    formData.append("content", data.content);
    formData.append("receiver", data.receiver);
    formData.append("capturedImage", data.image);

    const response = await api.post("/messages/capture", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  // Get conversation history with pagination
  getConversation: async (userId, page = 1, limit = 50) => {
    const response = await api.get(`/messages/conversation/${userId}`, {
      params: { page, limit },
    });
    return response.data;
  },

  // Get all messages
  getAllMessages: async () => {
    const response = await api.get("/messages/all_messages");
    return response.data;
  },

  // Poll for new messages
  pollNewMessages: async (userId, lastChecked) => {
    const response = await api.get(`/messages/poll/${userId}`, {
      params: { lastChecked },
    });
    return response.data;
  },

  // Start polling for new messages
  startPolling: (userId, callback, interval = 500) => {
    let lastChecked = new Date().toISOString();

    const pollInterval = setInterval(async () => {
      try {
        const { messages, timestamp } = await messageApi.pollNewMessages(
          userId,
          lastChecked
        );

        if (messages.length > 0) {
          callback(messages);
          lastChecked = timestamp;
        }
      } catch (error) {
        console.error("Polling error:", error);
        // Optionally stop polling on error
        // clearInterval(pollInterval);
      }
    }, interval);

    // Return function to stop polling
    return () => clearInterval(pollInterval);
  },
};

export default messageApi;
