import * as XLSX from 'xlsx'

export const formatExportData = (
  bookings,
  activeSocietyId,
  activeSocietyName
) => {
  return bookings
    .filter(
      booking =>
        booking.payment_mode === 'Full' &&
        booking.plot_id?.society_id === activeSocietyId
    )
    .map(booking => ({
      'Booking Number': booking.booking_number,
      'Customer Name': booking.customer_id?._id?.username || 'Unknown',
      'Plot Number': booking.plot_id?.plot_number || 'N/A',
      'Total Paid Amount': booking.total_amount,
      'Receipt Number': booking.receipt_no,
      'Booking Date': new Date(booking.booking_date).toLocaleDateString(
        'en-PK'
      ),
      'Payment Mode': booking.payment_mode,
      'Society Name':
        activeSocietyName ||
        booking.plot_id?.Society_id?.name ||
        booking.plot_id?.society_name ||
        'N/A',
      // Additional fields for comprehensive export
      'Customer Phone': booking.customer_id?.contactInfo?.phone || 'N/A',
      'Plot Size': booking.plot_id?.size || 'N/A',
      'Payment Status': booking.booking_status || 'N/A',
      'Remaining Balance': booking.remaining_balance || '0'
    }))
}

export const exportToExcel = (
  data,
  activeSocietyName,
  filename = 'full_payment_bookings_export'
) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  // Truncate society name to max 31 characters
  const truncatedSocietyName = activeSocietyName
    ? activeSocietyName.substring(0, 28) + '...'
    : 'Full Payments'

  XLSX.utils.book_append_sheet(workbook, worksheet, truncatedSocietyName)

  XLSX.writeFile(
    workbook,
    `${activeSocietyName}_full_payment_bookings_${
      new Date().toISOString().split('T')[0]
    }.xlsx`
  )
}

export const filterBookingsByDateRange = (
  bookings,
  startDate,
  endDate,
  activeSocietyId
) => {
  return bookings.filter(booking => {
    const bookingDate = new Date(booking.booking_date)
    return (
      bookingDate >= startDate &&
      bookingDate <= endDate &&
      booking.payment_mode === 'Full' &&
      booking.plot_id?.society_id === activeSocietyId
    )
  })
}

export const getDateRanges = () => {
  const now = new Date()
  return {
    today: {
      start: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      end: new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      )
    },
    thisMonth: {
      start: new Date(now.getFullYear(), now.getMonth(), 1),
      end: new Date(now.getFullYear(), now.getMonth() + 1, 0)
    },
    lastMonth: {
      start: new Date(now.getFullYear(), now.getMonth() - 1, 1),
      end: new Date(now.getFullYear(), now.getMonth(), 0)
    },
    thisYear: {
      start: new Date(now.getFullYear(), 0, 1),
      end: new Date(now.getFullYear(), 11, 31)
    },
    lastYear: {
      start: new Date(now.getFullYear() - 1, 0, 1),
      end: new Date(now.getFullYear() - 1, 11, 31)
    }
  }
}
