import React, { useState, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Calendar, ChevronDown, X, Check } from 'lucide-react'

const EnhancedDateFilter = ({
  onDateRangeChange,
  initialStartDate = null,
  initialEndDate = null
}) => {
  const [dateRange, setDateRange] = useState([initialStartDate, initialEndDate])
  const [startDate, endDate] = dateRange
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
  const [manualStartDate, setManualStartDate] = useState('')
  const [manualEndDate, setManualEndDate] = useState('')
  const datePickerRef = useRef(null)

  const presetRanges = [
    {
      label: 'Today',
      getValue: () => {
        const today = new Date()
        return [today, today]
      }
    },
    {
      label: 'This Week',
      getValue: () => {
        const today = new Date()
        const firstDay = new Date(
          today.setDate(today.getDate() - today.getDay())
        )
        const lastDay = new Date(today.setDate(today.getDate() + 6))
        return [firstDay, lastDay]
      }
    },
    {
      label: 'This Month',
      getValue: () => {
        const today = new Date()
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        return [firstDay, lastDay]
      }
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        const today = new Date()
        const thirtyDaysAgo = new Date(
          today.getTime() - 30 * 24 * 60 * 60 * 1000
        )
        return [thirtyDaysAgo, today]
      }
    },
    {
      label: 'This Year',
      getValue: () => {
        const today = new Date()
        const firstDay = new Date(today.getFullYear(), 0, 1)
        const lastDay = new Date(today.getFullYear(), 11, 31)
        return [firstDay, lastDay]
      }
    }
  ]

  const parseManualDate = dateString => {
    const parts = dateString.split('/')
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10)
      const month = parseInt(parts[1], 10)
      const year = parseInt(parts[2], 10)

      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        const parsedDate = new Date(year, month - 1, day)
        // Validate the date is valid and matches the input
        return parsedDate instanceof Date &&
          !isNaN(parsedDate) &&
          parsedDate.getDate() === day &&
          parsedDate.getMonth() === month - 1 &&
          parsedDate.getFullYear() === year
          ? parsedDate
          : null
      }
    }
    return null
  }

  const handleManualDateSubmit = () => {
    const startDateParsed = parseManualDate(manualStartDate)
    const endDateParsed = parseManualDate(manualEndDate)

    if (startDateParsed && endDateParsed) {
      const newDateRange = [startDateParsed, endDateParsed]
      setDateRange(newDateRange)
      onDateRangeChange(newDateRange)
      setIsDatePickerOpen(false)
    } else {
      alert('Please enter dates in DD/MM/YYYY format')
    }
  }

  // Handle date range selection
  const handleDateRangeChange = update => {
    setDateRange(update)
    setManualStartDate(update[0] ? update[0].toLocaleDateString() : '')
    setManualEndDate(update[1] ? update[1].toLocaleDateString() : '')
    if (update[0] && update[1]) {
      onDateRangeChange(update)
    }
  }

  // Apply preset range
  const applyPresetRange = range => {
    const newRange = range.getValue()
    setDateRange(newRange)
    setManualStartDate(newRange[0].toLocaleDateString())
    setManualEndDate(newRange[1].toLocaleDateString())
    onDateRangeChange(newRange)
    setIsDatePickerOpen(false)
  }

  // Clear date range
  const clearDateRange = () => {
    setDateRange([null, null])
    setManualStartDate('')
    setManualEndDate('')
    onDateRangeChange([null, null])
    setIsDatePickerOpen(false)
  }

  // Format date display
  const formatDateDisplay = () => {
    if (!startDate && !endDate) return 'Select Date Range'

    if (startDate && endDate) {
      return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
    }

    return startDate
      ? `From ${startDate.toLocaleDateString()}`
      : `Until ${endDate.toLocaleDateString()}`
  }

  return (
    <div className='relative'>
      {/* Date Range Selector Button */}
      <button
        onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        className='w-full flex items-center justify-between px-4 py-3 rounded-xl 
                   text-white bg-slate-700 border border-slate-600 
                   hover:border-teal-400 focus:outline-none 
                   transition-all duration-300'
      >
        <div className='flex items-center gap-2'>
          <Calendar className='w-5 h-5 text-teal-400' />
          <span className='text-white'>{formatDateDisplay()}</span>
        </div>
        <ChevronDown
          className={`w-4 h-4 text-teal-400 transition-transform duration-300 
                      ${isDatePickerOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {/* Dropdown Container */}
      {isDatePickerOpen && (
        <div
          ref={datePickerRef}
          className='absolute z-50 mt-2 w-full bg-slate-800 rounded-xl 
                     shadow-xl border border-slate-700 p-4 
                     animate-fadeIn'
        >
          {/* Preset Ranges */}
          <div className='grid grid-cols-3 gap-2 mb-4'>
            {presetRanges.map(range => (
              <button
                key={range.label}
                onClick={() => applyPresetRange(range)}
                className='px-3 py-2 text-xs bg-slate-700 
                           hover:bg-teal-700 text-white 
                           rounded-lg transition-colors'
              >
                {range.label}
              </button>
            ))}
          </div>
          {/* Manual Date Input */}
          <div className='flex gap-2 mb-4'>
            <input
              type='text'
              placeholder='Start Date (DD/MM/YYYY)'
              value={manualStartDate}
              onChange={e => setManualStartDate(e.target.value)}
              className='flex-1 px-3 py-2 bg-slate-700 text-black 
                         rounded-lg border border-slate-600 
                         focus:outline-none focus:border-teal-400'
            />
            <input
              type='text'
              placeholder='End Date (DD/MM/YYYY)'
              value={manualEndDate}
              onChange={e => setManualEndDate(e.target.value)}
              className='flex-1 px-3 py-2 bg-slate-700 text-black 
                         rounded-lg border border-slate-600 
                         focus:outline-none focus:border-teal-400'
            />
          </div>
          {/* Date Picker */}
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateRangeChange}
            inline
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            isClearable
            placeholderText='Select date range'
            dateFormat='MM/dd/yyyy'
            className='!w-full !bg-slate-800 !text-black'
            calendarClassName='custom-calendar-dropdown'
            dayClassName={date => {
              const isSelected =
                (startDate &&
                  date.toDateString() === startDate.toDateString()) ||
                (endDate && date.toDateString() === endDate.toDateString())

              const isInRange =
                startDate && endDate && date >= startDate && date <= endDate

              if (isSelected) return '!bg-teal-400 !text-black rounded-full'
              if (isInRange) return '!bg-teal-900/50 !text-black'
              return '!text-black hover:!bg-teal-500 hover:!text-black rounded-full'
            }}
          />
          {/* Action Buttons */}
          <div className='flex justify-between mt-4'>
            <div className='flex gap-2'>
              <button
                onClick={clearDateRange}
                className='flex items-center gap-2 px-4 py-2 
                           bg-red-500/10 text-red-400 
                           hover:bg-red-500/20 rounded-lg 
                           transition-colors'
              >
                <X className='w-4 h-4' />
                Clear
              </button>
              <button
                onClick={handleManualDateSubmit}
                className='flex items-center gap-2 px-4 py-2 
                           bg-teal-500/10 text-teal-400 
                           hover:bg-teal-500/20 rounded-lg 
                           transition-colors'
              >
                <Check className='w-4 h-4' />
                Apply Manual Dates
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Custom CSS for dropdown visibility */}
      <style jsx global>{`
        .custom-calendar-dropdown .react-datepicker__month-dropdown,
        .custom-calendar-dropdown .react-datepicker__year-dropdown {
          background-color: #1e293b !important;
          color: black !important;
        }

        .custom-calendar-dropdown .react-datepicker__month-dropdown-option,
        .custom-calendar-dropdown .react-datepicker__year-dropdown-option {
          background-color: #1e293b !important;
          color: black !important;
        }

        .custom-calendar-dropdown
          .react-datepicker__month-dropdown-option:hover,
        .custom-calendar-dropdown
          .react-datepicker__year-dropdown-option:hover {
          background-color: #2563eb !important;
          color: black !important;
        }

        .custom-calendar-dropdown .react-datepicker__day {
          color: black !important;
        }
      `}</style>
    </div>
  )
}

export default EnhancedDateFilter
