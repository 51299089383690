import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api'

export const createBooking = createAsyncThunk(
  'booking/createBooking',
  async (bookingData, { rejectWithValue }) => {
    // console.log("Creating booking with data:", bookingData);
    try {
      const response = await api.post('/booking/create', bookingData)
      return response.data
    } catch (error) {
      console.error(
        'Error during booking creation:',
        error.response ? error.response.data : error.message
      )
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

// Get a booking by ID
export const getBookingById = createAsyncThunk(
  'booking/getBookingById',
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/booking/bookings/${bookingId}`) // Fixed path
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

// Update booking status
export const updateBookingStatus = createAsyncThunk(
  'booking/updateBookingStatus',
  async ({ bookingId, bookingStatus }, { rejectWithValue }) => {
    try {
      const response = await api.put(`/bookings/${bookingId}/status`, {
        status: bookingStatus // Changed property name to 'status'
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

// Transfer a plot
export const transferPlot = createAsyncThunk(
  'booking/transferPlot',
  async (transferData, { rejectWithValue }) => {
    try {
      const response = await api.post('/plots/transfer', transferData) // Fixed path
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

export const updateBooking = createAsyncThunk(
  'booking/updateBooking',
  async ({ bookingId, bookingData }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/booking/bookings/${bookingId}`,
        bookingData
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

// Soft delete a booking
export const deleteBooking = createAsyncThunk(
  'booking/deleteBooking',
  async (bookingId, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/booking/bookings/${bookingId}`)
      console.log(response) // Fixed path
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

export const getAllBookings = createAsyncThunk(
  'booking/getAllBookings',
  async (_, { getState, rejectWithValue }) => {
    const { booking } = getState() // Access the current state
    if (booking.status === 'succeeded') {
      return booking.bookings // Return already fetched bookings
    }

    try {
      const response = await api.get('/booking/bookings')
      console.log('Fetched bookings response:', response.data)
      return response.data // Return the response data directly
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    bookings: [],
    booking: null,
    loading: false,
    error: null,
    status: 'idle'
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createBooking.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.loading = false
        // state.bookings = [action.payload.booking];
        // state.bookings.push(action.payload);
        if (action.payload?.booking) {
          state.bookings.push(action.payload.booking)
        } else {
          console.error('Invalid booking data:', action.payload)
        }
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getBookingById.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(getBookingById.fulfilled, (state, action) => {
        state.loading = false
        state.booking = action.payload // Assuming the payload contains the booking data
      })
      .addCase(getBookingById.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(updateBookingStatus.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(updateBookingStatus.fulfilled, (state, action) => {
        state.loading = false
        state.booking = action.payload // Assuming the payload contains the booking data
      })
      .addCase(updateBookingStatus.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(transferPlot.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(transferPlot.fulfilled, (state, action) => {
        state.loading = false
        state.booking = action.payload // Adjust based on actual response
      })
      .addCase(transferPlot.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // .addCase(deleteBooking.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      // })
      // .addCase(deleteBooking.fulfilled, (state) => {
      //   state.loading = false;
      //   state.booking = null; // Assuming booking is reset after deletion
      // })
      // .addCase(deleteBooking.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = action.payload;
      // })
      // Delete Booking
      .addCase(deleteBooking.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        state.loading = false
        state.booking = null
        // Updated to handle both array and single booking response
        if (action.payload.booking) {
          state.bookings = state.bookings.filter(
            b => b._id !== action.payload.booking._id
          )
        } else if (action.payload.message) {
          // If only success message is returned, remove using selectedBooking ID
          state.bookings = state.bookings.filter(b => b._id !== action.meta.arg)
        }
      })
      .addCase(deleteBooking.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // Update Booking
      .addCase(updateBooking.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.loading = false
        state.booking = action.payload.booking
        const index = state.bookings.findIndex(
          b => b._id === action.payload.booking._id
        )
        if (index !== -1) {
          state.bookings[index] = action.payload.booking
        }
      })
      .addCase(updateBooking.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(getAllBookings.pending, state => {
        if (state.status === 'idle') {
          state.loading = true
          state.status = 'loading'
          state.error = null
        }
      })
      .addCase(getAllBookings.fulfilled, (state, action) => {
        if (state.status === 'loading') {
          state.loading = false
          state.status = 'succeeded'
          // Make sure we're handling the correct structure
          state.bookings = action.payload.bookings || []
          console.log('Bookings stored in state:', state.bookings)
        }
      })
      .addCase(getAllBookings.rejected, (state, action) => {
        if (state.status === 'loading') {
          state.loading = false
          state.status = 'failed'
          state.error = action.payload
        }
      })
  }
})

export default bookingSlice.reducer
