import React, { useState } from "react";
import printJS from "print-js";
import {
  FileText,
  Building,
  CreditCard,
  Calendar,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

const CompletedInstallmentsPrint = ({
  installments,
  totalPaidAmount,
  companyInfo,
}) => {
  const formatCNIC = (cnic) => {
    if (!cnic) return "";
    return cnic.replace(/^(\d{5})(\d{7})(\d{1})$/, "$1-$2-$3");
  };

  const societyImage = companyInfo?.soc?.society_image || "default-image.jpg";

  const handlePrint = () => {
    const printContent = `
      <div style="font-family: 'Inter', 'Arial', sans-serif; margin: 0; padding: 15px; max-width: 800px; margin: 0 auto; background: #f4f4f4;">
        <!-- Compact Header -->
        <div style="background: linear-gradient(135deg, #3b82f6, #1e40af); color: white; padding: 15px; border-radius: 12px; margin-bottom: 15px; box-shadow: 0 6px 10px rgba(0,0,0,0.1);">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; align-items: center;">
              <img src="${societyImage}" alt="Society" style="width: 60px; height: 60px; border-radius: 50%; margin-right: 15px; border: 3px solid rgba(255,255,255,0.3); object-fit: cover;" />
              <div>
                <h1 style="margin: 0; font-size: 22px; font-weight: 700;">${
                  companyInfo?.soc?.name || "Property Management"
                }</h1>
                <p style="margin: 3px 0 0; opacity: 0.8; font-size: 12px;">${
                  companyInfo?.soc?.location || ""
                }</p>
              </div>
            </div>
            <div style="text-align: right; background: rgba(255,255,255,0.2); padding: 10px; border-radius: 10px;">
              <p style="margin: 0; opacity: 0.9; font-size: 11px;">Total Paid</p>
              <p style="margin: 3px 0 0; font-size: 22px; font-weight: 800; color: white;">PKR ${totalPaidAmount.toLocaleString(
                "en-US",
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}</p>
            </div>
          </div>
        </div>
  
        <!-- Compact Grid Layout -->
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 15px; margin-bottom: 15px;">
          <div style="background: white; padding: 15px; border-radius: 12px; box-shadow: 0 6px 15px rgba(0,0,0,0.06); border: 1px solid #e2e8f0;">
            <h3 style="font-size: 16px; margin-bottom: 10px; color: #3b82f6; border-bottom: 2px solid #3b82f6; padding-bottom: 5px;">Customer Details</h3>
            ${renderDetailCard(installments[0]?.customerDetails)}
          </div>
  
          <div style="background: white; padding: 15px; border-radius: 12px; box-shadow: 0 6px 15px rgba(0,0,0,0.06); border: 1px solid #e2e8f0;">
            <h3 style="font-size: 16px; margin-bottom: 10px; color: #3b82f6; border-bottom: 2px solid #3b82f6; padding-bottom: 5px;">Property Info</h3>
            ${renderPropertyDetails(companyInfo)}
          </div>
  
         
        </div>
  
        <!-- Compact Installments Table -->
<div style="background: white; border-radius: 12px; overflow: hidden; box-shadow: 0 6px 15px rgba(0,0,0,0.06); margin-bottom: 15px; border: 2px solid #3b82f6;">
  <table style="width: 100%; border-collapse: separate; border-spacing: 0;">
    <thead style="background-color: #f1f5f9;">
      <tr>
        <th style="padding: 12px; text-align: left; border-bottom: 2px solid #3b82f6; border-right: 1px solid #e2e8f0; font-size: 12px; color: #1e40af;">Installment</th>
        <th style="padding: 12px; text-align: left; border-bottom: 2px solid #3b82f6; border-right: 1px solid #e2e8f0; font-size: 12px; color: #1e40af;">Amount</th>
        <th style="padding: 12px; text-align: left; border-bottom: 2px solid #3b82f6; border-right: 1px solid #e2e8f0; font-size: 12px; color: #1e40af;">Date</th>
        <th style="padding: 12px; text-align: left; border-bottom: 2px solid #3b82f6; font-size: 12px; color: #1e40af;">Status</th>
      </tr>
    </thead>
    <tbody>
      ${installments.map(renderInstallmentRow).join("")}
    </tbody>
  </table>
</div>

       <!-- Compact Summary Section -->
<div style="background: linear-gradient(135deg, #3b82f6, #1e40af); padding: 20px; border-radius: 16px; margin-bottom: 15px; box-shadow: 0 10px 20px rgba(0,0,0,0.1);">
  <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 20px;">
    ${renderSummaryCard(
      "Total Installments",
      installments.length,
      "#10b981",
      "#f0fdf4"
    )}
    ${renderSummaryCard(
      "Total Paid",
      `PKR ${totalPaidAmount.toFixed(2)}`,
      "#3b82f6",
      "#f0f9ff"
    )}
    ${renderSummaryCard(
      "Avg Installment",
      `PKR ${(totalPaidAmount / installments.length).toFixed(2)}`,
      "#8b5cf6",
      "#f5f3ff"
    )}
  </div>
</div>
        <!-- Certification Section -->
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div style="width: 100px; height: 100px; border: 3px dotted #3b82f6; border-radius: 50%; display: flex; justify-content: center; align-items: center; opacity: 0.7;">
            <span style="font-size: 10px; color: #3b82f6; text-align: center;">OFFICIAL\nSTAMP</span>
          </div>
          <div style="width: 200px; height: 70px; border: 2px dotted #1e40af; border-radius: 10px; display: flex; justify-content: center; align-items: center; opacity: 0.7;">
            <span style="font-size: 10px; color: #1e40af; text-align: center;">Customer Signature\n& Authorization</span>
          </div>
        </div>
      </div>
    `;

    function renderDetailCard(customerDetails) {
      return `
        <div style="display: grid; gap: 12px;">
          <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
            <p style="margin: 0; color: #64748b; font-size: 12px;">Name</p>
            <p style="margin: 4px 0 0; font-weight: 500;">${
              customerDetails?.name || "N/A"
            }</p>
          </div>
          <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
            <p style="margin: 0; color: #64748b; font-size: 12px;">Contact</p>
            <p style="margin: 4px 0 0; font-weight: 500;">${
              customerDetails?.phone || "N/A"
            }</p>
          </div>
          <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
            <p style="margin: 0; color: #64748b; font-size: 12px;">CNIC</p>
            <p style="margin: 4px 0 0; font-weight: 500;">${
              formatCNIC(customerDetails?.cnic) || "N/A"
            }</p>
          </div>
        </div>
      `;
    }

    function renderPropertyDetails(companyInfo) {
      return `
        <div style="display: grid; gap: 12px;">
          <div style="background: #f8fafc; padding: 12px; border-radius: 8px;">
            <p style="margin: 0; color: #64748b; font-size: 12px;">Plot Details</p>
            <p style="margin: 4px 0 0; font-weight: 500;">${
              companyInfo.plot_type
            } - Plot #${companyInfo.plot_number}</p>
            <p style="margin: 4px 0 0; color: #64748b;">${companyInfo.block}</p>
            <p style="margin: 4px 0 0; color: #64748b;">${
              companyInfo.size
            } Marla</p>
          </div>

          ${renderPaymentDetails(installments)}
        </div>
      `;
    }

    function renderPaymentDetails(installments) {
      return `
        <div style="display: grid; gap: 12px;">
          <div style="background: #f0fdf4; padding: 12px; border-radius: 8px;">
            <p style="margin: 0; color: #15803d; font-size: 12px;">Total Amount</p>
            <p style="margin: 4px 0 0; font-weight: 600; font-size: 20px;">PKR ${
              installments.length > 0
                ? installments[0].amount.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : "0.00"
            }</p>
          </div>
        </div>
      `;
    }

    function renderInstallmentRow(inst) {
      return `
        <tr style="border-bottom: 1px solid #e2e8f0;">
          <td style="padding: 12px; border-right: 1px solid #e2e8f0;">${
            inst.installment_number
          }</td>
          <td style="padding: 12px; border-right: 1px solid #e2e8f0;">PKR ${inst.paid_amount.toLocaleString(
            "en-US",
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}</td>
          <td style="padding: 12px; border-right: 1px solid #e2e8f0;">${new Date(
            inst.payment_date
          ).toLocaleDateString()}</td>
          <td style="padding: 12px; font-weight: 500; color: ${
            inst.status === "Paid" ? "#10b981" : "#ef4444"
          };">${inst.status}</td>
        </tr>
      `;
    }

    function renderSummaryCard(
      label,
      value,
      accentColor = "#3b82f6",
      bgColor = "#f0f9ff"
    ) {
      // Special handling for numeric values in strings starting with "PKR"
      let formattedValue = value;
      if (typeof value === "string" && value.startsWith("PKR ")) {
        // Extract the numeric part and format it
        const numericPart = parseFloat(value.replace("PKR ", ""));
        if (!isNaN(numericPart)) {
          formattedValue = `PKR ${numericPart.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
      } else if (typeof value === "number") {
        // Format plain numbers
        formattedValue = value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      return `
        <div style="background: ${bgColor}; padding: 18px; border-radius: 12px; text-align: center; box-shadow: 0 6px 15px rgba(0,0,0,0.08); border: 2px solid ${accentColor}20;">
          <p style="margin: 0; color: ${accentColor}; font-size: 12px; text-transform: uppercase; letter-spacing: 1px; margin-bottom: 8px;">${label}</p>
          <p style="margin: 0; font-size: 22px; font-weight: 700; color: ${accentColor};">${formattedValue}</p>
        </div>
      `;
    }

    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
        @page { 
          size: A4 portrait; 
          margin: 10mm; 
        }
        body { 
          margin: 0; 
          font-family: 'Inter', Arial, sans-serif; 
          line-height: 1.5;
          color: #333;
        }
      `,
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const installmentsPerPage = 5;

  // Pagination logic
  const indexOfLastInstallment = currentPage * installmentsPerPage;
  const indexOfFirstInstallment = indexOfLastInstallment - installmentsPerPage;
  const currentInstallments = installments.slice(
    indexOfFirstInstallment,
    indexOfLastInstallment
  );

  const totalPages = Math.ceil(installments.length / installmentsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="bg-white w-full rounded-xl shadow-lg border border-blue-200 overflow-hidden">
      <div className="bg-gradient-to-r from-blue-600 to-blue-500 text-white p-5 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <CheckCircle className="w-10 h-10 text-white/80" strokeWidth={1.5} />
          <div>
            <h1 className="text-2xl font-extrabold tracking-tight">
              Completed Installments
            </h1>
            <p className="text-sm text-white/70">
              Comprehensive Payment Summary
            </p>
          </div>
        </div>
        <div className="text-right">
          <p className="text-xs text-white/60 mb-1">Total Paid Amount</p>
          <p className="text-2xl font-bold text-white">
            PKR {totalPaidAmount.toLocaleString()}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 p-5">
        <div className="bg-blue-50/50 p-4 rounded-2xl text-center border border-blue-200 hover:shadow-md transition-all">
          <Calendar
            className="mx-auto text-blue-600 w-8 h-8 mb-3"
            strokeWidth={1.5}
          />
          <p className="text-sm text-gray-600 mb-1">Total Installments</p>
          <p className="font-bold text-gray-800 text-xl">
            {installments.length}
          </p>
        </div>
        <div className="bg-green-50/50 p-4 rounded-2xl text-center border border-green-200 hover:shadow-md transition-all">
          <CreditCard
            className="mx-auto text-green-600 w-8 h-8 mb-3"
            strokeWidth={1.5}
          />
          <p className="text-sm text-gray-600 mb-1">Total Paid Amount</p>
          <p className="font-bold text-gray-800 text-xl">
            PKR {totalPaidAmount.toLocaleString()}
          </p>
        </div>
        <div className="bg-purple-50/50 p-4 rounded-2xl text-center border border-purple-200 hover:shadow-md transition-all">
          <Building
            className="mx-auto text-purple-600 w-8 h-8 mb-3"
            strokeWidth={1.5}
          />
          <p className="text-sm text-gray-600 mb-1">Average Installment</p>
          <p className="font-bold text-gray-800 text-xl">
            PKR {(totalPaidAmount / installments.length || 0).toLocaleString()}
          </p>
        </div>
      </div>

      <div className="overflow-x-auto px-5 pb-5">
        <table className="w-full border-collapse rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-blue-600 text-white">
              <th className="p-3 text-left first:rounded-tl-lg last:rounded-tr-lg">
                Installment No.
              </th>
              <th className="p-3 text-left">Amount</th>
              <th className="p-3 text-left">Payment Date</th>
              <th className="p-3 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentInstallments.map((inst) => (
              <tr
                key={inst.installment_number}
                className="border-b border-blue-100 even:bg-blue-50/30 hover:bg-blue-100/50 transition-colors"
              >
                <td className="p-3 text-gray-700">{inst.installment_number}</td>
                <td className="p-3 text-gray-700">
                  PKR {inst.paid_amount.toLocaleString()}
                </td>
                <td className="p-3 text-gray-700">
                  {new Date(inst.payment_date).toLocaleDateString()}
                </td>
                <td className="p-3 text-gray-700">{inst.status}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex justify-center items-center mt-4 space-x-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 rounded-lg disabled:opacity-50 hover:bg-blue-100 transition-colors"
          >
            <ChevronLeft className="w-5 h-5 text-blue-600" />
          </button>

          <div className="text-sm text-gray-600">
            Page {currentPage} of {totalPages}
          </div>

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 rounded-lg disabled:opacity-50 hover:bg-blue-100 transition-colors"
          >
            <ChevronRight className="w-5 h-5 text-blue-600" />
          </button>
        </div>
      </div>

      <div className="p-5 border-t border-blue-100 bg-blue-50/30 flex justify-end">
        <button
          onClick={handlePrint}
          className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center space-x-2 shadow-md"
        >
          <FileText className="w-5 h-5" strokeWidth={1.5} />
          <span>Print Summary</span>
        </button>
      </div>
    </div>
  );
};

export default CompletedInstallmentsPrint;
