// import React, { useState, useRef, useEffect, useMemo } from "react";
// import {
//   Camera,
//   Send,
//   Paperclip,
//   Search,
//   Clock,
//   Check,
//   CheckCheck,
//   Phone,
//   Video,
//   MoreVertical,
//   Smile,
//   Mic,
//   Image,
//   Link2,
//   Calendar,
//   MessageSquare,
// } from "lucide-react";

// const MessagingDashboard = () => {
//   const [message, setMessage] = useState("");
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [showCamera, setShowCamera] = useState(false);
//   const [showEmoji, setShowEmoji] = useState(false);
//   const fileInputRef = useRef(null);
//   const messagesEndRef = useRef(null);
//   const [isRecording, setIsRecording] = useState(false);

//   // Enhanced messages with rich content
//   const messages = useMemo(
//     () => [
//       {
//         id: 1,
//         sender: "Sarah Johnson",
//         content: "Just finished the new dashboard mockups! Take a look! 🎨",
//         timestamp: "10:25 AM",
//         status: "read",
//         attachments: [
//           { type: "image", url: "/api/placeholder/300/200", preview: true },
//         ],
//       },
//       {
//         id: 2,
//         sender: "me",
//         content:
//           "These look incredible! Love the color scheme and the new layout. 🔥",
//         timestamp: "10:28 AM",
//         status: "read",
//         reactions: ["❤️", "👏"],
//       },
//     ],
//     []
//   );

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   useEffect(scrollToBottom, [messages]);

//   // Sample users with rich data
//   const users = [
//     {
//       id: 1,
//       name: "Sarah Johnson",
//       role: "Product Designer",
//       lastMessage: "The new designs look amazing! 🎨",
//       timestamp: "10:30 AM",
//       unread: 2,
//       online: true,
//       avatar: "/api/placeholder/40/40",
//       typing: true,
//       status: "✨ Creating something amazing",
//     },
//     {
//       id: 2,
//       name: "Michael Chen",
//       role: "Senior Developer",
//       lastMessage: "Check out this new feature implementation",
//       timestamp: "Yesterday",
//       unread: 0,
//       online: false,
//       avatar: "/api/placeholder/40/40",
//       status: "🚀 Debugging in progress",
//     },
//   ];

//   // Quick actions for the chat
//   const quickActions = [
//     { icon: <Calendar className="h-5 w-5" />, label: "Schedule" },
//     { icon: <Link2 className="h-5 w-5" />, label: "Share Link" },
//     { icon: <Image className="h-5 w-5" />, label: "Media" },
//   ];

//   return (
//     <div className="flex h-[90vh] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-sm">
//       {/* Enhanced Sidebar */}
//       <div className="w-96 border-r border-slate-700/50 backdrop-blur-sm">
//         <div className="p-6">
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search conversations..."
//               className="w-full bg-slate-700/50 text-teal-300 rounded-2xl px-6 py-4 pl-14 focus:outline-none focus:ring-2 focus:ring-teal-500/50 transition-all duration-300 placeholder-teal-300/30"
//             />
//             <Search className="absolute left-5 top-4 h-5 w-5 text-teal-300/50" />
//           </div>
//         </div>

//         {/* Enhanced User List */}
//         <div className="overflow-y-auto space-y-2 px-4">
//           {users.map((user) => (
//             <div
//               key={user.id}
//               className="group relative rounded-xl p-4 hover:bg-slate-700/30 cursor-pointer transition-all duration-300 backdrop-blur-sm"
//               onClick={() => setSelectedUser(user)}
//             >
//               <div className="flex items-center space-x-4">
//                 <div className="relative">
//                   <img
//                     src={user.avatar}
//                     alt={user.name}
//                     className="w-14 h-14 rounded-full ring-2 ring-teal-500/20 group-hover:ring-teal-500/50 transition-all duration-300"
//                   />
//                   {user.online && (
//                     <div className="absolute bottom-0 right-0 w-4 h-4 bg-teal-500 rounded-full border-2 border-slate-800 shadow-lg" />
//                   )}
//                 </div>
//                 <div className="flex-1 min-w-0">
//                   <div className="flex justify-between items-center">
//                     <span className="font-semibold text-teal-300 text-lg">
//                       {user.name}
//                     </span>
//                     <span className="text-xs text-teal-300/50">
//                       {user.timestamp}
//                     </span>
//                   </div>
//                   <div className="text-sm text-teal-300/50 font-medium">
//                     {user.role}
//                   </div>
//                   <div className="flex justify-between items-center mt-1">
//                     <span className="text-sm text-teal-300/70 truncate max-w-[200px]">
//                       {user.typing ? (
//                         <span className="text-teal-400">Typing...</span>
//                       ) : (
//                         user.lastMessage
//                       )}
//                     </span>
//                     {user.unread > 0 && (
//                       <span className="bg-teal-500 text-slate-900 rounded-full px-3 py-1 text-xs font-semibold shadow-lg">
//                         {user.unread}
//                       </span>
//                     )}
//                   </div>
//                 </div>
//               </div>
//               {user.status && (
//                 <div className="absolute bottom-2 right-4 text-xs text-teal-300/30">
//                   {user.status}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Enhanced Chat Area */}
//       <div className="flex-1 flex flex-col">
//         {selectedUser ? (
//           <>
//             {/* Enhanced Chat Header */}
//             <div className="p-6 border-b border-slate-700/50 backdrop-blur-sm">
//               <div className="flex items-center justify-between">
//                 <div className="flex items-center space-x-4">
//                   <img
//                     src={selectedUser.avatar}
//                     alt={selectedUser.name}
//                     className="w-12 h-12 rounded-full ring-2 ring-teal-500/20"
//                   />
//                   <div>
//                     <div className="font-semibold text-teal-300 text-lg">
//                       {selectedUser.name}
//                     </div>
//                     <div className="text-sm text-teal-300/50">
//                       {selectedUser.online ? (
//                         <span className="flex items-center space-x-2">
//                           <span className="w-2 h-2 bg-teal-500 rounded-full inline-block" />
//                           <span>Active now</span>
//                         </span>
//                       ) : (
//                         "Offline"
//                       )}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex items-center space-x-6">
//                   <button className="text-teal-300/70 hover:text-teal-300 transition-colors">
//                     <Phone className="h-5 w-5" />
//                   </button>
//                   <button className="text-teal-300/70 hover:text-teal-300 transition-colors">
//                     <Video className="h-5 w-5" />
//                   </button>
//                   <button className="text-teal-300/70 hover:text-teal-300 transition-colors">
//                     <MoreVertical className="h-5 w-5" />
//                   </button>
//                 </div>
//               </div>

//               {/* Quick Actions */}
//               <div className="flex space-x-4 mt-4">
//                 {quickActions.map((action, index) => (
//                   <button
//                     key={index}
//                     className="flex items-center space-x-2 px-4 py-2 rounded-lg bg-slate-700/30 text-teal-300/70 hover:bg-slate-700/50 hover:text-teal-300 transition-all duration-300"
//                   >
//                     {action.icon}
//                     <span className="text-sm">{action.label}</span>
//                   </button>
//                 ))}
//               </div>
//             </div>

//             {/* Enhanced Messages Area */}
//             <div className="flex-1 overflow-y-auto p-6 space-y-6">
//               {messages.map((msg) => (
//                 <div
//                   key={msg.id}
//                   className={`flex ${
//                     msg.sender === "me" ? "justify-end" : "justify-start"
//                   }`}
//                 >
//                   <div className="max-w-[70%] space-y-2">
//                     <div
//                       className={`rounded-2xl p-4 ${
//                         msg.sender === "me"
//                           ? "bg-teal-500 text-slate-900"
//                           : "bg-slate-700/50 text-teal-300"
//                       } shadow-lg backdrop-blur-sm`}
//                     >
//                       <div className="text-lg">{msg.content}</div>
//                       {msg.attachments?.map((attachment, index) => (
//                         <div
//                           key={index}
//                           className="mt-2 rounded-lg overflow-hidden"
//                         >
//                           <img
//                             src={attachment.url}
//                             alt="attachment"
//                             className="w-full object-cover"
//                           />
//                         </div>
//                       ))}
//                       <div className="flex items-center justify-end mt-2 space-x-2">
//                         <span className="text-xs opacity-70">
//                           {msg.timestamp}
//                         </span>
//                         {msg.sender === "me" &&
//                           (msg.status === "read" ? (
//                             <CheckCheck className="h-4 w-4 opacity-70" />
//                           ) : (
//                             <Check className="h-4 w-4 opacity-70" />
//                           ))}
//                       </div>
//                     </div>
//                     {msg.reactions && (
//                       <div className="flex justify-end space-x-1">
//                         {msg.reactions.map((reaction, index) => (
//                           <span
//                             key={index}
//                             className="bg-slate-700/30 rounded-full px-2 py-1 text-sm backdrop-blur-sm"
//                           >
//                             {reaction}
//                           </span>
//                         ))}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               ))}
//               <div ref={messagesEndRef} />
//             </div>

//             {/* Enhanced Input Area */}
//             <div className="p-6 border-t border-slate-700/50 backdrop-blur-sm">
//               <div className="flex items-center space-x-4">
//                 <button
//                   onClick={() => setShowEmoji(!showEmoji)}
//                   className="text-teal-300/70 hover:text-teal-300 transition-colors"
//                 >
//                   <Smile className="h-6 w-6" />
//                 </button>
//                 <button
//                   onClick={() => setShowCamera(!showCamera)}
//                   className="text-teal-300/70 hover:text-teal-300 transition-colors"
//                 >
//                   <Camera className="h-6 w-6" />
//                 </button>
//                 <button
//                   onClick={() => fileInputRef.current?.click()}
//                   className="text-teal-300/70 hover:text-teal-300 transition-colors"
//                 >
//                   <Paperclip className="h-6 w-6" />
//                 </button>
//                 <div className="flex-1 relative">
//                   <input
//                     type="text"
//                     value={message}
//                     onChange={(e) => setMessage(e.target.value)}
//                     placeholder="Type your message..."
//                     className="w-full bg-slate-700/30 text-teal-300 rounded-xl px-6 py-4 focus:outline-none focus:ring-2 focus:ring-teal-500/50 transition-all duration-300 placeholder-teal-300/30"
//                   />
//                 </div>
//                 <button
//                   onClick={() => setIsRecording(!isRecording)}
//                   className={`text-teal-300/70 hover:text-teal-300 transition-colors ${
//                     isRecording ? "text-red-500 animate-pulse" : ""
//                   }`}
//                 >
//                   <Mic className="h-6 w-6" />
//                 </button>
//                 <button
//                   onClick={() => {
//                     if (message.trim()) {
//                       // Handle send
//                       setMessage("");
//                     }
//                   }}
//                   className="bg-teal-500 text-slate-900 rounded-xl p-4 hover:bg-teal-400 transition-colors shadow-lg"
//                 >
//                   <Send className="h-6 w-6" />
//                 </button>
//               </div>
//               <input
//                 type="file"
//                 ref={fileInputRef}
//                 className="hidden"
//                 onChange={(e) => {
//                   // Handle file upload
//                 }}
//               />
//             </div>

//             {/* Enhanced Camera Modal */}
//             {showCamera && (
//               <div className="absolute inset-0 bg-slate-900/95 flex items-center justify-center backdrop-blur-lg">
//                 <div className="bg-slate-800 p-6 rounded-2xl w-[32rem] shadow-2xl">
//                   <div className="aspect-video bg-slate-700 rounded-xl mb-6 overflow-hidden">
//                     {/* Camera preview would go here */}
//                     <div className="w-full h-full flex items-center justify-center text-teal-300/50">
//                       Camera Preview
//                     </div>
//                   </div>
//                   <div className="flex justify-between">
//                     <button
//                       onClick={() => setShowCamera(false)}
//                       className="px-6 py-3 bg-slate-700/50 text-teal-300 rounded-xl hover:bg-slate-700 transition-colors"
//                     >
//                       Cancel
//                     </button>
//                     <button
//                       onClick={() => {
//                         // Handle capture
//                         setShowCamera(false);
//                       }}
//                       className="px-6 py-3 bg-teal-500 text-slate-900 rounded-xl hover:bg-teal-400 transition-colors"
//                     >
//                       Capture
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             )}
//           </>
//         ) : (
//           <div className="flex-1 flex flex-col items-center justify-center text-teal-300/50 space-y-4 p-8">
//             <div className="w-24 h-24 rounded-full bg-slate-700/30 flex items-center justify-center backdrop-blur-sm">
//               <MessageSquare className="w-12 h-12" />
//             </div>
//             <div className="text-xl font-medium text-teal-300">
//               Welcome to Messages
//             </div>
//             <div className="text-center max-w-md text-teal-300/50">
//               Select a conversation from the sidebar to start messaging. Your
//               messages are end-to-end encrypted and stored securely.
//             </div>
//             <div className="grid grid-cols-3 gap-4 mt-8">
//               <div className="text-center p-6 bg-slate-700/20 rounded-xl backdrop-blur-sm">
//                 <Phone className="w-8 h-8 mx-auto mb-3 text-teal-400" />
//                 <div className="text-teal-300 font-medium">Voice Calls</div>
//                 <div className="text-sm text-teal-300/50 mt-2">
//                   HD voice calls with crystal clear audio
//                 </div>
//               </div>
//               <div className="text-center p-6 bg-slate-700/20 rounded-xl backdrop-blur-sm">
//                 <Video className="w-8 h-8 mx-auto mb-3 text-teal-400" />
//                 <div className="text-teal-300 font-medium">Video Chats</div>
//                 <div className="text-sm text-teal-300/50 mt-2">
//                   Face-to-face conversations in HD
//                 </div>
//               </div>
//               <div className="text-center p-6 bg-slate-700/20 rounded-xl backdrop-blur-sm">
//                 <Link2 className="w-8 h-8 mx-auto mb-3 text-teal-400" />
//                 <div className="text-teal-300 font-medium">File Sharing</div>
//                 <div className="text-sm text-teal-300/50 mt-2">
//                   Share files up to 2GB instantly
//                 </div>
//               </div>
//             </div>
//             <button className="mt-8 px-8 py-4 bg-teal-500 text-slate-900 rounded-xl hover:bg-teal-400 transition-all duration-300 font-medium flex items-center space-x-2 group">
//               <span>Start New Conversation</span>
//               <Send className="w-4 h-4 transform group-hover:translate-x-1 transition-transform duration-300" />
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MessagingDashboard;
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Camera,
  Send,
  Paperclip,
  Search,
  Clock,
  Check,
  CheckCheck,
  Phone,
  Video,
  MoreVertical,
  Smile,
  Mic,
  Image,
  Link2,
  Calendar,
  MessageSquare,
} from "lucide-react";
import {
  fetchFilteredUsers,
  sendMessage,
  sendImageMessage,
  fetchConversation,
  setSearchQuery,
  clearSearch,
  setCurrentConversation,
  selectFilteredUsers,
  selectSearchResults,
  selectCurrentConversation,
  selectConversationMessages,
  selectCanChatWithUser,
} from "../store/slices/messageSlice";

const MessagingDashboard = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [showCamera, setShowCamera] = useState(false);

  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);

  // Redux selectors
  const filteredUsers = useSelector(selectFilteredUsers);
  const searchResults = useSelector(selectSearchResults);
  const selectedUser = useSelector(selectCurrentConversation);
  const conversationMessages = useSelector((state) =>
    selectedUser ? selectConversationMessages(state, selectedUser._id) : []
  );
  const canChatWithSelected = useSelector((state) =>
    selectedUser ? selectCanChatWithUser(state, selectedUser._id) : false
  );
  const activeUser = useSelector((state) => state.auth.activeUser);

  // Fetch filtered users on mount
  useEffect(() => {
    dispatch(fetchFilteredUsers());
  }, [dispatch]);

  // Polling for new messages
  useEffect(() => {
    if (selectedUser && canChatWithSelected) {
      const pollInterval = setInterval(() => {
        dispatch(
          fetchConversation({
            userId: selectedUser._id,
            page: 1,
            limit: 50,
          })
        );
      }, 5000);

      return () => clearInterval(pollInterval);
    }
  }, [selectedUser, canChatWithSelected, dispatch]);

  // Auto scroll to bottom when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationMessages]);

  const handleSearch = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const handleSelectUser = (user) => {
    dispatch(setCurrentConversation(user));
    dispatch(fetchConversation({ userId: user._id }));
  };

  const handleSendMessage = async () => {
    if (!message.trim() || !selectedUser) return;

    try {
      await dispatch(
        sendMessage({
          receiver: selectedUser._id,
          content: message,
        })
      ).unwrap();
      setMessage("");
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files?.[0];
    if (!file || !selectedUser) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("receiver", selectedUser._id);

    try {
      await dispatch(sendImageMessage(formData)).unwrap();
    } catch (error) {
      console.error("Failed to send file:", error);
    }
  };

  const displayUsers = searchResults.length > 0 ? searchResults : filteredUsers;

  return (
    <div className="flex h-[90vh] bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-sm">
      {/* Users Sidebar */}
      <div className="w-96 border-r border-slate-700/50 backdrop-blur-sm">
        <div className="p-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search users..."
              onChange={handleSearch}
              className="w-full bg-slate-700/50 text-teal-300 rounded-2xl px-6 py-4 pl-14 focus:outline-none focus:ring-2 focus:ring-teal-500/50 transition-all duration-300 placeholder-teal-300/30"
            />
            <Search className="absolute left-5 top-4 h-5 w-5 text-teal-300/50" />
          </div>
        </div>

        <div className="overflow-y-auto space-y-2 px-4">
          {displayUsers.map((user) => (
            <div
              key={user._id}
              className={`group relative rounded-xl p-4 hover:bg-slate-700/30 cursor-pointer transition-all duration-300 backdrop-blur-sm ${
                selectedUser?._id === user._id ? "bg-slate-700/50" : ""
              }`}
              onClick={() => handleSelectUser(user)}
            >
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <img
                    src={user.profile_picture || "/api/placeholder/40/40"}
                    alt={user.username}
                    className="w-14 h-14 rounded-full ring-2 ring-teal-500/20"
                  />
                  {user.status === "active" && (
                    <div className="absolute bottom-0 right-0 w-4 h-4 bg-teal-500 rounded-full border-2 border-slate-800" />
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex justify-between items-center">
                    <span className="font-semibold text-teal-300 text-lg">
                      {user.username}
                    </span>
                  </div>
                  <div className="text-sm text-teal-300/50 font-medium">
                    {user.role}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Chat Area */}
      {selectedUser ? (
        <div className="flex-1 flex flex-col">
          {/* Chat Header */}
          <div className="p-6 border-b border-slate-700/50">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <img
                  src={selectedUser.profile_picture || "/api/placeholder/40/40"}
                  alt={selectedUser.username}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <div className="font-semibold text-teal-300 text-lg">
                    {selectedUser.username}
                  </div>
                  <div className="text-sm text-teal-300/50">
                    {selectedUser.role}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Messages Area */}
          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {conversationMessages.map((msg) => (
              <div
                key={msg._id}
                className={`flex ${
                  msg.sender === activeUser?._id
                    ? "justify-end"
                    : "justify-start"
                }`}
              >
                <div className="max-w-[70%] space-y-2">
                  <div
                    className={`rounded-2xl p-4 ${
                      msg.sender === activeUser?._id
                        ? "bg-teal-500 text-slate-900"
                        : "bg-slate-700/50 text-teal-300"
                    }`}
                  >
                    <div className="text-lg">{msg.content}</div>
                    {msg.attachments?.map((attachment, index) => (
                      <img
                        key={index}
                        src={attachment}
                        alt="attachment"
                        className="mt-2 rounded-lg max-w-full"
                      />
                    ))}
                    {msg.capturedImage && (
                      <img
                        src={msg.capturedImage}
                        alt="captured"
                        className="mt-2 rounded-lg max-w-full"
                      />
                    )}
                    <div className="text-xs opacity-70 mt-2">
                      {new Date(msg.created_at).toLocaleTimeString()}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {/* Input Area */}
          {canChatWithSelected && (
            <div className="p-6 border-t border-slate-700/50">
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setShowCamera(!showCamera)}
                  className="text-teal-300/70 hover:text-teal-300 transition-colors"
                >
                  <Camera className="h-6 w-6" />
                </button>
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="text-teal-300/70 hover:text-teal-300 transition-colors"
                >
                  <Paperclip className="h-6 w-6" />
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="hidden"
                  onChange={handleFileUpload}
                  accept="image/*"
                />
                <div className="flex-1">
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                    placeholder="Type your message..."
                    className="w-full bg-slate-700/30 text-teal-300 rounded-xl px-6 py-4 focus:outline-none focus:ring-2 focus:ring-teal-500/50 transition-all duration-300 placeholder-teal-300/30"
                  />
                </div>
                <button
                  onClick={handleSendMessage}
                  disabled={!message.trim()}
                  className="bg-teal-500 text-slate-900 rounded-xl p-4 hover:bg-teal-400 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <Send className="h-6 w-6" />
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center text-teal-300/50">
          <div className="text-center">
            <MessageSquare className="w-16 h-16 mx-auto mb-4" />
            <h3 className="text-xl font-medium text-teal-300">
              Welcome to Messages
            </h3>
            <p className="mt-2">Select a user to start chatting</p>
          </div>
        </div>
      )}

      {/* Camera Modal */}
      {showCamera && (
        <div className="absolute inset-0 bg-slate-900/95 flex items-center justify-center backdrop-blur-lg">
          <div className="bg-slate-800 p-6 rounded-2xl w-[32rem]">
            <div className="aspect-video bg-slate-700 rounded-xl mb-6">
              <div className="w-full h-full flex items-center justify-center text-teal-300/50">
                Camera Preview
              </div>
            </div>
            <div className="flex justify-between">
              <button
                onClick={() => setShowCamera(false)}
                className="px-6 py-3 bg-slate-700/50 text-teal-300 rounded-xl hover:bg-slate-700"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Handle capture
                  setShowCamera(false);
                }}
                className="px-6 py-3 bg-teal-500 text-slate-900 rounded-xl hover:bg-teal-400"
              >
                Capture
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessagingDashboard;
