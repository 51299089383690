import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Trash2,
  Search,
  ChevronLeft,
  ChevronRight,
  Users,
  SearchCode,
  MapPin,
  Shield,
  Check,
  X,
  UserCog,
  Filter,
  RefreshCw
} from 'lucide-react'
import Swal from 'sweetalert2'
import { useSnackbar } from 'notistack'
import { fetchAllUsers, deleteUser } from '../store/slices/authSlice'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
import { motion, AnimatePresence } from 'framer-motion'

const UserManagement = () => {
  const dispatch = useDispatch()
  const { users, status, activeUser } = useSelector(state => state.auth)
  const { activeSociety, theme, gradient } = useSidebarContext()
  const { enqueueSnackbar } = useSnackbar()

  const [filteredUsers, setFilteredUsers] = useState(users)
  const [searchQuery, setSearchQuery] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [filterOpen, setFilterOpen] = useState(false)
  const [roleFilter, setRoleFilter] = useState('all')
  const [statusFilter, setStatusFilter] = useState('all')
  const [isLoading, setIsLoading] = useState(false)

  // Fetch users on component mount
  useEffect(() => {
    if (status === 'idle' || (status === 'failed' && users.length === 0)) {
      setIsLoading(true)
      dispatch(fetchAllUsers()).finally(() => setIsLoading(false))
    }
  }, [dispatch, status, users.length])

  useEffect(() => {
    setFilteredUsers(
      users.filter(user => {
        // Society filter
        const belongsToActiveSociety =
          activeSociety && user.societies && Array.isArray(user.societies)
            ? user.societies.some(s => s._id === activeSociety._id)
            : true

        // Search filter
        const matchesSearch = () => {
          const searchRegex = new RegExp(searchQuery, 'i')
          return (
            user.username?.match(searchRegex) ||
            user.customerData?.name?.match(searchRegex) ||
            user.employeeData?.name?.match(searchRegex) ||
            user.investorData?.name?.match(searchRegex) ||
            user.adminData?.name?.match(searchRegex) ||
            user.role?.match(searchRegex)
          )
        }

        // Role filter
        const matchesRole =
          roleFilter === 'all' ? true : user.role === roleFilter

        // Status filter
        const matchesStatus =
          statusFilter === 'all' ? true : user.status === statusFilter

        return (
          belongsToActiveSociety &&
          (!searchQuery || matchesSearch()) &&
          matchesRole &&
          matchesStatus
        )
      })
    )
  }, [searchQuery, users, activeSociety, roleFilter, statusFilter])

  const handleDeleteUser = useCallback(
    async userId => {
      Swal.fire({
        title: 'Delete User',
        text: 'Are you sure you want to permanently delete this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#10B981',
        cancelButtonColor: '#EF4444',
        background: '#1E293B',
        color: '#5EEAD4',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          popup: 'rounded-xl shadow-2xl',
          confirmButton: 'hover:bg-emerald-600 transition-colors',
          cancelButton: 'hover:bg-red-600 transition-colors'
        }
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            setIsLoading(true)
            await dispatch(deleteUser(userId))
            await dispatch(fetchAllUsers())
            setIsLoading(false)
            enqueueSnackbar('User deleted successfully!', {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            })
          } catch (error) {
            setIsLoading(false)
            console.error('Error deleting user:', error)
            enqueueSnackbar('Failed to delete user. Please try again.', {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right'
              }
            })
          }
        }
      })
    },
    [dispatch, enqueueSnackbar]
  )

  const refreshUsers = useCallback(() => {
    setIsLoading(true)
    dispatch(fetchAllUsers()).finally(() => {
      setIsLoading(false)
      enqueueSnackbar('User list refreshed', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    })
  }, [dispatch, enqueueSnackbar])

  const getRoleColor = role => {
    switch (role?.toLowerCase()) {
      case 'admin':
        return 'bg-purple-500/20 text-purple-400 border-purple-500/30'
      case 'customer':
        return 'bg-blue-500/20 text-blue-400 border-blue-500/30'
      case 'employee':
        return 'bg-amber-500/20 text-amber-400 border-amber-500/30'
      case 'investor':
        return 'bg-emerald-500/20 text-emerald-400 border-emerald-500/30'
      default:
        return `${theme.tailwind.bg1} ${theme.tailwind.text} border-white/20`
    }
  }

  const columns = useMemo(
    () => [
      {
        field: 'profile_picture',
        headerName: 'Profile',
        renderCell: user => (
          <div className='relative group'>
            <div
              className={`w-14 h-14 rounded-full border-2 ${theme.tailwind.border1} overflow-hidden transition-all duration-300 group-hover:scale-105 ${theme.tailwind.groupHover} shadow-lg`}
            >
              {user.profile_picture ? (
                <img
                  src={user.profile_picture}
                  alt={user.username}
                  className='w-full h-full object-cover'
                />
              ) : (
                <div
                  className={`w-full h-full ${gradient} flex items-center justify-center`}
                >
                  <span className={`text-xl font-bold ${theme.tailwind.text3}`}>
                    {user.username.charAt(0).toUpperCase()}
                  </span>
                </div>
              )}
            </div>
            <div className='absolute -bottom-1 -right-1 w-6 h-6 rounded-full bg-slate-800 border border-white/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
              <span
                className={`w-3 h-3 rounded-full ${
                  user.status === 'active' ? 'bg-emerald-400' : 'bg-rose-500'
                }`}
              ></span>
            </div>
          </div>
        )
      },
      {
        field: 'username',
        headerName: 'Username',
        renderCell: user => (
          <div className='space-y-1'>
            <span className={`font-medium ${theme.tailwind.texting} text-base`}>
              @{user.username}
            </span>
            <div className={`text-xs ${theme.tailwind.text2}`}>
              Member since {new Date().toLocaleDateString()}
            </div>
          </div>
        )
      },
      {
        field: 'name',
        headerName: 'Name',
        renderCell: user => (
          <span className={`${theme.tailwind.texting1} font-medium`}>
            {user.adminData?.name ||
              user.customerData?.name ||
              user.employeeData?.name ||
              user.investorData?.name ||
              'No Name'}
          </span>
        )
      },
      {
        field: 'role',
        headerName: 'Role',
        renderCell: user => (
          <span
            className={`px-3 py-1.5 rounded-full w-28 border text-sm font-medium flex items-center gap-1.5 ${getRoleColor(
              user.role
            )}`}
          >
            <Shield className='h-3.5 w-3.5' />
            {user.role || 'Unassigned'}
          </span>
        )
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: user => (
          <span
            className={`px-4 py-1.5 rounded-full w-20 text-xs font-medium flex items-center gap-1.5 ${
              user.status === 'active'
                ? 'bg-emerald-400/20 text-emerald-300 border border-emerald-500/30'
                : 'bg-rose-500/20 text-rose-500 border border-rose-500/30'
            }`}
          >
            {user.status === 'active' ? (
              <Check className='h-3.5 w-3.5' />
            ) : (
              <X className='h-3.5 w-3.5' />
            )}
            {user.status}
          </span>
        )
      }
    ],
    [theme.tailwind, gradient]
  )

  const paginatedUsers = useMemo(() => {
    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize
    return filteredUsers.slice(startIndex, endIndex)
  }, [filteredUsers, page, pageSize])

  const totalPages = Math.ceil(filteredUsers.length / pageSize)

  const uniqueRoles = useMemo(() => {
    const roles = new Set(users.map(user => user.role).filter(Boolean))
    return Array.from(roles)
  }, [users])

  return (
    <div
      className={`${gradient} rounded-3xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] ${theme.tailwind.text} p-8`}
    >
      <div className='max-w-7xl mx-auto space-y-8'>
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className='flex items-center justify-between bg-white/10 backdrop-blur-2xl border border-white/10 rounded-3xl p-8 shadow-2xl'
        >
          <div className='space-y-3'>
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.4 }}
              className='flex items-center'
            >
              <span
                className={`inline-flex items-center px-6 py-2 rounded-full ${gradient} ${theme.tailwind.text3} border ${theme.tailwind.border1} shadow-lg`}
              >
                <MapPin className={`mr-2 w-5 h-5 ${theme.tailwind.text}`} />
                {activeSociety?.name || 'Loading...'}
              </span>
              <span
                className={`text-4xl font-extrabold m-4 ${theme.tailwind.textColor} bg-clip-text text-transparent`}
              >
                User Management
              </span>
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.3 }}
                className='inline-flex items-center px-3 py-1 bg-white/10 rounded-full text-xs border border-white/20'
              >
                {filteredUsers.length} users
              </motion.span>
            </motion.div>
            <p className={`${theme.tailwind.text} text-sm ml-1 max-w-2xl`}>
              Manage all users in your organization with advanced filtering and
              search capabilities. Assign roles, monitor status, and ensure
              security compliance.
            </p>
          </div>
          <motion.div
            initial={{ rotate: -10, opacity: 0 }}
            animate={{ rotate: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className='flex items-center gap-4'
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              onClick={refreshUsers}
              className='p-3 rounded-xl bg-white/10 border border-white/20 hover:bg-white/20 transition-all duration-300'
            >
              <RefreshCw
                className={`w-5 h-5 ${theme.tailwind.text} ${
                  isLoading ? 'animate-spin' : ''
                }`}
              />
            </motion.button>
            <div
              className={`w-16 h-16 rounded-2xl ${gradient} flex items-center justify-center shadow-lg`}
            >
              <UserCog className={`w-8 h-8 ${theme.tailwind.text}`} />
            </div>
          </motion.div>
        </motion.div>

        {/* Search and Filters */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className={`bg-white/10 backdrop-blur-2xl border border-white/10 rounded-3xl p-6 space-y-4 shadow-xl`}
        >
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-3'>
              <SearchCode className={`w-6 h-6 ${theme.tailwind.text}`} />
              <h3 className={`text-xl font-semibold ${theme.tailwind.text}`}>
                Search & Filter
              </h3>
            </div>
            <button
              onClick={() => setFilterOpen(!filterOpen)}
              className={`flex items-center gap-2 px-4 py-2 rounded-xl ${
                filterOpen ? 'bg-white/20' : 'bg-white/10'
              } border border-white/20 hover:bg-white/20 transition-all duration-300`}
            >
              <Filter className={`w-4 h-4 ${theme.tailwind.text}`} />
              <span>Filters</span>
            </button>
          </div>

          <div className='relative'>
            <Search
              className={`absolute left-4 top-1/2 -translate-y-1/2 ${theme.tailwind.text2} w-6 h-6`}
            />
            <input
              type='text'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder='Search users by username, role, or name...'
              className={`w-full pl-14 pr-6 py-4 bg-white/10 rounded-xl border border-white/20 ${theme.tailwind.focus1} focus:ring-2 ${theme.tailwind.focusRing} transition-all duration-300 ${theme.tailwind.text} ${theme.tailwind.placeHolder}`}
            />
          </div>

          <AnimatePresence>
            {filterOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className='overflow-hidden'
              >
                <div className='grid grid-cols-2 gap-6 pt-4 border-t border-white/10'>
                  <div className='space-y-2'>
                    <label
                      className={`block text-sm font-medium ${theme.tailwind.text4}`}
                    >
                      Filter by Role
                    </label>
                    <div className='flex flex-wrap gap-2'>
                      <button
                        onClick={() => setRoleFilter('all')}
                        className={`px-3 py-1.5 rounded-full text-sm ${
                          roleFilter === 'all'
                            ? 'bg-white/20 border border-white/30'
                            : 'bg-white/5 border border-white/10 hover:bg-white/10'
                        } transition-all duration-300`}
                      >
                        All
                      </button>
                      {uniqueRoles.map(role => (
                        <button
                          key={role}
                          onClick={() => setRoleFilter(role)}
                          className={`px-3 py-1.5 rounded-full text-sm ${
                            roleFilter === role
                              ? `${getRoleColor(role)} border-opacity-70`
                              : 'bg-white/5 border border-white/10 hover:bg-white/10'
                          } transition-all duration-300`}
                        >
                          {role}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className='space-y-2'>
                    <label
                      className={`block text-sm font-medium ${theme.tailwind.text4}`}
                    >
                      Filter by Status
                    </label>
                    <div className='flex gap-3'>
                      <button
                        onClick={() => setStatusFilter('all')}
                        className={`px-3 py-1.5 rounded-full text-sm ${
                          statusFilter === 'all'
                            ? 'bg-white/20 border border-white/30'
                            : 'bg-white/5 border border-white/10 hover:bg-white/10'
                        } transition-all duration-300`}
                      >
                        All
                      </button>
                      <button
                        onClick={() => setStatusFilter('active')}
                        className={`px-3 py-1.5 rounded-full text-sm flex items-center gap-1.5 ${
                          statusFilter === 'active'
                            ? 'bg-emerald-400/20 text-emerald-300 border border-emerald-500/30'
                            : 'bg-white/5 border border-white/10 hover:bg-white/10'
                        } transition-all duration-300`}
                      >
                        <Check className='h-3.5 w-3.5' />
                        Active
                      </button>
                      <button
                        onClick={() => setStatusFilter('inactive')}
                        className={`px-3 py-1.5 rounded-full text-sm flex items-center gap-1.5 ${
                          statusFilter === 'inactive'
                            ? 'bg-rose-500/20 text-rose-500 border border-rose-500/30'
                            : 'bg-white/5 border border-white/10 hover:bg-white/10'
                        } transition-all duration-300`}
                      >
                        <X className='h-3.5 w-3.5' />
                        Inactive
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        {/* User Table */}
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className='bg-white/10 backdrop-blur-2xl border border-white/10 rounded-3xl shadow-2xl overflow-hidden'
        >
          {isLoading ? (
            <div className='flex items-center justify-center py-20'>
              <div className='flex flex-col items-center gap-4'>
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ repeat: Infinity, duration: 2, ease: 'linear' }}
                  className={`w-12 h-12 rounded-full border-4 border-white/10 border-t-${theme.tailwind.text}`}
                />
                <p className={`${theme.tailwind.text2} text-sm`}>
                  Loading users...
                </p>
              </div>
            </div>
          ) : (
            <div className='overflow-x-auto'>
              <table className='w-full'>
                <thead className='bg-white/10 border-b border-white/20'>
                  <tr>
                    {columns.map(column => (
                      <th
                        key={column.field}
                        className={`px-6 py-5 text-left text-sm font-semibold ${theme.tailwind.text4} uppercase tracking-wider`}
                      >
                        {column.headerName}
                      </th>
                    ))}
                    <th
                      className={`px-6 py-5 text-left text-sm font-semibold ${theme.tailwind.text4} uppercase tracking-wider`}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedUsers.length === 0 ? (
                    <tr>
                      <td
                        colSpan={columns.length + 1}
                        className='px-6 py-16 text-center'
                      >
                        <div className='flex flex-col items-center gap-4'>
                          <Users
                            className={`w-16 h-16 ${theme.tailwind.text2} opacity-70`}
                          />
                          <p className={`${theme.tailwind.text2} text-lg`}>
                            No users found
                          </p>
                          <p
                            className={`${theme.tailwind.text4} text-sm max-w-md`}
                          >
                            Try adjusting your search or filter criteria to find
                            what you're looking for.
                          </p>
                          <button
                            onClick={() => {
                              setSearchQuery('')
                              setRoleFilter('all')
                              setStatusFilter('all')
                            }}
                            className={`mt-2 px-4 py-2 rounded-xl bg-white/10 border border-white/20 ${theme.tailwind.text} hover:bg-white/20 transition-colors duration-300`}
                          >
                            Clear filters
                          </button>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    paginatedUsers.map((user, index) => (
                      <motion.tr
                        key={user._id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.05, duration: 0.3 }}
                        className={`transition-colors duration-300 ${
                          index % 2 === 0 ? 'bg-white/5' : 'bg-white/10'
                        } hover:bg-white/20`}
                      >
                        {columns.map(column => (
                          <td key={column.field} className='px-6 py-5'>
                            {column.renderCell
                              ? column.renderCell(user)
                              : user[column.field]}
                          </td>
                        ))}
                        <td className='px-6 py-5'>
                          <div className='flex gap-2'>
                            {activeUser?.role === 'admin' &&
                            user.role === 'admin' ? (
                              <button
                                disabled
                                className='p-2.5 rounded-lg bg-rose-500/10 text-rose-400/50 cursor-not-allowed'
                                title='Cannot delete admin user'
                              >
                                <Trash2 className='h-5 w-5' />
                              </button>
                            ) : (
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => handleDeleteUser(user._id)}
                                className='p-2.5 rounded-lg bg-rose-500/20 text-rose-400 transition-all duration-300 hover:bg-rose-500/40 border border-rose-500/30'
                                title='Delete user'
                              >
                                <Trash2 className='h-5 w-5' />
                              </motion.button>
                            )}
                          </div>
                        </td>
                      </motion.tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className='px-8 py-5 bg-white/10 backdrop-blur-sm border-t border-white/20 flex items-center justify-between'>
            <span className={`text-sm ${theme.tailwind.text4}`}>
              Showing {filteredUsers.length > 0 ? (page - 1) * pageSize + 1 : 0}{' '}
              to {Math.min(page * pageSize, filteredUsers.length)} of{' '}
              {filteredUsers.length} users
            </span>
            <div className='flex items-center space-x-2'>
              <button
                onClick={() => setPage(1)}
                disabled={page === 1}
                className={`p-2 rounded-lg bg-white/10 ${theme.tailwind.text} disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20 border border-white/20`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <polyline points='11 17 6 12 11 7'></polyline>
                  <polyline points='18 17 13 12 18 7'></polyline>
                </svg>
              </button>
              <button
                onClick={() => setPage(Math.max(1, page - 1))}
                disabled={page === 1}
                className={`p-2 rounded-lg bg-white/10 ${theme.tailwind.text} disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20 border border-white/20`}
              >
                <ChevronLeft className='h-5 w-5' />
              </button>
              <div className='flex items-center'>
                {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                  let pageNum
                  if (totalPages <= 5) {
                    pageNum = i + 1
                  } else if (page <= 3) {
                    pageNum = i + 1
                  } else if (page >= totalPages - 2) {
                    pageNum = totalPages - 4 + i
                  } else {
                    pageNum = page - 2 + i
                  }

                  return (
                    <button
                      key={i}
                      onClick={() => setPage(pageNum)}
                      className={`h-9 w-9 rounded-lg mx-1 text-sm font-medium transition-all duration-300 ${
                        page === pageNum
                          ? `${gradient} ${theme.tailwind.text3} border border-white/30`
                          : 'bg-white/10 border border-white/20 hover:bg-white/20'
                      }`}
                    >
                      {pageNum}
                    </button>
                  )
                })}
              </div>
              <button
                onClick={() => setPage(Math.min(totalPages, page + 1))}
                disabled={page === totalPages || totalPages === 0}
                className={`p-2 rounded-lg bg-white/10 ${theme.tailwind.text} disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20 border border-white/20`}
              >
                <ChevronRight className='h-5 w-5' />
              </button>
              <button
                onClick={() => setPage(totalPages)}
                disabled={page === totalPages || totalPages === 0}
                className={`p-2 rounded-lg bg-white/10 ${theme.tailwind.text} disabled:opacity-30 disabled:cursor-not-allowed transition-all duration-300 hover:bg-white/20 border border-white/20`}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <polyline points='13 17 18 12 13 7'></polyline>
                  <polyline points='6 17 11 12 6 7'></polyline>
                </svg>
              </button>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default UserManagement
