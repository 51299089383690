import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  fetchTransactions,
  modifyTransaction,
  removeTransaction
} from '../store/slices/transactionSlice'

import * as XLSX from 'xlsx'
import Swal from 'sweetalert2'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
import {
  ArrowUpDown,
  Calendar,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Edit,
  Search,
  SlidersHorizontal,
  Trash2,
  X
} from 'lucide-react'
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import EnhancedDateFilter from './EnhancedDateFilter'

const IncomeDirection = () => {
  const dispatch = useDispatch()
  const { activeSociety } = useSidebarContext()

  const activeUser = useSelector(state => state.auth.activeUser)

  // const [dateRange, setDateRange] = useState('All Dates')

  const transactionsData = useSelector(state => state.transactions)
  const [currentPage, setCurrentPage] = useState(1)
  console.log(transactionsData)
  const [filters, setFilters] = useState({
    transactionType: '',
    amount: '',
    description: '',
    startDate: '',
    endDate: ''
  })
  const [sortOrder, setSortOrder] = useState('desc')
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [isFilterExpanded, setIsFilterExpanded] = useState(false)
  const isEmployee = activeUser?.role === 'Employee'
  const plotsPerPage = 10
  const [filteredTransactions, setFilteredTransactions] = useState([])
  const [totalFilteredAmount, setTotalFilteredAmount] = useState(0)
  const [displayDateText, setDisplayDateText] = useState(
    dateRange[0] && dateRange[1]
      ? `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`
      : ''
  )
  useEffect(() => {
    if (activeSociety?._id) {
      dispatch(
        fetchTransactions({
          societyId: activeSociety._id,
          direction: 'Income',
          page: currentPage,
          limit: plotsPerPage,
          description: filters.description,
          startDate: filters.startDate,
          endDate: filters.endDate,
          sortOrder: sortOrder
        })
      )
    }
  }, [
    dispatch,
    activeSociety,
    currentPage,
    filters.description,
    filters.startDate,
    filters.endDate,
    sortOrder
  ])

  const transactions = useMemo(() => {
    if (transactionsData?.transactions?.data?.transactions) {
      return transactionsData.transactions.data.transactions
    }
    return []
  }, [transactionsData])

  useEffect(() => {
    // Apply filters to transactions
    const filtered = transactions.filter(transaction => {
      // Description filter - case insensitive search
      if (
        filters.description &&
        !transaction.description
          ?.toLowerCase()
          .includes(filters.description.toLowerCase())
      ) {
        return false
      }

      // Date range filter
      if (filters.startDate && filters.endDate) {
        const transactionDate = new Date(transaction.transaction_date)
        // Set time to beginning of day for start date
        const startDate = new Date(filters.startDate)
        startDate.setHours(0, 0, 0, 0)

        // Set time to end of day for end date
        const endDate = new Date(filters.endDate)
        endDate.setHours(23, 59, 59, 999)

        if (transactionDate < startDate || transactionDate > endDate) {
          return false
        }
      }

      // Filter by transaction direction if specified
      if (
        filters.transactionDirection &&
        transaction.transaction_direction !== filters.transactionDirection
      ) {
        return false
      }

      return true
    })

    // Sort the filtered transactions
    const sorted = [...filtered].sort((a, b) => {
      const dateA = new Date(a.transaction_date)
      const dateB = new Date(b.transaction_date)
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA
    })

    setFilteredTransactions(sorted)

    // Calculate total amount for filtered transactions
    const total = filtered.reduce((sum, transaction) => {
      if (transaction.transaction_direction === 'in') {
        return sum + (transaction.amount || 0)
      } else if (transaction.transaction_direction === 'out') {
        return sum - (transaction.amount || 0)
      }
      return sum
    }, 0)

    setTotalFilteredAmount(total)
  }, [transactions, filters, sortOrder])

  // Calculate pagination properly
  const currentTransactions = transactions

  const totalFilteredAmounts = useMemo(() => {
    if (!Array.isArray(filteredTransactions)) return 0

    return filteredTransactions.reduce(
      (sum, transaction) => sum + Number(transaction.amount || 0),
      0
    )
  }, [filteredTransactions])

  const handleEdit = transaction => {
    if (isEmployee) return

    // Create a ref to store the selected date
    const dateRef = React.createRef()

    const CustomDialog = () => {
      // Local state for the date
      const [dateValue, setDateValue] = React.useState(
        new Date(transaction.transaction_date)
      )

      return (
        <div className='max-w-3xl mx-auto p-6'>
          <div className='bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-8 rounded-xl shadow-2xl border border-slate-700'>
            <h2 className='text-3xl font-bold text-teal-300 mb-6'>
              Edit Transaction
            </h2>
            <div className='grid grid-cols-2 gap-6'>
              {[
                {
                  id: 'amount',
                  label: 'Amount',
                  type: 'number',
                  value: transaction.amount
                },
                {
                  id: 'transaction_type',
                  label: 'Transaction Type',
                  type: 'text',
                  value: transaction.transaction_type
                },
                {
                  id: 'transaction_direction',
                  label: 'Transaction Direction',
                  type: 'text',
                  value: transaction.transaction_direction
                },
                {
                  id: 'payment_method',
                  label: 'Payment Method',
                  type: 'text',
                  value: transaction.payment_method
                },
                {
                  id: 'status',
                  label: 'Status',
                  type: 'text',
                  value: transaction.status
                },
                {
                  id: 'description',
                  label: 'Description',
                  type: 'text',
                  value: transaction.description
                },
                {
                  id: 'receipt_no',
                  label: 'Receipt No',
                  type: 'text',
                  value: transaction.receipt_no
                }
              ].map(field => (
                <div key={field.id} className='relative'>
                  <label className='block text-teal-300 text-sm font-medium mb-2'>
                    {field.label}
                  </label>
                  <input
                    id={field.id}
                    type={field.type}
                    className='w-full px-4 py-2.5 rounded-lg border border-slate-600 bg-slate-700 text-white 
                                 placeholder-slate-400 focus:border-teal-400 focus:ring-2 focus:ring-teal-400 
                                 focus:ring-opacity-50 transition-all duration-200 ease-in-out'
                    defaultValue={field.value}
                  />
                </div>
              ))}
              <div className='relative'>
                <label className='block text-teal-300 text-sm font-medium mb-2'>
                  Transaction Date
                </label>
                <DatePicker
                  selected={dateValue}
                  onChange={date => {
                    setDateValue(date)
                    dateRef.current = date
                  }}
                  dateFormat='dd/MM/yyyy'
                  placeholderText='DD/MM/YYYY'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className='w-full px-4 py-2.5 rounded-lg border border-slate-600 bg-slate-700 text-white 
                               placeholder-slate-400 focus:border-teal-400 focus:ring-2 focus:ring-teal-400 
                               focus:ring-opacity-50 transition-all duration-200 ease-in-out'
                />
              </div>
            </div>
          </div>
        </div>
      )
    }

    Swal.fire({
      html: ReactDOMServer.renderToString(<CustomDialog />),
      showCancelButton: true,
      confirmButtonText: 'Save Changes',
      cancelButtonText: 'Cancel',
      width: 'auto',
      background: '#1E293B',
      customClass: {
        popup:
          'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl text-teal-400',
        confirmButton:
          'bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        cancelButton:
          'bg-red-500 hover:bg-red-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        title: 'text-2xl font-bold text-teal-300 mb-4',
        container: 'backdrop-blur-sm'
      },
      didOpen: () => {
        // Mount the actual React component
        const container = document.querySelector('.swal2-html-container')
        if (container) {
          ReactDOM.render(<CustomDialog />, container)
        }
      },
      willClose: () => {
        // Cleanup
        const container = document.querySelector('.swal2-html-container')
        if (container) {
          ReactDOM.unmountComponentAtNode(container)
        }
      },
      preConfirm: () => {
        return {
          transaction_date: dateRef.current
            ? dateRef.current.toISOString()
            : transaction.transaction_date,
          amount: document.getElementById('amount').value,
          transaction_type: document.getElementById('transaction_type').value,
          transaction_direction: document.getElementById(
            'transaction_direction'
          ).value,
          payment_method: document.getElementById('payment_method').value,
          status: document.getElementById('status').value,
          description: document.getElementById('description').value,
          receipt_no: document.getElementById('receipt_no').value
        }
      }
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          modifyTransaction({
            id: transaction._id,
            transactionData: {
              ...transaction,
              ...result.value
            },
            societyId: activeSociety._id
          })
        )

        Swal.fire({
          title: 'Success!',
          text: 'Transaction has been updated successfully',
          icon: 'success',
          confirmButtonColor: '#10B981',
          background: '#1E293B',
          color: '#5EEAD4',
          confirmButtonText: 'Done',
          customClass: {
            popup:
              'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl',
            confirmButton:
              'hover:bg-emerald-600 transition-colors px-6 py-2.5 rounded-lg',
            title: 'text-xl font-bold text-teal-300'
          }
        })
      }
    })
  }

  const handleDelete = transactionId => {
    if (isEmployee) return
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      background: '#1E293B',
      customClass: {
        popup:
          'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl text-teal-400',
        confirmButton:
          'bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        cancelButton:
          'bg-red-500 hover:bg-red-600 text-white px-6 py-2.5 rounded-lg transition-colors duration-200',
        title: 'text-2xl font-bold text-teal-300 mb-4',
        container: 'backdrop-blur-sm'
      }
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(removeTransaction(transactionId))

        Swal.fire({
          title: 'Deleted!',
          text: 'The transaction has been deleted.',
          icon: 'success',
          confirmButtonColor: '#10B981',
          background: '#1E293B',
          color: '#5EEAD4',
          confirmButtonText: 'Done',
          customClass: {
            popup:
              'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl',
            confirmButton:
              'hover:bg-emerald-600 transition-colors px-6 py-2.5 rounded-lg',
            title: 'text-xl font-bold text-teal-300'
          }
        })
      }
    })
  }
  const getTransactionTypeColor = transactionType => {
    const colors = {
      'Full Payment': 'bg-green-500/20 hover:bg-green-500/30',
      'Partial Payment': 'bg-green-500/20 hover:bg-green-500/30',
      'Installment Payment': 'bg-purple-500/20 hover:bg-purple-500/30',
      'Salary Payment': 'bg-yellow-500/20 hover:bg-yellow-500/30',
      'Expense Payment': 'bg-red-500/20 hover:bg-red-500/30',
      'Resell Payment': 'bg-indigo-500/20 hover:bg-indigo-500/30',
      Other: 'bg-green-500/20 hover:bg-green-500/30',
      'Dealer Commission': 'bg-orange-500/20 hover:bg-orange-500/30',
      'Booking Full Payment': 'bg-cyan-500/20 hover:bg-cyan-500/30',
      'Booking Installment Payment': 'bg-cyan-500/20 hover:bg-cyan-500/30',
      'Transfer Fee': 'bg-pink-500/20 hover:bg-pink-500/30'
    }
    return colors[transactionType] || 'bg-green-500/20 hover:bg-green-500/30'
  }

  const totalIncome = useMemo(() => {
    if (transactionsData?.transactions?.data?.summary?.totalIncome) {
      return transactionsData.transactions.data.summary.totalIncome
    }
    return 0
  }, [transactionsData])

  // Modified handleNextPage function
  const handleNextPage = () => {
    // Only need to check if there's more data to fetch
    if (
      transactionsData?.transactions?.data?.total >
      currentPage * plotsPerPage
    ) {
      const nextPage = currentPage + 1
      setCurrentPage(nextPage)
      dispatch(
        fetchTransactions({
          societyId: activeSociety._id,
          direction: 'Income',
          page: nextPage,
          limit: plotsPerPage
        })
      )
    }
  }
  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc'
    setSortOrder(newSortOrder)

    // Reset to first page when sort order changes
    setCurrentPage(1)
  }

  // Modified handlePreviousPage function
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1
      setCurrentPage(prevPage)
      dispatch(
        fetchTransactions({
          societyId: activeSociety._id,
          direction: 'Income',
          page: prevPage,
          limit: plotsPerPage
        })
      )
    }
  }

  // Modified renderPageNumbers function
  const renderPageNumbers = () => {
    // Calculate total pages based on API total count
    const totalItems = transactionsData?.transactions?.data?.total || 0
    const totalPages = Math.ceil(totalItems / plotsPerPage)
    let pages = []

    if (totalPages <= 5) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage <= 3) {
        pages = [1, 2, 3, 4, 5]
      } else if (currentPage >= totalPages - 2) {
        pages = Array.from({ length: 5 }, (_, i) => totalPages - 4 + i)
      } else {
        pages = [
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2
        ]
      }
    }

    return pages.map(pageNum => (
      <button
        key={pageNum}
        onClick={() => {
          setCurrentPage(pageNum)
          dispatch(
            fetchTransactions({
              societyId: activeSociety._id,
              direction: 'Income',
              page: pageNum,
              limit: plotsPerPage
            })
          )
        }}
        className={`flex items-center justify-center h-6 w-6 sm:h-8 sm:w-8 rounded-full text-xs sm:text-sm font-medium transition-all duration-300 ${
          pageNum === currentPage
            ? 'bg-teal-500 text-white shadow-lg scale-110'
            : 'bg-white/10 text-teal-300 hover:bg-slate-400 hover:text-white'
        }`}
      >
        {pageNum}
      </button>
    ))
  }
  const exportTransactionsToExcel = async (transactions, period) => {
    // Make sure transactions is an array
    if (!Array.isArray(transactions)) {
      Swal.fire('Error', 'No transactions data available for export.', 'error')
      return
    }

    // Create date filters based on selected period
    let startDate = null
    let endDate = null
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    switch (period) {
      case 'day':
        // Today
        startDate = today.toISOString()
        const todayEnd = new Date(today)
        todayEnd.setHours(23, 59, 59, 999)
        endDate = todayEnd.toISOString()
        break
      case 'week':
        // This Week (last 7 days)
        const weekStart = new Date(today)
        weekStart.setDate(today.getDate() - 7)
        startDate = weekStart.toISOString()
        endDate = new Date().toISOString()
        break
      case 'month':
        // This Month
        const monthStart = new Date(today.getFullYear(), today.getMonth(), 1)
        startDate = monthStart.toISOString()
        const monthEnd = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
          23,
          59,
          59,
          999
        )
        endDate = monthEnd.toISOString()
        break
      case 'year':
        // This Year
        const yearStart = new Date(today.getFullYear(), 0, 1)
        startDate = yearStart.toISOString()
        const yearEnd = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        endDate = yearEnd.toISOString()
        break
      case 'all':
      default:
        // All Time - no date filtering needed
        break
    }

    // If a period filter was selected, fetch the filtered data
    if (period === 'all') {
      try {
        // Fetch all transactions without date filtering
        const response = await dispatch(
          fetchTransactions({
            societyId: activeSociety._id,
            direction: 'Income',
            description: filters.description,
            sortOrder
          })
        ).unwrap()

        // If we got data back, use it instead
        if (
          response?.data?.transactions &&
          Array.isArray(response.data.transactions)
        ) {
          transactions = response.data.transactions
        }
      } catch (error) {
        console.error('Error fetching all transactions for export:', error)
        // Continue with whatever data we already have
      }
    }
    // If a specific period filter was selected, fetch the filtered data
    else if (startDate && endDate) {
      try {
        // Fetch filtered data based on the date range
        const response = await dispatch(
          fetchTransactions({
            societyId: activeSociety._id,
            direction: 'Income',
            description: filters.description,
            startDate,
            endDate,
            sortOrder
          })
        ).unwrap()

        // If we got data back, use it instead
        if (
          response?.data?.transactions &&
          Array.isArray(response.data.transactions)
        ) {
          transactions = response.data.transactions
        }
      } catch (error) {
        console.error('Error fetching filtered transactions for export:', error)
        // Continue with whatever data we already have
      }
    }

    let totalIncome = 0

    const dataToExport = transactions.map(transaction => {
      const amount = transaction.amount || 0
      totalIncome += amount

      return {
        Date: new Date(transaction.transaction_date).toLocaleDateString(),
        'Transaction Receipt No.': transaction.receipt_no || 'N/A',
        'Customer Name':
          transaction.customer_id?.name ||
          (transaction.customer_info && transaction.customer_info[0]?.name) ||
          transaction.employee_id?.name ||
          transaction.user_id?.investorData?.name ||
          'N/A',

        'Society Name': activeSociety?.name || 'N/A',
        'Plot Number':
          transaction.plot_id?.plot_number ||
          (transaction.plot_info && transaction.plot_info[0]?.plot_number) ||
          'No Plot',
        'Transaction Type':
          transaction.transaction_type !== undefined &&
          transaction.transaction_type !== null
            ? transaction.transaction_type.toString()
            : 'N/A',
        'Transaction Direction': transaction.transaction_direction || 'N/A',
        'Transaction Method': transaction.payment_method || 'N/A',
        Income: amount,
        Status: transaction.status || 'N/A',
        Description: transaction.description || 'N/A'
      }
    })

    // Add totals row at the end
    dataToExport.push({
      Date: '',
      'Transaction Receipt No.': '',
      'Customer Name': '',
      'Society Name': '',
      'Plot Number': '',
      'Transaction Type': '',
      'Transaction Direction': 'Total',
      'Transaction Method': '',
      Income: totalIncome,
      Status: '',
      Description: ''
    })

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport)

    // Get the range of the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref'])

    // Create styles object
    const styles = {
      headerStyle: {
        fill: {
          fgColor: { rgb: 'D9D9D9' }
        },
        font: {
          bold: true,
          color: { rgb: '000000' }
        },
        alignment: {
          horizontal: 'center'
        }
      },
      alternateRowStyle: {
        fill: {
          fgColor: { rgb: 'F7F7F7' }
        }
      },
      incomeStyle: {
        font: {
          color: { rgb: '008000' } // Green color for income
        }
      },
      totalRowStyle: {
        fill: {
          fgColor: { rgb: 'FFF2CC' }
        },
        font: {
          bold: true,
          color: { rgb: '000000' }
        },
        alignment: {
          horizontal: 'center'
        }
      }
    }

    // Apply styles to the worksheet
    for (let R = 0; R <= range.e.r; R++) {
      for (let C = 0; C <= range.e.c; C++) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C })

        // Initialize cell if it doesn't exist
        if (!worksheet[cellRef]) {
          worksheet[cellRef] = { v: '', t: 's' }
        }

        // Add s (style) property if it doesn't exist
        if (!worksheet[cellRef].s) {
          worksheet[cellRef].s = {}
        }

        // Header row
        if (R === 0) {
          worksheet[cellRef].s = styles.headerStyle
        }
        // Alternate row shading
        else if (R % 2 === 0 && R < range.e.r) {
          worksheet[cellRef].s = styles.alternateRowStyle
        }
        // Total row
        else if (R === range.e.r) {
          worksheet[cellRef].s = styles.totalRowStyle
        }

        // Income column styling
        if (
          C === Object.keys(dataToExport[0]).indexOf('Income') &&
          worksheet[cellRef].v
        ) {
          worksheet[cellRef].s = {
            ...worksheet[cellRef].s,
            ...styles.incomeStyle
          }
        }
      }
    }

    // Set column widths
    worksheet['!cols'] = Object.keys(dataToExport[0]).map(() => ({ wpx: 150 }))

    // Create workbook and add worksheet
    const workbook = {
      SheetNames: ['Transactions'],
      Sheets: {
        Transactions: worksheet
      }
    }

    // Generate filename with date range info
    const societyName = activeSociety
      ? activeSociety.name.replace(/\s+/g, '_')
      : 'Society'

    // Add date range to filename
    let dateRangeText = ''
    if (period === 'day') {
      dateRangeText = `_today_${today.toISOString().slice(0, 10)}`
    } else if (period === 'week') {
      dateRangeText = `_last7days_${today.toISOString().slice(0, 10)}`
    } else if (period === 'month') {
      dateRangeText = `_${today.toLocaleString('default', {
        month: 'long'
      })}_${today.getFullYear()}`
    } else if (period === 'year') {
      dateRangeText = `_year_${today.getFullYear()}`
    }

    const fileName = `${societyName}_income_transactions${dateRangeText}.xlsx`

    // Write file using XLSX
    XLSX.writeFile(workbook, fileName)
  }

  const handleExportToExcel = () => {
    Swal.fire({
      title: `Export Income Transactions - ${activeSociety.name}`,
      html: `
  <div class="bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 p-6 rounded-xl">
    <p class="text-green-300 mb-4">Select the period for export:</p>
    <select 
      id="exportPeriod" 
      class="swal2-select bg-slate-800 text-green-300 border border-slate-700 rounded-md p-2 outline-none focus:ring-2 focus:ring-green-500 w-full mb-4">
      <option value="all" class="bg-slate-800 text-green-300">All Time</option>
      <option value="day" class="bg-slate-800 text-green-300">Today</option>
      <option value="week" class="bg-slate-800 text-green-300">Last 7 Days</option>
      <option value="month" class="bg-slate-800 text-green-300">Current Month</option>
      <option value="year" class="bg-slate-800 text-green-300">Current Year</option>
      <option value="custom" class="bg-slate-800 text-green-300">Custom Date Range</option>
    </select>
    
    <div id="customDateFields" style="display: none;">
      <div class="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label class="block text-green-300 text-sm mb-2">Start Date</label>
          <input 
            type="date" 
            id="customStartDate" 
            class="w-full px-4 py-2 rounded-lg text-green-400 bg-slate-700 border border-slate-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
        <div>
          <label class="block text-green-300 text-sm mb-2">End Date</label>
          <input 
            type="date" 
            id="customEndDate" 
            class="w-full px-4 py-2 rounded-lg text-green-400 bg-slate-700 border border-slate-600 focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>
      </div>
    </div>
    
    <p class="text-slate-400 mt-3 text-sm">* This will fetch fresh data based on your selected date range</p>
  </div>
  
  <script>
    document.getElementById('exportPeriod').addEventListener('change', function() {
      const customFields = document.getElementById('customDateFields');
      if (this.value === 'custom') {
        customFields.style.display = 'block';
      } else {
        customFields.style.display = 'none';
      }
    });
  </script>
`,
      confirmButtonText: 'Export',
      showCancelButton: true,
      focusConfirm: false,
      background: '#1E293B',
      customClass: {
        popup: 'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
        title: 'text-green-300',
        confirmButton:
          'bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg',
        cancelButton:
          'bg-slate-600 hover:bg-slate-700 text-white px-6 py-2 rounded-lg',
        content: 'bg-slate-800 p-6 rounded-lg'
      },
      didOpen: () => {
        // Initialize event listener after the modal is opened
        const exportPeriodElement = document.getElementById('exportPeriod')
        const customDateFields = document.getElementById('customDateFields')

        if (exportPeriodElement) {
          exportPeriodElement.addEventListener('change', function () {
            if (this.value === 'custom') {
              customDateFields.style.display = 'block'
            } else {
              customDateFields.style.display = 'none'
            }
          })
        }
      },
      preConfirm: () => {
        const exportPeriod = document.getElementById('exportPeriod').value

        // If custom date range is selected, validate and return both dates
        if (exportPeriod === 'custom') {
          const startDate = document.getElementById('customStartDate').value
          const endDate = document.getElementById('customEndDate').value

          if (!startDate || !endDate) {
            Swal.showValidationMessage('Please select both start and end dates')
            return false
          }

          // Validate that end date is not before start date
          if (new Date(endDate) < new Date(startDate)) {
            Swal.showValidationMessage('End date cannot be before start date')
            return false
          }

          return {
            period: 'custom',
            startDate,
            endDate
          }
        }

        return {
          period: exportPeriod
        }
      }
    }).then(result => {
      if (result.isConfirmed) {
        // Show loading state
        Swal.fire({
          title: 'Preparing Export',
          html: 'Gathering transaction data...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading()
          },
          background: '#1E293B',
          customClass: {
            popup:
              'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
            title: 'text-green-300'
          }
        })

        // Use setTimeout to allow the loading dialog to render
        setTimeout(() => {
          const exportData = result.value

          if (exportData.period === 'custom') {
            // Handle custom date range export
            exportTransactionsWithCustomDateRange(
              filteredTransactions,
              exportData.startDate,
              exportData.endDate
            )
              .then(() => {
                Swal.fire({
                  title: 'Export Complete',
                  text: 'Your export has been downloaded successfully.',
                  icon: 'success',
                  background: '#1E293B',
                  customClass: {
                    popup:
                      'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
                    title: 'text-green-300'
                  }
                })
              })
              .catch(error => {
                console.error('Export error:', error)
                Swal.fire({
                  title: 'Export Error',
                  text: 'There was an error exporting your data. Please try again.',
                  icon: 'error',
                  background: '#1E293B',
                  customClass: {
                    popup:
                      'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
                    title: 'text-green-300'
                  }
                })
              })
          } else {
            // Handle predefined period export
            exportTransactionsToExcel(filteredTransactions, exportData.period)
              .then(() => {
                Swal.fire({
                  title: 'Export Complete',
                  text: 'Your export has been downloaded successfully.',
                  icon: 'success',
                  background: '#1E293B',
                  customClass: {
                    popup:
                      'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
                    title: 'text-green-300'
                  }
                })
              })
              .catch(error => {
                console.error('Export error:', error)
                Swal.fire({
                  title: 'Export Error',
                  text: 'There was an error exporting your data. Please try again.',
                  icon: 'error',
                  background: '#1E293B',
                  customClass: {
                    popup:
                      'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
                    title: 'text-green-300'
                  }
                })
              })
          }
        }, 500)
      }
    })
  }

  // New function to handle custom date range exports
  const exportTransactionsWithCustomDateRange = async (
    transactions,
    startDate,
    endDate
  ) => {
    // Make sure transactions is an array
    if (!Array.isArray(transactions)) {
      Swal.fire('Error', 'No transactions data available for export.', 'error')
      return
    }

    // Format dates for API call
    const formattedStartDate = new Date(startDate)
    formattedStartDate.setHours(0, 0, 0, 0)

    const formattedEndDate = new Date(endDate)
    formattedEndDate.setHours(23, 59, 59, 999)

    try {
      // Fetch filtered data based on the custom date range
      const response = await dispatch(
        fetchTransactions({
          societyId: activeSociety._id,
          direction: 'Income',
          page: 1,
          limit: 1000, // Set a high limit to get all transactions for the period
          description: filters.description,
          startDate: formattedStartDate.toISOString(),
          endDate: formattedEndDate.toISOString(),
          sortOrder
        })
      ).unwrap()

      // If we got data back, use it instead
      if (
        response?.data?.transactions &&
        Array.isArray(response.data.transactions)
      ) {
        transactions = response.data.transactions
      }
    } catch (error) {
      console.error('Error fetching filtered transactions for export:', error)
      // Continue with whatever data we already have
    }

    let totalIncome = 0

    const dataToExport = transactions.map(transaction => {
      const amount = transaction.amount || 0
      totalIncome += amount

      return {
        Date: new Date(transaction.transaction_date).toLocaleDateString(),
        'Transaction Receipt No.': transaction.receipt_no || 'N/A',
        'Customer Name':
          transaction.customer_id?.name ||
          transaction.employee_id?.name ||
          transaction.user_id?.investorData?.name ||
          'N/A',
        'Society Name': activeSociety?.name || 'N/A',
        'Plot Number': transaction.plot_id?.plot_number || 'No Plot',
        'Transaction Type':
          transaction.transaction_type !== undefined &&
          transaction.transaction_type !== null
            ? transaction.transaction_type.toString()
            : 'N/A',
        'Transaction Direction': transaction.transaction_direction || 'N/A',
        'Transaction Method': transaction.payment_method || 'N/A',
        Income: amount,
        Status: transaction.status || 'N/A',
        Description: transaction.description || 'N/A'
      }
    })

    // Add totals row at the end
    dataToExport.push({
      Date: '',
      'Transaction Receipt No.': '',
      'Customer Name': '',
      'Society Name': '',
      'Plot Number': '',
      'Transaction Type': '',
      'Transaction Direction': 'Total',
      'Transaction Method': '',
      Income: totalIncome,
      Status: '',
      Description: ''
    })

    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport)

    // Get the range of the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref'])

    // Create styles object
    const styles = {
      headerStyle: {
        fill: {
          fgColor: { rgb: 'D9D9D9' }
        },
        font: {
          bold: true,
          color: { rgb: '000000' }
        },
        alignment: {
          horizontal: 'center'
        }
      },
      alternateRowStyle: {
        fill: {
          fgColor: { rgb: 'F7F7F7' }
        }
      },
      incomeStyle: {
        font: {
          color: { rgb: '008000' } // Green color for income
        }
      },
      totalRowStyle: {
        fill: {
          fgColor: { rgb: 'FFF2CC' }
        },
        font: {
          bold: true,
          color: { rgb: '000000' }
        },
        alignment: {
          horizontal: 'center'
        }
      }
    }

    // Apply styles to the worksheet
    for (let R = 0; R <= range.e.r; R++) {
      for (let C = 0; C <= range.e.c; C++) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C })

        // Initialize cell if it doesn't exist
        if (!worksheet[cellRef]) {
          worksheet[cellRef] = { v: '', t: 's' }
        }

        // Add s (style) property if it doesn't exist
        if (!worksheet[cellRef].s) {
          worksheet[cellRef].s = {}
        }

        // Header row
        if (R === 0) {
          worksheet[cellRef].s = styles.headerStyle
        }
        // Alternate row shading
        else if (R % 2 === 0 && R < range.e.r) {
          worksheet[cellRef].s = styles.alternateRowStyle
        }
        // Total row
        else if (R === range.e.r) {
          worksheet[cellRef].s = styles.totalRowStyle
        }

        // Income column styling
        if (
          C === Object.keys(dataToExport[0]).indexOf('Income') &&
          worksheet[cellRef].v
        ) {
          worksheet[cellRef].s = {
            ...worksheet[cellRef].s,
            ...styles.incomeStyle
          }
        }
      }
    }

    // Set column widths
    worksheet['!cols'] = Object.keys(dataToExport[0]).map(() => ({ wpx: 150 }))

    // Create workbook and add worksheet
    const workbook = {
      SheetNames: ['Transactions'],
      Sheets: {
        Transactions: worksheet
      }
    }

    // Generate filename with date range info
    const societyName = activeSociety
      ? activeSociety.name.replace(/\s+/g, '_')
      : 'Society'

    // Format dates for filename
    const startDateFormatted = new Date(startDate).toISOString().slice(0, 10)
    const endDateFormatted = new Date(endDate).toISOString().slice(0, 10)

    const fileName = `${societyName}_income_transactions_${startDateFormatted}_to_${endDateFormatted}.xlsx`

    // Write file using XLSX
    XLSX.writeFile(workbook, fileName)
  }

  const validateManualDateInput = inputValue => {
    const dateParts = inputValue.split('-')
    if (dateParts.length === 2) {
      try {
        const startDateStr = dateParts[0].trim()
        const endDateStr = dateParts[1].trim()

        const startDate = new Date(startDateStr)
        const endDate = new Date(endDateStr)

        if (!isNaN(startDate) && !isNaN(endDate)) {
          // Update the date range
          setDateRange([startDate, endDate])

          // Update filters
          setFilters(prevFilters => ({
            ...prevFilters,
            startDate: startDate,
            endDate: endDate
          }))

          return true
        }
      } catch (error) {
        console.error('Date validation error:', error)
      }
    }

    // If invalid, revert to the current valid date range display
    if (dateRange[0] && dateRange[1]) {
      setDisplayDateText(
        `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`
      )
    } else {
      setDisplayDateText('')
    }
    return false
  }

  const handleDateRangeChange = update => {
    setDateRange(update)
    if (update[0] && update[1]) {
      setFilters(prevFilters => ({
        ...prevFilters,
        startDate: update[0],
        endDate: update[1]
      }))

      // Update the display text
      setDisplayDateText(
        `${update[0].toLocaleDateString()} - ${update[1].toLocaleDateString()}`
      )

      setTimeout(() => setDatePickerOpen(false), 500)
    }
  }

  // Update your clearDateRange function
  const clearDateRange = () => {
    setDateRange([null, null])
    setDisplayDateText('')
    setFilters(prevFilters => ({
      ...prevFilters,
      startDate: null,
      endDate: null
    }))
  }

  // Helper function to get all dates in range for highlighting
  const getDatesInRange = (startDate, endDate) => {
    const dates = []
    let currentDate = new Date(startDate)

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return dates
  }

  // Function to apply preset date ranges
  const applyPresetRange = presetType => {
    const today = new Date()
    let start = new Date()
    let end = new Date()

    switch (presetType) {
      case 'last7Days':
        start.setDate(today.getDate() - 7)
        end = today
        break
      case 'last30Days':
        start.setDate(today.getDate() - 30)
        end = today
        break
      case 'thisMonth':
        start = new Date(today.getFullYear(), today.getMonth(), 1)
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        break
      case 'lastMonth':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        end = new Date(today.getFullYear(), today.getMonth(), 0)
        break
      default:
        return
    }

    setDateRange([start, end])
    setDisplayDateText(
      `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
    )

    setFilters(prevFilters => ({
      ...prevFilters,
      startDate: start,
      endDate: end
    }))
  }

  const clearFilters = () => {
    setFilters({
      name: '',
      description: '',
      transactionType: '',
      transactionDirection: '',
      amount: '',
      startDate: null,
      endDate: null
    })
    setDateRange([null, null])
  }

  return (
    <div className=' bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-2xl shadow-[0_8px_32px_0_rgba(0,0,0,0.36)] p-4 md:p-8'>
      <div className='max-w-7xl mx-auto space-y-6'>
        {/* Header */}
        <div className='flex items-center justify-between'>
          <div>
            {activeSociety && (
              <div className='flex items-center gap-3 mt-2'>
                <div className='w-16 h-16 rounded-full overflow-hidden border-2 border-teal-400'>
                  <img
                    src={
                      activeSociety.society_image || '/api/placeholder/40/40'
                    }
                    alt={activeSociety.name}
                    className='w-full h-full object-cover'
                    onError={e => {
                      e.target.src = '/api/placeholder/40/40'
                    }}
                  />
                </div>
                <div className='text-left'>
                  <p className='text-3xl text-teal-400 font-bold'>
                    {activeSociety.name}
                  </p>
                  <h2 className='text-2xl md:text-3xl font-bold bg-gradient-to-r from-teal-300 to-teal-500 bg-clip-text text-transparent'>
                    Financial Sales Analysis
                  </h2>
                </div>
              </div>
            )}
          </div>
          {/* {loading && (
              <div className="text-sm text-gray-400 animate-pulse">
                Loading data...
              </div>
            )} */}
          <select
            value={dateRange}
            onChange={e => setDateRange(e.target.value)}
            className='ml-4 bg-slate-800 text-gray-400 border border-slate-700 p-2 rounded'
          >
            <option>All Dates</option>
            <option>Last Year</option>
            <option>Last 6 Months</option>
            <option>Last 3 Months</option>
            <option>Last Month</option>
            <option>Last Day</option>
            <option>Today</option>
          </select>
        </div>
        {/* Total Income Card */}
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 items-center'>
          <div className='bg-slate-800/50 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700'>
            <div className='p-6'>
              <div className='flex items-center gap-4'>
                <div className='p-3 bg-gradient-to-r from-teal-400/10 to-teal-400/10 rounded-full'>
                  <div className='w-6 h-6 text-teal-400'>📊</div>
                </div>
                <div>
                  <p className='text-sm font-medium text-gray-400'>
                    Total Income
                  </p>
                  <p className='text-xl font-bold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent'>
                    {totalIncome.toLocaleString()} PKR
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-slate-800 rounded-xl p-6 shadow-lg border border-slate-700'>
          {/* Main filters always visible */}
          <div className='flex flex-col space-y-6'>
            <div className='flex items-center justify-between'>
              <h2 className='text-xl font-bold text-white flex items-center gap-2'>
                <SlidersHorizontal className='w-5 h-5 text-teal-400' />
                Transaction Filters
              </h2>

              <div className='flex items-center gap-3'>
                {Object.values(filters).some(val => val && val !== '') && (
                  <button
                    onClick={clearFilters}
                    className='flex items-center gap-1 text-sm text-teal-400 hover:text-teal-300 transition-colors'
                  >
                    <X className='w-4 h-4' />
                    Clear All
                  </button>
                )}
              </div>
            </div>

            {/* Quick search and date selector always visible */}
            <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
              <div className='relative col-span-1'>
                <Search className='absolute left-3 top-1/2 transform -translate-y-1/2 text-teal-400 w-5 h-5' />
                <input
                  type='text'
                  name='description'
                  placeholder='Search by description...'
                  value={filters.description}
                  onChange={e =>
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      description: e.target.value
                    }))
                  }
                  className='w-full pl-10 pr-4 py-3 rounded-xl text-white bg-slate-700 border border-slate-600 focus:border-teal-400 focus:ring-2 focus:ring-teal-400/20 focus:outline-none transition-all duration-300 placeholder-slate-400'
                />
              </div>

              <div className='relative col-span-1'>
                <EnhancedDateFilter
                  onDateRangeChange={dateRange => {
                    setDateRange(dateRange)
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      startDate: dateRange[0],
                      endDate: dateRange[1]
                    }))
                  }}
                  initialStartDate={filters.startDate}
                  initialEndDate={filters.endDate}
                />
              </div>

              <button
                onClick={handleSortOrderChange}
                className='flex items-center justify-center gap-2 px-4 py-3 rounded-xl text-white bg-slate-700 border border-slate-600 hover:bg-slate-600 hover:border-teal-400 transition-all duration-300'
              >
                <ArrowUpDown
                  className={`w-5 h-5 text-teal-400 ${
                    sortOrder === 'desc' ? 'rotate-180' : ''
                  }`}
                />
                Sort {sortOrder === 'asc' ? 'Newest First' : 'Oldest First'}
              </button>
            </div>
          </div>
        </div>
        {(filters.name ||
          filters.description ||
          filters.transactionType ||
          filters.transactionDirection ||
          filters.amount ||
          filters.date) && (
          <div className='bg-gradient-to-br from-teal-700 to-teal-900 rounded-xl shadow-lg border border-teal-600 p-6 mt-4'>
            <h3 className='text-2xl font-bold text-teal-200'>
              Total for Filtered Results
            </h3>
            <p className='text-4xl font-bold text-white mt-2'>
              {totalFilteredAmounts.toLocaleString()} PKR
            </p>
          </div>
        )}
        {/* Transactions Table */}
        <div className='bg-slate-800/50 backdrop-blur-sm rounded-xl shadow-lg border border-slate-700 overflow-hidden'>
          <div className='p-6 border-b border-slate-700 flex justify-between items-center'>
            <h3 className='text-3xl font-semibold bg-gradient-to-r from-teal-300 to-teal-400 bg-clip-text text-transparent'>
              Expense Transactions
            </h3>
            <button
              onClick={handleExportToExcel}
              className='px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-600 transition'
            >
              Export to Excel
            </button>
          </div>

          <div className='overflow-x-auto'>
            <table className='w-full bg-slate-800 rounded-lg text-teal-300'>
              <thead>
                <tr className='bg-slate-900 rounded-lg text-teal-300'>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Name
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Societies
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Transaction Type
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Transaction Direction
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Amount
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Date
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Status
                  </th>
                  <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                    Description
                  </th>
                  {!isEmployee && (
                    <th className='px-6 py-3 text-left text-xs font-medium text-teal-400 uppercase tracking-wider'>
                      Actions
                    </th>
                  )}
                </tr>
              </thead>

              <tbody className='divide-y divide-slate-700'>
                {currentTransactions.length > 0 ? (
                  currentTransactions.map(transaction => (
                    <tr
                      key={transaction._id}
                      className={`transition-colors duration-300 ${getTransactionTypeColor(
                        transaction.transaction_type
                      )}`}
                    >
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.transaction_type === 'Salary Payment' ? (
                          <>
                            Employee
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.employee_id?.name || 'No Employee'}
                            </span>
                          </>
                        ) : transaction.transaction_type ===
                          'Expense Payment' ? (
                          <>
                            Investor
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.user_id?.investorData?.name ||
                                'No Investor'}
                            </span>
                          </>
                        ) : (
                          <>
                            Customer
                            <br />
                            <span className='text-md font-semibold'>
                              {transaction.customer_id?.name ||
                                (transaction.customer_info &&
                                  transaction.customer_info[0]?.name) ||
                                'No Customer'}
                            </span>
                          </>
                        )}
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.transaction_type === 'Salary Payment'
                          ? transaction.employee_id?.society?.name ||
                            'No Society'
                          : transaction.transaction_type ===
                              'Expense Payment' &&
                            transaction.user_id?.investorData?.societies
                              ?.length > 0
                          ? transaction.user_id.investorData.societies
                              .map(society => society.name)
                              .join(', ')
                          : transaction.transaction_type === 'Expense Payment'
                          ? 'No Society'
                          : transaction.societies?.length > 0
                          ? transaction.societies
                              .map(society => society.name)
                              .join(', ')
                          : 'No Society'}
                        <br />
                        {transaction.plot_id?.plot_number ||
                        (transaction.plot_info &&
                          transaction.plot_info[0]?.plot_number) ? (
                          <span>
                            Plot No:{' '}
                            {transaction.plot_id?.plot_number ||
                              transaction.plot_info[0]?.plot_number}
                          </span>
                        ) : null}

                        {transaction.booking_info &&
                          transaction.booking_info[0]?.offertag && (
                            <div className='relative -bottom-1 -right-0'>
                              <div className='bg-red-600 text-white text-xs font-semibold px-3 py-1 rounded-full shadow-lg flex items-center space-x-1'>
                                <span>
                                  {transaction.booking_info[0].offertag}
                                </span>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  className='h-3 w-3'
                                  viewBox='0 0 20 20'
                                  fill='currentColor'
                                >
                                  <path
                                    fillRule='evenodd'
                                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z'
                                    clipRule='evenodd'
                                  />
                                </svg>
                              </div>
                            </div>
                          )}
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.transaction_type}
                        <br />
                        <div className='text-teal-400 mt-1'>
                          {transaction.receipt_no ? (
                            <div className='text-teal-400 mt-1'>
                              Receipt No: {transaction.receipt_no}
                            </div>
                          ) : null}
                        </div>
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.transaction_direction || 'N/A'}
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.amount?.toLocaleString() || '0'} PKR
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {new Date(
                          transaction.transaction_date
                        ).toLocaleDateString('en-GB')}
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.status || 'N/A'}
                      </td>
                      <td className='border-b px-6 py-4 text-sm text-teal-300'>
                        {transaction.description || 'No Description'}
                      </td>
                      {!isEmployee && (
                        <td className='border-b px-6 py-4 text-sm text-teal-300'>
                          <div className='flex items-center gap-2'>
                            {/* Edit Icon */}
                            <button
                              onClick={() => handleEdit(transaction)}
                              className='p-2 rounded-xl bg-teal-300/10 hover:bg-teal-300/20 text-teal-300 transition-colors duration-300'
                              title='Edit'
                            >
                              <Edit className='w-5 h-5' />
                            </button>

                            {/* Delete Icon */}
                            <button
                              onClick={() => handleDelete(transaction._id)}
                              className='p-2 rounded-xl bg-red-400/10 hover:bg-red-400/20 text-red-400 transition-colors duration-300'
                              title='Delete'
                            >
                              <Trash2 className='w-5 h-5' />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={isEmployee ? 8 : 9}
                      className='text-center py-6 text-gray-400'
                    >
                      No transactions available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='flex space-x-2'></div>
        </div>
        <div className='flex justify-center items-center gap-2 sm:gap-4 mt-4 mb-5'>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`
          flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg
          transition-all duration-300
          ${
            currentPage === 1
              ? 'bg-slate-600 cursor-not-allowed'
              : 'bg-slate-700 text-teal-300 hover:bg-slate-600'
          }
        `}
          >
            <ChevronLeft
              className={`w-4 h-4 ${
                currentPage === 1 ? 'text-slate-400' : 'text-teal-300'
              }`}
            />
          </button>

          <div className='flex items-center gap-2'>
            {currentPage > 3 &&
              Math.ceil(filteredTransactions.length / plotsPerPage) > 5 && (
                <>
                  <button
                    onClick={() => setCurrentPage(1)}
                    className='flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300'
                  >
                    1
                  </button>
                  <span className='text-teal-300'>...</span>
                </>
              )}

            {renderPageNumbers()}

            {currentPage <
              Math.ceil(filteredTransactions.length / plotsPerPage) - 2 &&
              Math.ceil(filteredTransactions.length / plotsPerPage) > 5 && (
                <>
                  <span className='text-teal-300'>...</span>
                  <button
                    onClick={() =>
                      setCurrentPage(
                        Math.ceil(filteredTransactions.length / plotsPerPage)
                      )
                    }
                    className='flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg text-sm font-medium bg-slate-700 text-teal-300 hover:bg-slate-600 transition-all duration-300'
                  >
                    {Math.ceil(filteredTransactions.length / plotsPerPage)}
                  </button>
                </>
              )}
          </div>

          <button
            onClick={handleNextPage}
            disabled={
              currentPage ===
              Math.ceil(filteredTransactions.length / plotsPerPage)
            }
            className={`
          flex items-center justify-center h-8 w-8 sm:h-10 sm:w-10 rounded-lg
          transition-all duration-300
          ${
            currentPage ===
            Math.ceil(filteredTransactions.length / plotsPerPage)
              ? 'bg-slate-600 cursor-not-allowed'
              : 'bg-slate-700 text-teal-300 hover:bg-slate-600'
          }
        `}
          >
            <ChevronRight
              className={`w-4 h-4 ${
                currentPage ===
                Math.ceil(filteredTransactions.length / plotsPerPage)
                  ? 'text-slate-400'
                  : 'text-teal-300'
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default IncomeDirection
