import React, { PureComponent, useEffect, useRef, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import { PieChartIcon } from "lucide-react";
import { useSidebarContext } from "../scenes/global/sidebar/SidebarContext";

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  // Base calculations for line start
  const outerRadiusOffset = Math.min(cx, cy) * 0.1;
  const sx = cx + (outerRadius + outerRadiusOffset) * cos;
  const sy = cy + (outerRadius + outerRadiusOffset) * sin;

  // Determine if it's the "Expense" or "Net Income" segment
  const isExpense = payload.name === "Expense";

  // Calculate step line points
  const verticalDistance = 70; // Distance for vertical line
  const horizontalOffset = isExpense ? 110 : -100; // Distance for horizontal line

  // Calculate end points
  const mx = sx; // Middle x point (vertical line)
  const my = cy + outerRadius + verticalDistance; // Middle y point (bottom of vertical line)
  const ex = cx + horizontalOffset; // End x point
  const ey = my; // End y point (same as middle y)

  // Text positioning
  const textX = ex;
  const textY = ey + 20; // Position text below the line
  const textAnchor = isExpense ? "end" : "start";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={fill}
        className="text-base md:text-lg font-semibold"
      >
        {payload.name}
      </text>

      {/* Step line with right angles */}
      <path
        d={`
          M ${sx},${sy}
          L ${mx},${my}
          L ${ex},${ey}
        `}
        stroke={fill}
        strokeWidth={2}
        fill="none"
      />

      {/* End point circle */}
      <circle cx={ex} cy={ey} r={3} fill={fill} stroke="none" />

      {/* Value text */}
      <text
        x={textX}
        y={textY}
        textAnchor={textAnchor}
        fill={fill}
        className="text-xs md:text-sm"
      >
        {`Value: PKR ${value.toLocaleString()}`}
      </text>

      {/* Percentage text */}
      <text
        x={textX}
        y={textY + 20}
        textAnchor={textAnchor}
        fill={fill}
        className="text-xs md:text-sm"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const RevenuePieChart = ({ aggregationData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const { gradient, theme } = useSidebarContext();

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const totalIncome = aggregationData?.totalIncome || 0;
  const totalExpense = aggregationData?.totalExpense || 0;
  const netIncome = Math.max(totalIncome - totalExpense, 0);

  const data = [
    { name: "Expense", value: totalExpense },
    { name: "Net Income", value: netIncome },
  ];

  const minDimension = Math.min(dimensions.width, dimensions.height);
  const innerRadius = Math.max(minDimension * 0.2, 60);
  const outerRadius = Math.max(minDimension * 0.3, 80);

  return (
    <div className={`${gradient} p-4 md:p-6 rounded-xl shadow-xl`}>
      <div className="flex items-center gap-2 md:gap-3 mb-4 md:mb-8">
        <PieChartIcon
          className={`${theme?.tailwind?.text || "text-teal-300"} w-6 h-6 md:w-8 md:h-8`}
        />
        <div>
          <h2
            className={`${theme?.tailwind?.text || "text-teal-300"} text-xl md:text-2xl font-bold`}
          >
            Financial Overview
          </h2>
          <p
            className={`${theme?.tailwind?.text4 || "text-teal-300/80"} text-xs md:text-sm`}
          >
            Interactive visualization of revenue metrics
          </p>
        </div>
      </div>
      <div
        ref={containerRef}
        className={`${theme?.tailwind?.bg2 || "bg-slate-800/30"} rounded-lg p-2 h-[450px] md:h-[450px]`}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={(props) => renderActiveShape({ ...props, fill: theme?.mui?.main || "#4FD1C5" })}
              data={data}
              cx="50%"
              cy="40%"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill={theme?.mui?.main || "#4FD1C5"}
              dataKey="value"
              onMouseEnter={(_, index) => setActiveIndex(index)}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RevenuePieChart;