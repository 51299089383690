import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useMemo
} from 'react'
import { useSelector } from 'react-redux'
import { ProSidebarProvider } from 'react-pro-sidebar'
import MyProSidebar from './MyProSidebar'

const SidebarContext = createContext(null)
const ACTIVE_SOCIETY_KEY = 'activeSociety'

// Background gradients for each active society
const gradientBackgrounds = [
  'bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800',
  'bg-gradient-to-br from-slate-500 via-slate-600 to-slate-500',
  'bg-gradient-to-br from-red-950 via-red-850 to-red-950'
]

const themeColors = [
  {
    tailwind: {
      // User management
      text: 'text-teal-400',
      text1: 'text-teal-400/60',
      text2: 'text-teal-300/50',
      text3: 'text-teal-600',
      text4: 'text-teal-300/70',
      text5: 'bg-gradient-to-r from-teal-500 to-cyan-500',
      text6: 'bg-gradient-to-r from-teal-300 to-cyan-300',
      text7: 'text-teal-300/30',
      text8: 'bg-gradient-to-r from-teal-400 to-teal-600',
      text9: 'text-teal-300',
      text10: 'text-teal-500',
      text11: 'text-teal-300/80',
      text12: 'text-teal-400/70',

      border: 'border-teal-400',
      border1: 'border-teal-300/30',
      border2: 'border-slate-700/50',
      border3: 'border-slate-700/30',
      border4: 'border-slate-700/30',
      border5: 'border-teal-600/50',
      border6: 'border-teal-500',
      border7: 'border-teal-700',
      border8: 'border-teal-600',
      border9: 'border-teal-800',
      border10: 'border-teal-200',
      border11: 'border-teal-500/30',
      border12: 'border-teal-500/10',
      border13: 'border-slate-600/50',
      border14: 'border-teal-400/10',
      border15: 'border-teal-500/20',
      border16: 'border-teal-400/50',
      border17: 'border-teal-800/30',
      border18: 'border-gray-700/30',

      bg1: 'bg-teal-500/20',
      bg2: 'bg-slate-800/50',
      bg3: 'bg-slate-700/50',
      bg4: 'bg-gradient-to-br from-slate-800/90 to-slate-900/90',
      bg5: 'bg-slate-700',
      bg6: 'bg-slate-900/60',
      bg7: 'bg-gradient-to-br from-teal-500/10 via-transparent to-sky-500/10',
      bg8: 'bg-gradient-to-br from-slate-800 to-slate-900',
      bg9: 'bg-gradient-to-br from-slate-800/80 via-slate-900/80 to-slate-800/80',
      bg10: 'bg-teal-600/10',
      bg11: 'bg-teal-300/10',
      bg12: 'bg-gray-800',
      bg13: 'bg-teal-500',
      bg14: 'bg-slate-800',
      bg15: 'bg-slate-800/60',
      bg16: 'bg-slate-900',
      bg17: 'bg-slate-700/30',
      bg18: 'bg-teal-600',
      bg19: 'bg-gray-900/40',
      bg20: 'bg-gray-900/50',
      bg21: 'bg-teal-900/30',
      bg22: 'bg-gray-700/30',
      bg23: 'bg-gray-800/40',
      bg24: 'bg-slate-900/60',
      bg25: 'bg-teal-500/10',
      bg26: 'bg-gray-700',
      bg27: 'bg-slate-800/95',

      hover: 'hover:text-teal-500',
      hover1: 'hover:text-teal-300',
      hoverB: 'hover:border-teal-500/30',
      bgHover: 'hover:bg-slate-700/70',
      bgHover1: 'hover:bg-slate-800/60',
      bgHover2: 'hover:bg-slate-800/30',
      bgHover3: 'hover:bg-teal-600/20',
      bgHover4: 'hover:bg-teal-900/20',
      bgHover5: 'hover:bg-teal-600',
      bgHover6: 'hover:bg-teal-400',
      bgHover7: 'hover:bg-teal-500/10',
      bgHover8: 'hover:bg-teal-500/5',
      bgHover9: 'hover:bg-teal-500/40',
      bgHover10: 'hover:bg-slate-600/50',
      bgHover11: 'hover:bg-teal-700',
      bgHover12: 'hover:bg-gray-800/40',
      bgHover13: 'hover:bg-teal-500/20',
      bgHover14: 'hover:bg-slate-800',

      textColor: 'bg-gradient-to-r from-teal-300 via-teal-400 to-teal-300',
      textColor1: 'bg-gradient-to-br from-slate-700/50 to-slate-800/50',

      groupHover: 'group-hover:border-teal-400/60',
      groupHover1: 'group-hover:border-teal-500/30',
      groupHover3: 'group-hover:text-teal-200',
      groupHover4: 'group-hover:text-teal-200/80',
      groupHover5: 'group-hover:text-teal-200/80',
      groupHover6: 'group-hover:text-emerald-200/90',
      groupHover7: 'group-hover:text-teal-300',
      groupHoverG: 'group-hover:from-teal-200',
      groupHoverG1: 'group-hover:via-cyan-200',
      groupHoverG2: 'group-hover:to-teal-200',

      focus: 'focus:border-teal-300',
      focus1: 'focus:border-teal-300/50',
      focus2: 'focus:border-teal-500/50',
      focus3: 'focus:border-teal-500',
      focusRing: 'focus:ring-teal-300/50',
      focusRing1: 'focus:ring-teal-500/50',
      focusRing2: 'focus:ring-teal-500',
      texting: 'text-slate-200',
      texting1: 'text-slate-300',

      bgGradient:
        'bg-gradient-to-br from-slate-900/90 via-slate-950/95 to-slate-900/90',
      bgGradient1:
        'bg-gradient-to-tr from-emerald-500/10 via-transparent to-sky-500/10',
      bgGradient2:
        'bg-[radial-gradient(circle_at_50%_50%,rgba(16,185,129,0.12),transparent_70%)]',
      bgGradient3: 'bg-emerald-400/50',
      bgGradient4: 'bg-teal-400/30',
      bgGradient5: 'bg-sky-400/20',
      bgGradient6: 'bg-gradient-to-br from-teal-900/10 to-gray-800/20',

      bgr: 'bg-gradient-to-r from-teal-300 via-teal-200 to-cyan-200',
      bgr1: 'bg-gradient-to-r from-transparent via-emerald-500/30 to-transparent',
      bgr2: 'bg-gradient-to-r from-slate-800 to-slate-900',
      bgr3: 'bg-gradient-to-r from-teal-500/10 to-slate-900/10',
      bgr4: 'bg-gradient-to-r from-teal-300 to-teal-400',

      active: 'active:text-teal-600',
      placeHolder: 'placeholder-teal-300/50',
      placeHolder1: 'placeholder-teal-500/50',
      placeHolder2: 'placeholder-teal-300/30',
      ringColor: 'ring-teal-300/50',
      ringColor1: 'ring-teal-500',
      ringColor2: 'ring-teal-400/30',
      divide: 'divide-slate-700/30',
      groupDisabled: 'text-teal-300/50',
      ringHover: 'hover:ring-teal-300/50',
      bgFocus: 'focus:bg-slate-600',
      shadow: 'shadow-slate-900/20',
      shadowing: 'shadow-teal-500/30'
    },
    mui: {
      main: '#2DD4BF',
      hover: '#14B8A6',
      active: '#0D9488',
      border: '#2DD4BF',
      border1: 'rgba(45, 212, 191, 0.2)',
      fill: 'rgba(45, 212, 191, 0.1)',
      cart: '#475569',
      light: '#5EEAD4',
      bg2: 'rgba(26, 31, 46, 0.8), rgba(26, 31, 46, 0.8)'
    }
  },
  {
    tailwind: {
      // User management
      text: 'text-yellow-400',
      text1: 'text-yellow-400/60',
      text2: 'text-yellow-300/50',
      text3: 'text-yellow-600',
      text4: 'text-yellow-300/90',
      text5: 'bg-gradient-to-r from-yellow-500 to-amber-500',
      text6: 'bg-gradient-to-r from-yellow-300 to-amber-300',
      text7: 'text-yellow-400/90',
      text8: 'bg-gradient-to-r from-yellow-300 to-yellow-500',
      text9: 'text-yellow-300',
      text10: 'text-yellow-500',
      text11: 'text-yellow-300/80',
      text12: 'text-yellow-400/70',

      textColor:
        'bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-400',
      textColor1: 'bg-gradient-to-br from-slate-400/50 to-slate-500/50',

      border: 'border-yellow-400',
      border1: 'border-yellow-400/30',
      border2: 'border-yellow-500/50',
      border3: 'border-slate-400/30',
      border4: 'border-slate-300/30',
      border5: 'border-yellow-600/50',
      border6: 'border-yellow-500',
      border7: 'border-yellow-400',
      border8: 'border-yellow-600',
      border9: 'border-yellow-500',
      border10: 'border-yellow-200',
      border11: 'border-yellow-500/30',
      border12: 'border-yellow-500/10',
      border13: 'border-gray-600/50',
      border14: 'border-yellow-400/10',
      border15: 'border-yellow-500/20',
      border16: 'border-yellow-400/50',
      border17: 'border-yellow-800/30',
      border18: 'border-gray-300/30',
      hover: 'hover:text-yellow-500',
      hover1: 'hover:text-yellow-300',
      hoverB: 'hover:border-yellow-500/30',
      bgHover: 'hover:bg-slate-300/70',
      bgHover1: 'hover:bg-slate-400/60',
      bgHover2: 'hover:bg-slate-400/30',
      bgHover3: 'hover:bg-yellow-400/20',
      bgHover4: 'hover:bg-yellow-900/20',
      bgHover5: 'hover:bg-yellow-600',
      bgHover6: 'hover:bg-yellow-400',
      bgHover7: 'hover:bg-yellow-500/10',
      bgHover8: 'hover:bg-yellow-500/5',
      bgHover9: 'hover:bg-yellow-500/40',
      bgHover10: 'hover:bg-slate-200/50',
      bgHover11: 'hover:bg-yellow-700',
      bgHover12: 'hover:bg-gray-400/40',
      bgHover13: 'hover:bg-yellow-500/20',
      bgHover14: 'hover:bg-slate-400',

      groupHover: 'group-hover:border-yellow-400/60',
      groupHover1: 'group-hover:border-yellow-500/30',
      groupHover2: 'group-hover:border-emerald-500/20',
      groupHover3: 'group-hover:text-yellow-200',
      groupHover4: 'group-hover:text-yellow-200/80',
      groupHover5: 'group-hover:text-yellow-200/80',
      groupHover6: 'group-hover:text-amber-200/90',
      groupHover7: 'group-hover:text-yellow-300',
      groupHoverG: 'group-hover:from-yellow-200',
      groupHoverG1: 'group-hover:via-amber-200',
      groupHoverG2: 'group-hover:to-yellow-200',

      focus: 'focus:border-yellow-400',
      focus1: 'focus:border-yellow-400/50',
      focus2: 'focus:border-yellow-500/50 ',
      focusRing: 'focus:ring-yellow-300/50',
      focusRing1: 'focus:ring-yellow-500/50',
      focusRing2: 'focus:ring-yellow-500',
      bgFocus: 'focus:bg-slate-300',

      texting: 'text-slate-100',
      texting1: 'text-slate-200',

      bg1: 'bg-yellow-500/20',
      bg2: 'bg-slate-500/50',
      bg3: 'bg-slate-300/50',
      bg4: 'bg-gradient-to-br from-gray-400/90 to-gray-500/90',
      bg5: 'bg-gray-500',
      bg6: 'bg-slate-500/60',
      bg7: 'bg-gradient-to-br from-yellow-500/10 via-transparent to-amber-500/10',
      bg8: 'bg-gradient-to-br from-slate-300 to-slate-400',
      bg9: 'bg-gradient-to-br from-slate-400/80 via-slate-500/80 to-slate-400/80',
      bg10: 'bg-yellow-400/10',
      bg11: 'bg-yellow-300/10',
      bg12: 'bg-gray-400',
      bg13: 'bg-yellow-500',
      bg14: 'bg-slate-400',
      bg15: 'bg-slate-400/60',
      bg16: 'bg-slate-500',
      bg17: 'bg-slate-300/30',
      bg18: 'bg-yellow-600',
      bg19: 'bg-gray-600/80',
      bg20: 'bg-gray-400/50',
      bg21: 'bg-yellow-700/30',
      bg22: 'bg-gray-300/30',
      bg23: 'bg-gray-400/40',
      bg24: 'bg-slate-500/60',
      bg25: 'bg-yellow-500/10',
      bg26: 'bg-gray-400',
      bg27: 'bg-slate-500/95',

      shadow: 'shadow-slate-400/20',
      shadowhover: 'group-hover:shadow-emerald-500/10',
      shadowhover1: 'hover:shadow-teal-500/20',

      bgGradient:
        'bg-gradient-to-br from-slate-500/90 via-slate-550/95 to-slate-500/90',
      bgGradient1:
        'bg-gradient-to-tr from-amber-500/10 via-transparent to-lime-500/10',
      bgGradient2:
        'bg-[radial-gradient(circle_at_50%_50%,rgba(255,223,0,0.12),transparent_70%)]',
      bgGradient3: 'bg-amber-400/50',
      bgGradient4: 'bg-yellow-400/30',
      bgGradient5: 'bg-lime-400/20',
      bgGradient6: 'bg-gradient-to-br from-yellow-900/10 to-gray-400/20',

      bgr: 'bg-gradient-to-r from-yellow-300 via-yellow-200 to-amber-200',
      bgr1: 'bg-gradient-to-r from-transparent via-amber-500/30 to-transparent',
      bgr2: 'bg-gradient-to-r from-slate-400 to-slate-500',
      bgr3: 'bg-gradient-to-r from-yellow-500/10 to-slate-400/10',
      bgr4: 'bg-gradient-to-r from-yellow-300 to-yellow-400',

      ringHover: 'hover:ring-yellow-300/50',
      placeHolder: 'placeholder-yellow-400/50',
      placeHolder1: 'placeholder-yellow-300/50',
      placeHolder2: 'placeholder-yellow-300/30',
      ringColor: 'ring-yellow-300/50',
      ringColor1: 'ring-yellow-500',
      ringColor2: 'ring-yellow-400/30',
      divide: 'divide-slate-400/30',
      groupDisabled: 'text-yellow-300/50',
      drop_shadow: 'drop-shadow-[0_0_10px_rgba(13,148,136,0.6)]',
      shadows: 'shadow-[0_8px_32px_0_rgba(0,0,0,0.36)]',
      active: 'active:text-yellow-600',
      shadowing: 'shadow-yellow-500/30'
    },
    mui: {
      main: '#FACC15',
      hover: '#EAB308',
      active: '#CA8A04',
      border: '#FACC15',
      border1: 'rgba(250, 204, 21, 0.2)',
      fill: 'rgba(250, 204, 21, 0.1)',
      cart: '#FFFF',
      light: '#facc15',
      bg2: 'rgba(100,116,139,0.6), rgba(71,85,105,0.7)'
    }
  },
  {
    tailwind: {
      text: 'text-orange-500',
      text1: 'text-orange-500/60',
      text2: 'text-orange-400/50',
      hover: 'hover:text-orange-700',
      active: 'active:text-orange-800',
      border: 'border-orange-500',
      border1: 'border-orange-400/30',
      border5: 'border-yellow-600/50',
      bg1: 'bg-orange-500/20',
      bg2: 'bg-red-400/50',
      bg3: 'bg-red-300/50',
      bgHover: 'hover:bg-red-300/70',
      textColor:
        'bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-400',
      textColor1: 'bg-gradient-to-br from-slate-400/50 to-slate-500/50',
      focus: 'focus:border-yellow-400',
      placeHolder: 'placeholder-yellow-400/50',
      ringColor: 'ring-yellow-300/50',
      // All Users
      groupHover: 'group-hover:border-yellow-400/60',
      text3: 'text-yellow-600',
      text4: 'text-yellow-300/90',
      focus1: 'focus:border-yellow-400/50',
      focusRing: 'focus:ring-yellow-300/50',
      texting: 'text-slate-100',
      texting1: 'text-slate-200',
      groupHover2: 'group-hover:border-amber-500/20',
      shadowhover: 'group-hover:shadow-amber-500/10',
      shadowhover1: 'hover:shadow-yellow-500/20',
      drop_shadow: 'drop-shadow-[0_0_10px_rgba(255,255,0,0.6)]',
      shadows: 'shadow-[0_8px_32px_0_rgba(255,255,0,0.36)]'
    },
    mui: {
      main: '#F97316',
      hover: '#C2410C',
      active: '#9A3C00',
      border: '#F97316'
    }
  },
  {
    tailwind: {
      text: 'text-cyan-400',
      hover: 'hover:text-cyan-500',
      active: 'active:text-cyan-600',
      border: 'border-cyan-400'
    },
    mui: {
      main: '#22D3EE',
      hover: '#06B6D4',
      active: '#0891B2',
      border: '#22D3EE'
    }
  },
  {
    tailwind: {
      text: 'text-fuchsia-400',
      hover: 'hover:text-fuchsia-500',
      active: 'active:text-fuchsia-600',
      border: 'border-fuchsia-400'
    },
    mui: {
      main: '#E879F9',
      hover: '#D946EF',
      active: '#C026D3',
      border: '#E879F9'
    }
  }
]
const defaultGradient = gradientBackgrounds[0] // Default to the first gradient

export const MyProSidebarProvider = ({ children }) => {
  const { societies } = useSelector(state => state.societies)
  const { activeUser } = useSelector(state => state.auth)

  const [activeSociety, setActiveSocietyState] = useState(() => {
    const saved = localStorage.getItem(ACTIVE_SOCIETY_KEY)
    return saved ? JSON.parse(saved) : null
  })
  // console.log("active society", activeSociety);
  // console.log("society", societies);

  const [collapsed, setCollapsed] = useState(false)
  const savedSociety = useMemo(() => {
    const saved = localStorage.getItem(ACTIVE_SOCIETY_KEY)
    return saved ? JSON.parse(saved) : null
  }, [])
  // Create a mapping of society IDs to background gradients and theme colors
  const societySettingsMap = useMemo(() => {
    const map = new Map()
    if (societies) {
      societies.forEach((society, index) => {
        map.set(society._id, {
          gradient: gradientBackgrounds[index % gradientBackgrounds.length],
          theme: themeColors[index % themeColors.length]
        })
      })
    }
    return map
  }, [societies])
  useEffect(() => {
    if (!societies?.length) return

    const initializeSociety = () => {
      // If we have a saved society, verify it still exists in the current societies list
      if (savedSociety) {
        const societyStillExists = societies.find(
          s => s._id === savedSociety._id
        )
        if (societyStillExists) {
          setActiveSocietyState(savedSociety)
          return
        }
      }

      // If no valid saved society, set based on user role
      if (activeUser?.role === 'Employee') {
        // For employees, find their first assigned society
        const employeeSociety = societies.find(society =>
          activeUser.societies?.some(
            userSociety => userSociety._id === society._id
          )
        )
        if (employeeSociety) {
          setActiveSocietyState(employeeSociety)
          localStorage.setItem(
            ACTIVE_SOCIETY_KEY,
            JSON.stringify(employeeSociety)
          )
        }
      } else {
        // For admins or other roles, default to first society
        setActiveSocietyState(societies[0])
        localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(societies[0]))
      }
    }

    initializeSociety()
  }, [societies, activeUser, savedSociety])

  const updateActiveSociety = society => {
    if (society) {
      setActiveSocietyState(society)
      localStorage.setItem(ACTIVE_SOCIETY_KEY, JSON.stringify(society))
    }
  }

  const toggleSidebar = () => {
    setCollapsed(prev => !prev)
  }

  // Determine the gradient and theme for the active society
  const societySettings = useMemo(() => {
    if (!activeSociety)
      return { gradient: defaultGradient, theme: themeColors[0] }
    return (
      societySettingsMap.get(activeSociety._id) || {
        gradient: defaultGradient,
        theme: themeColors[0]
      }
    )
  }, [activeSociety, societySettingsMap])

  const contextValue = useMemo(
    () => ({
      activeSociety,
      setActiveSociety: updateActiveSociety,
      gradient: societySettings.gradient,
      theme: societySettings.theme,
      collapsed,
      toggleSidebar
    }),
    [activeSociety, societySettings, collapsed]
  )

  return (
    <ProSidebarProvider>
      <SidebarContext.Provider value={contextValue}>
        <div className={`flex ${societySettings.gradient}`}>
          <MyProSidebar />
          {children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  )
}

export const useSidebarContext = () => {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error(
      'useSidebarContext must be used within MyProSidebarProvider'
    )
  }
  return context
}

export default MyProSidebarProvider
