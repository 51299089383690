import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  login as loginAPI,
  logout as logoutAPI,
  register as registerAPI,
  getAllUsers as getAllUsersAPI,
  getCustomerById,
  updateCustomer,
  getCustomerPlots,
  getAllInvestors,
  getInvestorById,
  getAllEmployees,
  getEmployeeById,
  updateEmployee,
  updateInvestor,
  getCurrentUser,
  deleteUser as deleteUserAPI,
  updateUser as updateUserAPI,
  updateUserStatus as updateUserStatusAPI,
  getAdmin,
  updateAdmin,
  verifyPassword as verifyPasswordAPI,
  resetPassword as resetPasswordAPI
} from '../../api'

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await loginAPI(credentials)

      // Ensure response has expected structure
      if (!response || !response.token || !response.user) {
        throw new Error('Invalid login response')
      }

      // Store token and last activity
      localStorage.setItem('token', response.token)
      localStorage.setItem('lastActivity', Date.now().toString())

      return response.user
    } catch (error) {
      // More comprehensive error handling
      const errorMessage =
        error.response?.data?.message || error.message || 'Login failed'

      return rejectWithValue(errorMessage)
    }
  }
)

// Thunk for user registration
export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await registerAPI(formData)
      return response
    } catch (error) {
      console.error('Registration API error:', error)
      return rejectWithValue(error.response?.data || error.message)
    }
  }
)

// Verify Password Thunk
export const verifyPassword = createAsyncThunk(
  'auth/verifyPassword',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await verifyPasswordAPI({ userId })
      return response
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'Password verification failed'
      )
    }
  }
)

// ✅ Reset Password Thunk
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ userId, newPassword }, { rejectWithValue }) => {
    try {
      const response = await resetPasswordAPI({ userId, newPassword })
      return response
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Password reset failed')
    }
  }
)

export const fetchUserPassword = createAsyncThunk(
  'auth/fetchUserPassword',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await verifyPasswordAPI({ userId })
      console.log('Fetched Password:', response.password)
      return { userId, password: response.password }
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch password')
    }
  }
)

// Thunk for fetching admin details
export const fetchAdminById = createAsyncThunk(
  'auth/fetchAdminById',
  async (adminId, { rejectWithValue }) => {
    try {
      const response = await getAdmin(adminId)
      return response.admin // Adjust based on the API response structure
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch admin')
    }
  }
)

// Thunk for updating admin details
export const saveAdminData = createAsyncThunk(
  'auth/saveAdminData',
  async ({ adminId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await updateAdmin(adminId, updatedData)
      return response.admin // Adjust based on the API response structure
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update admin')
    }
  }
)

export const checkActiveUser = createAsyncThunk(
  'auth/checkActiveUser',
  async (_, { rejectWithValue, getState }) => {
    const state = getState()
    // Check if the user data already exists and is valid
    if (state.auth.activeUser && state.auth.activeUser.fetchedAt) {
      const lastFetchedAt = state.auth.activeUser.fetchedAt
      const currentTime = Date.now()
      const timeElapsed = currentTime - lastFetchedAt

      // If data was fetched within 5 minutes (300000ms), return the cached user data
      if (timeElapsed < 300000) {
        return state.auth.activeUser
      }
    }

    try {
      const response = await getCurrentUser()
      const user = response.user

      // Filter societies based on user role
      let availableSocieties = user.societies
      if (user.role !== 'Admin') {
        availableSocieties = user.additionalData?.societies || []
      }

      return { ...user, availableSocieties, fetchedAt: Date.now() } // Add fetchedAt timestamp
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch active user')
    }
  }
)

export const updateUserStatus = createAsyncThunk(
  'auth/updateUserStatus',
  async ({ userId, status }, { rejectWithValue }) => {
    try {
      const response = await updateUserStatusAPI(userId, status)
      return { userId, status }
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update user status')
    }
  }
)
export const updateUserInfo = createAsyncThunk(
  'auth/updateUserInfo',
  async ({ userId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await updateUserAPI(userId, updatedData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      console.log('Update response:', response)
      return response
    } catch (error) {
      console.error('Error updating user:', error)
      return rejectWithValue(error)
    }
  }
)

// Thunks for CRUD operations
export const saveEmployeeData = createAsyncThunk(
  'auth/saveEmployeeData',
  async (employeeData, { rejectWithValue }) => {
    try {
      const response = await updateEmployee(employeeData.id, employeeData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const saveInvestorData = createAsyncThunk(
  'auth/saveInvestorData',
  async ({ investorData, investorId }, { rejectWithValue }) => {
    try {
      const response = await updateInvestor(investorId, investorData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Thunk for fetching all users
export const fetchAllUsers = createAsyncThunk(
  'auth/fetchAllUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllUsersAPI()
      // console.log("Fetched users from API:", response.length);
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      // Call logout API if needed
      await logoutAPI()

      // Clear all sensitive local storage and session data
      localStorage.removeItem('token')
      localStorage.removeItem('selectedMenuItem')
      localStorage.removeItem('lastPath')
      localStorage.removeItem('expandedSection')
      localStorage.removeItem('lastActivity') ///
      // Clear session storage
      sessionStorage.clear()

      // // Trigger logout event
      // window.dispatchEvent(new Event("logout"));

      return true
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Logout failed')
    }
  }
)

// Thunks for fetching and updating customer/investor/employee data
export const fetchCustomerById = createAsyncThunk(
  'auth/fetchCustomerById',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await getCustomerById(customerId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const updateCustomerInfo = createAsyncThunk(
  'auth/updateCustomerInfo',
  async ({ customerId, updatedData }, { rejectWithValue }) => {
    try {
      const response = await updateCustomer(customerId, updatedData)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

export const fetchCustomerPlots = createAsyncThunk(
  'auth/fetchCustomerPlots',
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await getCustomerPlots(customerId)
      // console.log(
      //   `Customer ${customerId} has ${response.plots.length} plot(s).`
      // );
      return response.plots
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      )
    }
  }
)

// Fetching all investors
export const fetchAllInvestors = createAsyncThunk(
  'auth/fetchAllInvestors',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllInvestors()
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Fetching investor by ID
export const fetchInvestorById = createAsyncThunk(
  'auth/fetchInvestorById',
  async (investorId, { rejectWithValue }) => {
    try {
      const response = await getInvestorById(investorId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Fetching all employees
export const fetchAllEmployees = createAsyncThunk(
  'auth/fetchAllEmployees',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllEmployees()
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Fetching employee by ID
export const fetchEmployeeById = createAsyncThunk(
  'auth/fetchEmployeeById',
  async (employeeId, { rejectWithValue }) => {
    try {
      const response = await getEmployeeById(employeeId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

// Delete user thunk
export const deleteUser = createAsyncThunk(
  'auth/deleteUser',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await deleteUserAPI(userId)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  }
)

const initialState = {
  user: null,
  isAuthenticated: !!localStorage.getItem('token'),
  loading: false,
  status: 'idle',
  error: null,
  users: [],
  customers: [],
  plots: [],
  investors: [],
  employees: [],
  admins: [],
  activeUser: null,
  lastFetched: null,
  lastActivity: localStorage.getItem('lastActivity') || null
}

// Auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateLastActivity: state => {
      const currentTimestamp = new Date().toISOString()
      state.lastActivity = currentTimestamp
      localStorage.setItem('lastActivity', currentTimestamp)
    },

    logoutUser: state => {
      // Check if this is a reload or actual logout
      const isReloading = sessionStorage.getItem('isReloading') === 'true'

      if (!isReloading) {
        state.user = null
        state.isAuthenticated = false
        state.activeUser = null
        localStorage.removeItem('token')
        localStorage.removeItem('lastActivity')
        sessionStorage.removeItem('isReloading')
      } else {
        // If it's a reload, just remove the reloading flag
        sessionStorage.removeItem('isReloading')
      }
    }
  },
  extraReducers: builder => {
    builder

      .addCase(login.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false
        state.isAuthenticated = true
        state.activeUser = action.payload
        state.user = action.payload
        state.error = null
        state.lastActivity = Date.now()
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false
        state.isAuthenticated = false
        state.activeUser = null
        state.user = null
        state.error = action.payload || 'Login failed'
      })
      .addCase(logout.pending, state => {
        state.loading = true
      })
      .addCase(logout.fulfilled, state => {
        // state.isAuthenticated = false;
        // state.user = null;
        // state.activeUser = null;
        state.isAuthenticated = false
        state.user = null
        state.activeUser = null
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(registerUser.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false
        state.user = action.payload.user
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      .addCase(fetchAllUsers.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.users = action.payload
        state.status = 'succeeded'
        state.lastFetched = new Date().toISOString()
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        console.error('Failed to fetch users:', action.payload)
        state.status = 'failed'
        state.error = action.payload
      })

      .addCase(fetchCustomerById.fulfilled, (state, action) => {
        state.customers.push(action.payload)
        state.status = 'succeeded'
      })
      .addCase(fetchCustomerById.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(updateCustomerInfo.fulfilled, (state, action) => {
        const index = state.customers.findIndex(
          customer => customer.id === action.payload.id
        )
        if (index !== -1) {
          state.customers[index] = action.payload
        }
        state.status = 'succeeded'
      })
      .addCase(updateCustomerInfo.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(fetchCustomerPlots.fulfilled, (state, action) => {
        state.plots = action.payload
        state.status = 'succeeded'
      })
      .addCase(fetchCustomerPlots.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(fetchAllInvestors.fulfilled, (state, action) => {
        state.investors = action.payload
        state.status = 'succeeded'
      })
      .addCase(fetchAllInvestors.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(fetchInvestorById.fulfilled, (state, action) => {
        const index = state.investors.findIndex(
          investor => investor.id === action.payload.id
        )
        if (index !== -1) {
          state.investors[index] = action.payload
        }
        state.status = 'succeeded'
      })
      .addCase(fetchInvestorById.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(fetchAllEmployees.fulfilled, (state, action) => {
        state.employees = action.payload
        state.status = 'succeeded'
      })
      .addCase(fetchAllEmployees.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(fetchEmployeeById.fulfilled, (state, action) => {
        const index = state.employees.findIndex(
          employee => employee.id === action.payload.id
        )
        if (index !== -1) {
          state.employees[index] = action.payload
        }
        state.status = 'succeeded'
      })
      .addCase(fetchEmployeeById.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(saveEmployeeData.pending, state => {
        state.status = 'loading'
      })
      .addCase(saveEmployeeData.fulfilled, (state, action) => {
        const index = state.employees.findIndex(
          employee => employee.id === action.payload.id
        )
        if (index !== -1) {
          state.employees[index] = action.payload
        }
        state.status = 'succeeded'
      })
      .addCase(saveEmployeeData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(saveInvestorData.pending, state => {
        state.status = 'loading'
      })
      .addCase(saveInvestorData.fulfilled, (state, action) => {
        const index = state.investors.findIndex(
          investor => investor.id === action.payload.id
        )
        if (index !== -1) {
          state.investors[index] = action.payload
        }
        state.status = 'succeeded'
      })
      .addCase(saveInvestorData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(deleteUser.pending, state => {
        state.status = 'loading'
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.users = state.users.filter(user => user.id !== action.payload.id)
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })

      .addCase(updateUserInfo.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        const updatedUser = action.payload.user
        if (state.activeUser?.id === updatedUser.id) {
          state.activeUser = updatedUser
        }
        state.loading = false
        state.status = 'succeeded'
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.loading = false
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        const { userId, status } = action.payload
        const userIndex = state.users.findIndex(user => user._id === userId)

        if (userIndex !== -1) {
          state.users[userIndex] = {
            ...state.users[userIndex],
            status
          }
        }
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(fetchAdminById.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAdminById.fulfilled, (state, action) => {
        const admin = action.payload
        const existingAdminIndex = state.admins.findIndex(
          adm => adm._id === admin._id
        )

        if (existingAdminIndex >= 0) {
          state.admins[existingAdminIndex] = admin
        } else {
          state.admins.push(admin)
        }
        state.status = 'succeeded'
      })
      .addCase(fetchAdminById.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(saveAdminData.pending, state => {
        state.status = 'loading'
      })
      .addCase(saveAdminData.fulfilled, (state, action) => {
        const updatedAdmin = action.payload
        const adminIndex = state.admins.findIndex(
          admin => admin._id === updatedAdmin._id
        )

        if (adminIndex !== -1) {
          state.admins[adminIndex] = updatedAdmin
        } else {
          state.admins.push(updatedAdmin)
        }
        state.status = 'succeeded'
      })
      .addCase(saveAdminData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload
      })
      .addCase(checkActiveUser.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(checkActiveUser.fulfilled, (state, action) => {
        state.loading = false
        state.activeUser = action.payload
        state.isAuthenticated = true
        state.error = null
      })
      .addCase(checkActiveUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload || 'Failed to fetch active user'
      })

      .addCase(verifyPassword.fulfilled, (state, action) => {
        state.loading = false
        const { userId, password } = action.payload
        state.users = state.users.map(user =>
          user._id === userId ? { ...user, password } : user
        )
      })
      .addCase(verifyPassword.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
      // ✅ Handle Reset Password
      .addCase(resetPassword.pending, state => {
        state.loading = true
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
  }
})

export const addTabCloseListener = dispatch => {
  const handleTabClose = async () => {
    try {
      // Dispatch logout action when tab is about to be closed
      await dispatch(logout()).unwrap()
    } catch (error) {
      console.error('Logout failed on tab close:', error)
    }
  }

  // Add event listener for tab close
  window.addEventListener('beforeunload', handleTabClose)

  // Return a cleanup function to remove the event listener
  return () => {
    window.removeEventListener('beforeunload', handleTabClose)
  }
}

export const { logoutUser, updateLastActivity } = authSlice.actions
export default authSlice.reducer
