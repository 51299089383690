import React from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { MapPin, X } from 'lucide-react'
import { useSidebarContext } from '../scenes/global/sidebar/SidebarContext'
import { updateSocietyActionPlot } from '../store/slices/societySlice'
import { fetchSocieties } from '../store/slices/societySlice'

// Validation Schema
const plotValidationSchema = Yup.object().shape({
  plots: Yup.array().of(
    Yup.object().shape({
      plot_number: Yup.string()
        .required('Plot number is required')
        .matches(
          /^([a-zA-Z]*)(\d+)-?([a-zA-Z]*)(\d+)?$/,
          'Invalid plot number format'
        ),
      block: Yup.string().required('Block is required'),
      size: Yup.string()
        .required('Size is required')
        .test(
          'is-valid-size',
          'Size must be a number or in format like 25*5',
          value => {
            if (/^\d+(\.\d+)?$/.test(value)) return true

            const multiplicationPattern = /^\d+(\.\d+)?\s*\*\s*\d+(\.\d+)?$/
            return multiplicationPattern.test(value)
          }
        ),
      marla: Yup.string()
        .required('Size in Marla is required')
        .test(
          'is-valid-size',
          'Size must be a number or in format like 25*5',
          value => {
            if (/^\d+(\.\d+)?$/.test(value)) return true

            const multiplicationPattern = /^\d+(\.\d+)?\s*\*\s*\d+(\.\d+)?$/
            return multiplicationPattern.test(value)
          }
        ),
      plot_type: Yup.string().required('Plot type is required'),
      category: Yup.string().required('Category is required')
    })
  )
})

const PlotCreation = () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { activeSociety } = useSidebarContext()

  const expandPlotRange = plotInput => {
    const rangePattern = /^([a-zA-Z]*)(\d+)-([a-zA-Z]*)(\d+)$/
    const match = plotInput.match(rangePattern)

    if (!match) return [plotInput]

    const [, prefix1, start, prefix2, end] = match

    if (prefix1 !== prefix2) {
      return []
    }

    const expandedPlots = []
    for (let i = parseInt(start); i <= parseInt(end); i++) {
      expandedPlots.push(`${prefix1}${i}`)
    }

    return expandedPlots
  }

  const handleSubmitPlots = async (values, { resetForm, setSubmitting }) => {
    try {
      if (!activeSociety) {
        enqueueSnackbar('Please select a society first', { variant: 'error' })
        setSubmitting(false)
        return
      }

      // Expand plot ranges
      const expandedPlots = values.plots.flatMap(plot => {
        const expandedNumbers = expandPlotRange(plot.plot_number)
        return expandedNumbers.map(num => ({
          ...plot,
          plot_number: num
        }))
      })

      // Create a FormData object
      const formData = new FormData()

      // Append society fields
      formData.append('name', activeSociety.name)
      formData.append('location', activeSociety.location)

      // Create a JSON string of the plots array and append it to formData
      formData.append('plotsData', JSON.stringify(expandedPlots))

      // Append images if any
      if (values.society_image) {
        formData.append('society_image', values.society_image)
      }
      if (values.society_map_image) {
        formData.append('society_map_image', values.society_map_image)
      }

      // Dispatch the Redux action with correct parameters
      await dispatch(
        updateSocietyActionPlot({
          id: activeSociety._id, // Make sure this is the correct property name
          data: formData // This should match what your thunk expects
        })
      ).unwrap()

      // Fetch updated societies and reset form
      await dispatch(fetchSocieties())
      enqueueSnackbar('Plots added successfully!', { variant: 'success' })
      resetForm()
      setSubmitting(false)
    } catch (error) {
      console.error('Error adding plots:', error)
      enqueueSnackbar('Failed to add plots', { variant: 'error' })
      setSubmitting(false)
    }
  }

  if (!activeSociety) {
    return (
      <div className='max-w-6xl mx-auto p-6 bg-gradient-to-br'>
        <div className='bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl p-8 mb-8'>
          <h2 className='text-3xl font-bold mb-8 text-teal-300'>
            Please Select a Society
          </h2>
        </div>
      </div>
    )
  }

  return (
    <div className='max-w-6xl mx-auto p-6 bg-gradient-to-br'>
      <div className='bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 rounded-xl shadow-xl p-8 mb-8'>
        <h2 className='relative text-4xl font-extrabold mb-10 text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 animate-gradient-x'>
          <span className='block text-lg text-teal-500 mb-2 opacity-70'>
            Expand Your Society
          </span>
          Plot Creation for
          <span className='ml-3 inline-flex items-center px-6 py-1 rounded-full bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-600 border border-teal-300/50 shadow-md'>
            <MapPin className='mr-2 w-5 h-5 text-teal-500' />
            {activeSociety.name}
          </span>
        </h2>

        <Formik
          initialValues={{
            plots: [
              {
                plot_number: '',
                block: '',
                plot_type: '',
                size: '',
                marla: '',
                category: ''
              }
            ]
          }}
          validationSchema={plotValidationSchema}
          onSubmit={handleSubmitPlots}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              <FieldArray name='plots'>
                {({ remove, push }) => (
                  <>
                    {values.plots.map((plot, index) => (
                      <div
                        key={index}
                        className='relative rounded-lg p-6 mb-6 shadow-xl border border-teal-500'
                      >
                        {index > 0 && (
                          <button
                            type='button'
                            onClick={() => remove(index)}
                            className='absolute top-2 right-2 group p-1'
                            aria-label='Remove plot'
                          >
                            <div className='relative'>
                              <div className='absolute -inset-1 bg-red-500 rounded-full opacity-50 group-hover:opacity-75 transition-opacity duration-300'></div>
                              <div className='relative bg-white rounded-full p-1 border-2 border-red-500 group-hover:border-red-600 transition-colors'>
                                <X
                                  size={15}
                                  className='text-red-500 group-hover:text-red-600 transition-colors'
                                />
                              </div>
                            </div>
                          </button>
                        )}

                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
                          <div className='space-y-2'>
                            <label className='block text-teal-300'>
                              Plot Number Range
                            </label>
                            <Field
                              type='text'
                              name={`plots.${index}.plot_number`}
                              className='w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300'
                              placeholder='e.g., 101-110, AR-3430-AR-3440'
                            />
                            {errors.plots?.[index]?.plot_number &&
                              touched.plots?.[index]?.plot_number && (
                                <div className='text-red-500 mt-2'>
                                  {errors.plots[index].plot_number}
                                </div>
                              )}
                          </div>

                          {/* Block */}
                          <div className='space-y-2'>
                            <label className='block text-teal-300'>Block</label>
                            <Field
                              type='text'
                              name={`plots.${index}.block`}
                              className='w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300'
                            />
                            {errors.plots?.[index]?.block &&
                              touched.plots?.[index]?.block && (
                                <div className='text-red-500 mt-2'>
                                  {errors.plots[index].block}
                                </div>
                              )}
                          </div>

                          {/* Size */}
                          <div className='space-y-2'>
                            <label className='block text-teal-300'>Size</label>
                            <Field
                              type='text'
                              name={`plots.${index}.size`}
                              className='w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300'
                            />
                            {errors.plots?.[index]?.size &&
                              touched.plots?.[index]?.size && (
                                <div className='text-red-500 mt-2'>
                                  {errors.plots[index].size}
                                </div>
                              )}
                          </div>

                          {/* Plot Type */}
                          <div className='space-y-2'>
                            <label className='block text-teal-300'>
                              Plot Type
                            </label>
                            <Field
                              as='select'
                              name={`plots.${index}.plot_type`}
                              className='w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300'
                            >
                              <option value=''>Select type</option>
                              <option value='Residential'>Residential</option>
                              <option value='Commercial'>Commercial</option>
                            </Field>
                            {errors.plots?.[index]?.plot_type &&
                              touched.plots?.[index]?.plot_type && (
                                <div className='text-red-500 mt-2'>
                                  {errors.plots[index].plot_type}
                                </div>
                              )}
                          </div>

                          {/* Category */}
                          <div className='space-y-2'>
                            <label className='block text-teal-300'>
                              Category
                            </label>
                            <Field
                              as='select'
                              name={`plots.${index}.category`}
                              className='w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300'
                            >
                              <option value=''>Select category</option>
                              <option value='General'>General</option>
                              <option value='Corner'>Corner</option>
                              <option value='Park Face'>Park Face</option>
                              <option value='Boulevard'>Boulevard</option>
                            </Field>
                            {errors.plots?.[index]?.category &&
                              touched.plots?.[index]?.category && (
                                <div className='text-red-500 mt-2'>
                                  {errors.plots[index].category}
                                </div>
                              )}
                          </div>
                          <div className='space-y-2'>
                            <label className='block text-teal-300'>Marla</label>
                            <Field
                              type='text'
                              name={`plots.${index}.marla`}
                              className='w-full bg-slate-700 text-teal-300 border border-slate-500 rounded-lg p-3 focus:ring-2 focus:ring-teal-300'
                            />
                            {errors.plots?.[index]?.marla &&
                              touched.plots?.[index]?.marla && (
                                <div className='text-red-500 mt-2'>
                                  {errors.plots[index].marla}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className='flex flex-col sm:flex-row justify-between gap-4 mt-8'>
                      <button
                        type='button'
                        onClick={() =>
                          push({
                            plot_number: '',
                            block: '',
                            plot_type: '',
                            size: '',
                            category: ''
                          })
                        }
                        className='px-6 py-3 bg-slate-600 text-teal-300 rounded-lg hover:bg-slate-500 transition-colors duration-200 flex items-center justify-center'
                      >
                        <span className='mr-2'>+</span> Add Another Plot
                      </button>
                      <button
                        type='submit'
                        className='px-8 py-3 bg-teal-600 text-white rounded-lg hover:bg-teal-500 transition-colors duration-200 font-semibold'
                      >
                        Submit Plots
                      </button>
                    </div>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default PlotCreation
