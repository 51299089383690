// src/theme/colors.js

const colors = {
  // Main background
  bgGradientFrom: 'from-gray-700',
  bgGradientVia: 'via-teal-600/10',
  bgGradientTo: 'to-teal-800',

  // Text colors
  textPrimary: 'text-white',
  textSecondary: 'text-gray-300',
  textMuted: 'text-gray-400',
  textHighlight: 'text-teal-300',
  textFooter: 'text-teal-400',

  // Button gradients
  btnPrimaryFrom: 'from-teal-500',
  btnPrimaryTo: 'to-emerald-600',
  btnSecondaryFrom: 'from-gray-600',
  btnSecondaryTo: 'to-gray-700',

  // Card and container backgrounds
  cardBg: 'bg-gray-800',
  cardBgOpacity: 'bg-opacity-60',
  cardBorder: 'border-teal-800',
  cardBorderHover: 'border-teal-500',
  cardBgHover: 'bg-gray-700',

  // Form elements
  inputBg: 'bg-gray-700',
  inputBgDisabled: 'bg-gray-600',
  inputBorder: 'border-gray-600',
  inputFocusBorder: 'focus:border-teal-500',
  inputFocusRing: 'focus:ring-teal-500',

  // Table elements
  tableHeaderBg: 'bg-gray-800',
  tableBorderColor: 'border-gray-700',
  tableRowHover: 'hover:bg-gray-700',

  // Status colors
  positive: 'text-emerald-400',
  negative: 'text-red-400',

  // Utility functions
  getBalanceColor: amount => (amount >= 0 ? 'text-emerald-400' : 'text-red-400')
}

export default colors
