import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChevronsLeft,
  ChevronsRight,
  Mail,
  Search,
  Users,
  ChevronDown,
} from "lucide-react";
import { fetchAllUsers } from "../../../store/slices/authSlice";
import { useSidebarContext } from "../../global/sidebar/SidebarContext";

const InboxAndUsers = () => {
  const dispatch = useDispatch();
  const { users, status } = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { gradient, theme } = useSidebarContext();
  const itemsPerPage = 5;

  const inboxMessages = [
    {
      name: "Leonardo Payne",
      message: "Hey! there I'm available...",
      time: "12:30 PM",
      avatar: "L",
    },
    {
      name: "Carl Henson",
      message: "I've finished it! See you soon...",
      time: "02:14 AM",
      avatar: "C",
    },
    {
      name: "Jensen Combs",
      message: "This template is awesome!",
      time: "08:22 PM",
      avatar: "J",
    },
    {
      name: "Amiah Burton",
      message: "Nice to meet you",
      time: "05:49 AM",
      avatar: "A",
    },
    {
      name: "Yaretzi Mayo",
      message: "Hey! there I'm available...",
      time: "01:19 AM",
      avatar: "Y",
    },
  ];

  useEffect(() => {
    if (status === "idle" || (status === "failed" && users.length === 0)) {
      dispatch(fetchAllUsers());
    }
  }, [dispatch, status, users.length]);

  const getPhoneNumber = (user) => {
    if (user.role === "Customer") {
      return user.customerData?.contactInfo?.phone || "N/A";
    } else if (user.role === "Employee") {
      return user.employeeData?.personalDetails?.phone || "N/A";
    } else if (user.role === "Investor") {
      return user.investorData?.contactInfo?.phone || "N/A";
    } else if (user.role === "Admin") {
      return user.adminData?.contactInfo?.phone || "N/A";
    }
    return "N/A";
  };

  const formatCnicNumber = (cnic) => {
    if (!cnic || cnic === "N/A") return "N/A";
    const cleanedCnic = cnic.replace(/[^\d]/g, "");
    if (cleanedCnic.length !== 13) return cnic;
    return `${cleanedCnic.slice(0, 5)}-${cleanedCnic.slice(
      5,
      12
    )}-${cleanedCnic.slice(12)}`;
  };

  const getCnicNumber = (user) => {
    if (user.role === "Customer") {
      return formatCnicNumber(user.customerData?.contactInfo?.cnic);
    } else if (user.role === "Employee") {
      return formatCnicNumber(user.employeeData?.personalDetails?.cnic);
    } else if (user.role === "Investor") {
      return formatCnicNumber(user.investorData?.contactInfo?.cnic);
    } else if (user.role === "Admin") {
      return formatCnicNumber(user.adminData?.contactInfo?.cnic);
    }
    return "N/A";
  };

  const filteredUsers =
    users?.filter((user) => {
      const phone = getPhoneNumber(user)?.toLowerCase() || "";
      const username = user.username?.toLowerCase() || "";
      return (
        username.includes(searchQuery.toLowerCase()) ||
        phone.includes(searchQuery.toLowerCase())
      );
    }) || [];

  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div className=" p-3">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          {/* Inbox Section */}
          <div className="md:col-span-4 space-y-6">
            <div className={`${theme.tailwind.bg6} backdrop-blur-xl border ${theme.tailwind.border3} rounded-3xl p-6 shadow-2xl transition-all duration-500 ${theme.tailwind.shadowhover1}`}>
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-3">
                  <Mail className={`w-6 h-6 ${theme.tailwind.text}`} />
                  <h2 className={`${theme.tailwind.text4} font-bold text-2xl`}>INBOX</h2>
                </div>
                <div className="relative">
                  <Search className={`w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 ${theme.tailwind.text1}`} />
                  <input
                    type="text"
                    placeholder="Search messages..."
                    className={`pl-10 pr-4 py-2.5 w-full ${theme.tailwind.bg2} rounded-xl ${theme.tailwind.text} ${theme.tailwind.placeHolder} border ${theme.tailwind.border2} ${theme.tailwind.focus1} focus:outline-none focus:ring-2 ${theme.tailwind.focusRing1} transition duration-300`}
                  />
                </div>
              </div>

              <div className="space-y-4">
                {inboxMessages.map((message, index) => (
                  <div
                    key={index}
                    className={`group relative overflow-hidden p-4 rounded-xl transition-all duration-300 cursor-pointer border ${theme.tailwind.border4} ${theme.tailwind.hoverB} ${theme.tailwind.bg2} ${theme.tailwind.bgHover1}`}
                  >
                    <div className={`absolute inset-0 ${theme.tailwind.bg7} opacity-0 group-hover:opacity-100 transition-opacity duration-700`} />
                    <div className="flex items-start space-x-4 relative z-10">
                      <div className="flex-shrink-0">
                        <div className={`w-12 h-12 rounded-full ${theme.tailwind.bg8} border ${theme.tailwind.border2} ${theme.tailwind.groupHover1} flex items-center justify-center ${theme.tailwind.text} font-semibold text-lg shadow-md group-hover:scale-110 transition-transform duration-300`}>
                          {message.avatar}
                        </div>
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between">
                          <p className={`${theme.tailwind.text} font-semibold text-lg truncate ${theme.tailwind.groupHover7} transition-colors duration-300`}>
                            {message.name}
                          </p>
                          <span className={`${theme.tailwind.text2} text-sm ${theme.tailwind.groupHover7} transition-colors duration-300`}>
                            {message.time}
                          </span>
                        </div>
                        <p className={`mt-1 ${theme.tailwind.text4} text-sm truncate ${theme.tailwind.groupHover3} transition-colors duration-300`}>
                          {message.message}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Users Section */}
          <div className="md:col-span-8 space-y-6">
            <div className={`${theme.tailwind.bg6} backdrop-blur-xl border border-slate-700/30 rounded-3xl p-6 shadow-2xl transition-all duration-500 hover:shadow-teal-500/20`}>
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-3">
                  <Users className={`w-6 h-6 ${theme.tailwind.text}`} />
                  <h2 className={`${theme.tailwind.text4} font-bold text-2xl tracking-wide`}>
                    ALL USERS
                  </h2>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <Search className={`w-5 h-5 absolute left-3 top-1/2 transform -translate-y-1/2 ${theme.tailwind.text2}`} />
                    <input
                      type="text"
                      placeholder="Search users..."
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                      }}
                      className={`pl-10 pr-4 py-2.5 w-full ${theme.tailwind.bg2} rounded-xl ${theme.tailwind.text} ${theme.tailwind.placeHolder} border ${theme.tailwind.border2} ${theme.tailwind.focus1} focus:outline-none focus:ring-2 ${theme.tailwind.focusRing1} transition duration-300`}

                    />
                  </div>
                </div>
              </div>

              {status === "loading" ? (
                <div className="flex items-center justify-center h-64">
                  <div className={`animate-spin rounded-full h-10 w-10 border-b-2 ${theme.tailwind.border}`}></div>
                </div>
              ) : paginatedUsers.length > 0 ? (
                <>
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className={`border-b ${theme.tailwind.border2}`}>
                          {["Username", "Phone", "CNIC", "Role"].map(
                            (header) => (
                              <th
                                key={header}
                                className={`px-6 py-4 text-left ${theme.tailwind.text2} font-semibold uppercase tracking-wider`}
                              >
                                <div className="flex items-center">
                                  {header}
                                  <ChevronDown className={`w-4 h-4 ml-2 ${theme.tailwind.text2}`} />
                                </div>
                              </th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className={`divide-y ${theme.tailwind.divide}`}>
                        {paginatedUsers.map((user, index) => (
                          <tr
                            key={user._id || index}
                            className={`group ${theme.tailwind.bgHover2} transition-colors duration-300 ${theme.tailwind.hoverB}`}
                          >
                            <td className="px-6 py-4">
                              <div className="flex items-center space-x-3">
                                <div className={`w-10 h-10 rounded-full ${theme.tailwind.bg8} border ${theme.tailwind.border2} ${theme.tailwind.groupHover1} flex items-center justify-center ${theme.tailwind.text} font-semibold group-hover:scale-110 transition-transform duration-300 overflow-hidden`}>
                                  {user.profile_picture ? (
                                    <img
                                      src={user.profile_picture}
                                      alt={`${user.username}'s avatar`}
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <span>
                                      {user.username?.charAt(0).toUpperCase() ||
                                        "U"}
                                    </span>
                                  )}
                                </div>

                                <span className={`${theme.tailwind.text} ${theme.tailwind.groupHover7} transition-colors`}>
                                  {user.username}
                                </span>
                              </div>
                            </td>
                            <td className={`px-6 py-4 ${theme.tailwind.text4} ${theme.tailwind.groupHover7} transition-colors`}>
                              {getPhoneNumber(user)}
                            </td>
                            <td className={`px-6 py-4 ${theme.tailwind.text4} ${theme.tailwind.groupHover7} transition-colors`}>
                              {getCnicNumber(user)}
                            </td>
                            <td className="px-6 py-4">
                              <span
                                className={`px-3 py-1.5 rounded-full text-xs font-medium uppercase tracking-wider transition-colors duration-300 ${
                                  user.role === "Employee"
                                    ? "bg-blue-500/20 text-blue-300 group-hover:bg-blue-500/40"
                                    : user.role === "Customer"
                                    ? "bg-green-500/20 text-green-300 group-hover:bg-green-500/40"
                                    : "bg-purple-500/20 text-purple-300 group-hover:bg-purple-500/40"
                                }`}
                              >
                                {user.role}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* Pagination Controls */}
                  <div className="flex justify-between items-center mt-8 px-4">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className={`group rounded-xl p-2.5 ${theme.tailwind.bgHover1} transition-all duration-300 disabled:opacity-50`}
                    >
                      <ChevronsLeft className={`w-6 h-6 ${theme.tailwind.text} ${theme.tailwind.groupHover} ${theme.tailwind.groupDisabled}`} />
                    </button>
                    <div className="flex items-center space-x-4">
                      <span className={`${theme.tailwind.text} font-medium`}>
                        Page{" "}
                        <span className={`${theme.tailwind.text}`}>{currentPage}</span> of{" "}
                        {totalPages}
                      </span>
                    </div>
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className={`group rounded-xl p-2.5 ${theme.tailwind.bgHover1} transition-all duration-300 disabled:opacity-50`}
                    >
                      <ChevronsRight className={`w-6 h-6 ${theme.tailwind.text} ${theme.tailwind.groupHover} ${theme.tailwind.groupDisabled}`} />
                    </button>
                  </div>
                </>
              ) : (
                <div className={`text-center ${theme.tailwind.text} py-12`}>
                  <p className="text-xl font-medium">No users found</p>
                  <p className={`${theme.tailwind.text2} mt-2`}>
                    Try adjusting your search query
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InboxAndUsers;
