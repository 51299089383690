import axios from 'axios'

// const API_BASE_URL = 'http://localhost:5000/api'
// const API_BASE_URL = "http://192.168.100.148:5000/api";
// const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
// const API_BASE_URL =
//   process.env.REACT_APP_BACKEND_URL || "https://77.37.63.90:5000/api";

const API_BASE_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://alnoordevelopers.com/api'

const api = axios.create({
  baseURL: API_BASE_URL
})

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Authentication API
export const login = async credentials => {
  const response = await api.post('/auth/login', credentials)
  return response.data
}

export const register = async formData => {
  try {
    const response = await api.post('/auth/register', formData)
    return response.data
  } catch (error) {
    console.error('Error during registration:')

    // Log the full error
    console.error('Full error:', error)

    // Log the response data if available
    if (error.response) {
      console.error('Response status:', error.response.status)
      console.error('Response data:', error.response.data)
    }

    throw error
  }
}

export const verifyPassword = async ({ userId }) => {
  try {
    const response = await api.post('/auth/verify-password', { userId })
    return response.data
  } catch (error) {
    console.error('Error verifying password:', error.response || error)
    throw error.response ? error.response.data : error
  }
}
export const resetPassword = async ({ userId, newPassword }) => {
  try {
    const response = await api.post('/auth/reset-password', {
      userId,
      newPassword
    })
    return response.data
  } catch (error) {
    console.error('Error resetting password:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Admin API functions
export const getAdmin = async adminId => {
  try {
    const response = await api.get(`/auth/admin/${adminId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching admin:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

export const updateAdmin = async (adminId, updates) => {
  try {
    const response = await api.put(`/auth/admin/${adminId}`, updates)
    return response.data
  } catch (error) {
    console.error('Error updating admin:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

export const logout = async () => {
  const token = localStorage.getItem('token')
  const response = await api.post(
    '/auth/logout',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}

export const refreshToken = async () => {
  const response = await api.post('/auth/refresh-token')
  return response.data
}

export const updateUserStatus = async (userId, status) => {
  try {
    const response = await api.patch(`/users/${userId}/status`, { status })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

// User API calls
export const getAllUsers = async () => {
  try {
    const response = await api.get('/users')

    return response.data
  } catch (error) {
    console.error('Error in getAllUsers API:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

export const getUserById = async userId => {
  try {
    const response = await api.get(`/users/${userId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateUser = async (userId, updatedData) => {
  try {
    const response = await api.put(`/users/${userId}`, updatedData)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
export const deleteUser = async userId => {
  try {
    const response = await api.delete(`/users/${userId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const changeUserStatus = async (userId, status) => {
  try {
    const response = await api.patch(`/users/${userId}/status`, { status })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getUsersByRole = async role => {
  try {
    const response = await api.get(`/users/role/${role}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateUserSocieties = async (userId, societies) => {
  try {
    const response = await api.patch(`/users/${userId}/societies`, {
      societies
    })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getCurrentUser = async () => {
  try {
    const response = await api.get('/auth/current-user')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getCurrentUser1 = async () => {
  try {
    const response = await api.get('/users/me')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

// Customer API calls
export const getCustomerById = async () => {
  try {
    const response = await api.get('/customer/me')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateCustomer = async (customerId, updatedData) => {
  try {
    const response = await api.put(
      `/customer/customer/${customerId}`,
      updatedData
    )
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateOwnInfo = async updatedData => {
  try {
    const response = await api.put('/customer/me', updatedData)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getCustomerPlots = async customerId => {
  try {
    const response = await api.get(`customer/customer/${customerId}/plots`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getCustomerPayments = async () => {
  try {
    const response = await api.get('/customer/me/payments')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getCustomerInteractions = async () => {
  try {
    const response = await api.get('/customer/me/interactions')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

// Investor API calls
export const getAllInvestors = async () => {
  try {
    const response = await api.get('/investors')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getInvestorById = async investorId => {
  try {
    const response = await api.get(`/investors/${investorId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateInvestor = async (investorId, updatedData) => {
  try {
    const response = await api.put(`/investors/${investorId}`, updatedData)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const changeInvestorStatus = async (investorId, status) => {
  try {
    const response = await api.patch(`/investors/${investorId}/status`, {
      status
    })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const deleteInvestor = async investorId => {
  try {
    const response = await api.delete(`/investors/${investorId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getInvestorsBySociety = async societyId => {
  try {
    const response = await api.get(`/investors/society/${societyId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

// Employee API calls
export const getAllEmployees = async () => {
  try {
    const response = await api.get('/employees')
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getEmployeeById = async employeeId => {
  try {
    const response = await api.get(`/employees/${employeeId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateEmployee = async (employeeId, updatedData) => {
  try {
    const response = await api.put(`/employees/${employeeId}`, updatedData)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const changeEmployeeStatus = async (employeeId, status) => {
  try {
    const response = await api.patch(`/employees/${employeeId}/status`, {
      status
    })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const deleteEmployee = async employeeId => {
  try {
    const response = await api.delete(`/employees/${employeeId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getEmployeesBySociety = async societyId => {
  try {
    const response = await api.get(`/employees/society/${societyId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

/// Society API

// Create a new society (Admin only)
export const createSociety = async societyData => {
  const response = await api.post('/societies', societyData)
  return response.data
}

export const getAllSocieties = async () => {
  const response = await api.get('/societies')
  return response.data.societies
}

export const getSocietyById = async societyId => {
  const response = await api.get(`/societies/${societyId}`)
  return response.data
}

export const getSocietyByName = async societyName => {
  const response = await api.get(`/societies/name/${societyName}`)
  return response.data
}

export const updateSociety = async (societyId, updatedData) => {
  try {
    // Make sure societyId is defined before making the request
    if (!societyId) {
      throw new Error('Society ID is undefined')
    }
    console.log('Updating society with ID:', societyId) // Add logging
    const response = await api.put(`/societies/${societyId}`, updatedData)
    return response.data
  } catch (error) {
    console.error('Update society error:', error)
    throw error // Pass the original error for better debugging
  }
}

// Delete a society by ID (Admin only)
export const deleteSociety = async societyId => {
  const response = await api.delete(`/societies/${societyId}`)
  return response.data
}

// Add a plot to a society (Admin, Manager)
export const addPlotToSociety = async plotData => {
  const response = await api.post('/societies/add-plot', plotData)
  return response.data
}

// Remove a plot from a society (Admin, Manager)

// Edit a plot within a society
export const editPlotFromSociety = async (societyId, plotId, plotData) => {
  const response = await api.put(
    `/societies/edit-plot/${societyId}/plot/${plotId}`,
    plotData
  )
  return response.data
}

// Remove a plot from a society
export const removePlotFromSociety = async (societyId, plotId) => {
  try {
    const response = await api.delete(
      `/societies/remove-plot/${societyId}/plot/${plotId}`
    )
    return response.data // Ensure the API is returning the correct response
  } catch (error) {
    console.error('Error deleting plot:', error)
    throw error // Rethrow the error if any
  }
}

// Add a user to a society (Admin only)
export const addUserToSociety = async userData => {
  const response = await api.post('/societies/add-user', userData)
  return response.data
}

export const fetchPlotsAPI = async societyId => {
  const response = await api.get(`/societies/${societyId}/plots`)
  return response.data
}

// Update a specific plot's data
export const updatePlotAPI = async plotData => {
  const { plotId, ...updatedData } = plotData
  const response = await api.put(`/plots/${plotId}`, updatedData)
  return response.data
}
// Remove a user from a society (Admin only)
export const removeUserFromSociety = async (society_id, user_id) => {
  const response = await api.delete(
    `/societies/remove-user/${society_id}/${user_id}`
  )
  return response.data
}

// Booking API

export const createBooking = async bookingData => {
  try {
    const response = await api.post('/booking/create', bookingData) // Fixed path
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getBookingById = async bookingId => {
  try {
    const response = await api.get(`/booking/bookings/${bookingId}`) // Fixed path
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getAllBookings = async () => {
  try {
    const response = await api.get('/booking/bookings')
    console.log('Fetched bookings:', response.data)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateBookingStatus = async (bookingId, status) => {
  try {
    const response = await api.put(`/booking/bookings/${bookingId}/status`, {
      status
    }) // Fixed path
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateBooking = async (bookingId, bookingData) => {
  try {
    const response = await api.put(
      `/booking/bookings/${bookingId}`,
      bookingData
    )
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const canEditBooking = async bookingId => {
  try {
    const response = await api.get(`/booking/bookings/${bookingId}/can-edit`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const deleteBooking = async bookingId => {
  try {
    const response = await api.delete(`/booking/bookings/${bookingId}`) // Fixed path
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

// Installment API

export const payInstallment = async paymentData => {
  try {
    const response = await api.post('/booking/installments/pay', paymentData)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getInstallmentsForUser = async customerId => {
  try {
    const response = await api.get(`/booking/installments/user/${customerId}`)
    console.log(response)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getPaidInstallmentsForUser = async (customerId, plotId) => {
  try {
    const response = await api.get(
      `/booking/installments/user/${customerId}/plot/${plotId}/paid`
    )
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getInstallmentsByPlotId = async plotId => {
  try {
    const response = await api.get(`/booking/installments/plot/${plotId}`)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const getInstallmentsByPlotIdAndStatus = async (plotId, status) => {
  try {
    const response = await api.get(
      `/booking/installments/plot/${plotId}/status/${status}`
    )
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const checkOverdueInstallments = async plotId => {
  try {
    const endpoint = `/booking/installments/check-overdue${
      plotId ? `?plot_id=${plotId}` : ''
    }`
    const response = await api.get(endpoint)
    return response.data.overdueInstallments // Return only the required data
  } catch (error) {
    // Enhance error handling for consistency
    const errorMessage =
      error.response?.data?.message || 'Failed to fetch overdue installments'
    console.error('Error fetching overdue installments:', errorMessage)
    throw new Error(errorMessage) // Throw a consistent error message
  }
}

export const updateInstallmentStatus = async (installmentId, data) => {
  try {
    const response = await api.put(`/booking/installments/update`, {
      installment_id: installmentId,
      ...data
    })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

// Financial

export const createTransaction = async transactionData => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/financial/transactions`,
      transactionData
    )
    return response.data
  } catch (error) {
    console.error('Error creating transaction:', error)
    throw error
  }
}

export const getAllTransactions = async (
  page,
  limit,
  direction,
  societyId,
  description,
  startDate,
  endDate,
  sortOrder
) => {
  try {
    const params = {
      transaction_direction: direction,
      society_id: societyId,
      description,
      sortOrder
    }
    if (startDate) params.startDate = startDate
    if (endDate) params.endDate = endDate
    if (!startDate && !endDate) {
      params.page = page
      params.limit = limit
    }
    const response = await axios.get(`${API_BASE_URL}/financial/transactions`, {
      params
    })
    return response.data
  } catch (error) {
    console.error('Error fetching transactions:', error)
    throw error
  }
}

export const getTransactionById = async id => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/financial/transactions/${id}`
    )
    return response.data
  } catch (error) {
    console.error('Error fetching transaction:', error)
    throw error
  }
}

export const updateTransaction = async (id, transactionData) => {
  try {
    console.log('API Payload:', transactionData)
    const response = await axios.put(
      `${API_BASE_URL}/financial/transactions/${id}`,
      transactionData
    )
    return response.data
  } catch (error) {
    console.error('Error updating transaction:', error)
    throw error
  }
}

export const deleteTransaction = async id => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/financial/transactions/${id}`
    )
    return response.data
  } catch (error) {
    console.error('Error deleting transaction:', error)
    throw error
  }
}

// General Transaction
// Function to create a new transaction
export const createGeneralTransaction = async transactionData => {
  try {
    const response = await api.post('/generalTransaction', transactionData)
    console.log('Transaction created:', response.data) // Add logging to check the response
    return response.data
  } catch (error) {
    console.error('Error creating transaction:', error)
    throw error
  }
}

/// Function to fetch all transactions with optional filters
export const fetchGeneralTransactions = async (filters = {}) => {
  try {
    const response = await api.get('/generalTransaction', { params: filters })
    return response.data
  } catch (error) {
    console.error('Error fetching transactions:', error)
    throw error
  }
}

// Function to update a specific transaction by ID
export const updateGeneralTransaction = async (transactionId, updatedData) => {
  try {
    const response = await api.put(
      `/generalTransaction/${transactionId}`,
      updatedData
    )
    return response.data
  } catch (error) {
    console.error('Error updating transaction:', error)
    throw error
  }
}

// Function to delete a specific transaction by ID
export const deleteGeneralTransaction = async transactionId => {
  try {
    // Construct the correct URL and pass authentication headers if necessary
    const response = await api.delete(`/generalTransaction/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })

    return response.data // Return the response data
  } catch (error) {
    console.error('Error deleting transaction:', error)
    throw error // Ensure the error is thrown so it can be handled elsewhere
  }
}

// Function to fetch the transaction summary (total income, expense, and balance)
export const fetchGeneralTransactionSummary = async () => {
  try {
    const response = await api.get('/generalTransaction/summary') // Using 'api' to ensure proper authorization
    return response.data
  } catch (error) {
    console.error('Error fetching transaction summary:', error)
    throw error
  }
}

// Resell API

// Fetch all resells
export const fetchAllResells = async () => {
  try {
    const response = await api.get('/resell/resells')
    return response.data
  } catch (error) {
    console.error('Error fetching resells:', error)
    throw error
  }
}

// Fetch resell by ID
export const fetchResellById = async resellId => {
  try {
    const response = await api.get(`/resell/resells/${resellId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching resell by ID:', error)
    throw error
  }
}

// Create a new resell
export const createResell = async resellData => {
  try {
    const response = await api.post('/resell/resells', resellData)
    return response.data
  } catch (error) {
    console.error('Error creating resell:', error)
    throw error
  }
}

// Update a resell by ID
export const updateResell = async (resellId, resellData) => {
  try {
    const response = await api.put(`/resell/resells/${resellId}`, resellData)
    return response.data
  } catch (error) {
    console.error('Error updating resell:', error)
    throw error
  }
}

// Delete a resell by ID (soft delete)
export const deleteResell = async resellId => {
  try {
    const response = await api.delete(`/resell/resells/${resellId}`)
    return response.data
  } catch (error) {
    console.error('Error deleting resell:', error)
    throw error
  }
}

// Aggregation

export const fetchAggregatedData = async societyId => {
  try {
    const response = await api.get(`/dashboard/aggregated-data/${societyId}`)
    return response.data.data // Extract only the data key
  } catch (error) {
    console.error('Error fetching aggregated data:', error)
    throw error
  }
}

// Transfer a plot
export const transferPlot = async plotData => {
  const response = await api.post('/transferplot/', plotData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}` // Ensure the authorization token is passed
    }
  })
  return response.data
}

// Fetch all transfer plots
export const fetchTransferPlots = async (query = {}) => {
  const queryString = new URLSearchParams(query).toString()
  const response = await api.get(`/transferplot/?${queryString}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })
  return response.data
}

// Notification APIs

// Create a new notification
export const createNotification = async notificationData => {
  try {
    const response = await api.post('/notifications', notificationData)
    return response.data
  } catch (error) {
    console.error('Error creating notification:', error)
    throw error
  }
}

// Fetch notifications for a specific user
export const fetchUserNotifications = async userId => {
  try {
    const response = await api.get(`/notifications/user/${userId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching user notifications:', error)
    throw error
  }
}

// Fetch admin notifications
export const fetchAdminNotifications = async () => {
  try {
    const response = await api.get(`/notifications/admin`)
    return response.data
  } catch (error) {
    console.error('Error fetching admin notifications:', error)
    throw error
  }
}

// Mark a notification as read
export const markNotificationAsRead = async notificationId => {
  try {
    const response = await api.patch(`/notifications/${notificationId}/read`)
    return response.data
  } catch (error) {
    console.error('Error marking notification as read:', error)
    throw error
  }
}

// Delete a notification
export const deleteNotification = async notificationId => {
  try {
    const response = await api.delete(`/notifications/${notificationId}`)
    return response.data
  } catch (error) {
    console.error('Error deleting notification:', error)
    throw error
  }
}

// Salary API calls

// Pay salary to an employee
export const paySalary = async employeeId => {
  try {
    const response = await api.post(`/salary/pay-salary/${employeeId}`)
    return response.data
  } catch (error) {
    console.error('Error paying salary:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Generate salary slip for an employee
export const generateSalarySlip = async employeeId => {
  try {
    const response = await api.get(`/salary/generate-slip/${employeeId}`)
    return response.data
  } catch (error) {
    console.error('Error generating salary slip:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Get payment history of an employee
export const getPaymentHistory = async employeeId => {
  try {
    const response = await api.get(`/salary/payment-history/${employeeId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching payment history:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Update salary details of an employee
export const updateSalaryDetails = async (employeeId, salaryDetails) => {
  try {
    const response = await api.put(
      `/salary/update-salary/${employeeId}`,
      salaryDetails
    )
    return response.data
  } catch (error) {
    console.error('Error updating salary details:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Messaging API functions

// Send a message
export const sendMessage = async messageData => {
  try {
    const response = await api.post('/messages/send', messageData)
    return response.data
  } catch (error) {
    console.error('Error sending message:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

export const fetchMessages = async otherUserId => {
  try {
    const response = await api.get(`/messages/${otherUserId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching messages:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Mark messages as read
export const markMessagesAsRead = async otherUserId => {
  try {
    const response = await api.post('/messages/mark-as-read', { otherUserId })
    return response.data
  } catch (error) {
    console.error('Error marking messages as read:', error.response || error)
    throw error.response ? error.response.data : error
  }
}

// Scholarship API
export const createScholarship = async scholarshipData => {
  const response = await api.post('/scholarship/scholarships', scholarshipData)
  return response.data
}

export const getAllScholarships = async () => {
  const response = await api.get('/scholarship/scholarships')
  return response.data
}

export const updateScholarshipStatus = async (id, statusUpdate) => {
  const response = await api.put(
    `/scholarship/scholarships/${id}`,
    statusUpdate
  )
  return response.data
}

export const deleteScholarship = async id => {
  const response = await api.delete(`/scholarship/scholarships/${id}`)
  return response.data
}
export const getAllScholarshipsByStatus = async status => {
  const response = await api.get(`/scholarship/scholarships/status`, {
    params: { status }
  })
  return response.data
}

// Message API
export const sendMessageWithAttachments = async data => {
  const response = await api.post('/messages/attachments', data)
  return response.data
}

export const sendCapturedImage = async data => {
  const response = await api.post('/messages/capture', data)
  return response.data
}

export const getConversation = async userId => {
  const response = await api.get(`/messages/conversation/${userId}`)
  return response.data
}

export const getAllMessages = async () => {
  const response = await api.get('/messages/all_messages')
  return response.data
}

export const fetchEntriesAPI = async khataBy => {
  try {
    const response = await api.get(`/khata/${khataBy}`)
    return response.data.data
  } catch (error) {
    throw new Error(error.message)
  }
}

export const createEntryAPI = async entry => {
  try {
    const response = await api.post(`/khata`, entry)
    return response.data.data
  } catch (error) {
    throw new Error(error.message)
  }
}

export const deleteEntryAPI = async id => {
  try {
    await api.delete(`/khata/${id}`)
  } catch (error) {
    throw new Error(error.message)
  }
}

export const updateEntryAPI = async (id, updatedEntry) => {
  try {
    const response = await api.put(`/khata/${id}`, updatedEntry)
    return response.data.data
  } catch (error) {
    throw new Error(error.message)
  }
}

export const fetchAllAccountsAPI = async () => {
  try {
    const response = await api.get('/khata//accounts')
    return response.data.data
  } catch (error) {
    throw new Error(error.message)
  }
}
// Plot Cancellation APIs
export const plotCancellationApi = {
  /**
   * Cancel a plot booking
   * @param {Object} options - Cancellation options
   * @returns {Promise} Cancellation result
   */
  cancelPlot: async options => {
    const response = await api.post('/plot/cancel', options)
    return response.data
  },

  /**
   * Create a new installment plan for a partially refunded plot
   * @param {Object} options - Installment plan options
   * @returns {Promise} Created installment plan
   */
  createNewInstallmentPlan: async options => {
    const response = await api.post('/plot/installment-plan', options)
    return response.data
  },

  /**
   * Retrieve installments for canceled plots
   * @param {Object} filters - Query filters
   * @returns {Promise} Canceled plot installments
   */
  getCanceledPlotInstallments: async (filters = {}) => {
    const response = await api.get('/plot/canceled-installments', {
      params: filters
    })
    return response.data
  },

  /**
   * Get summary of canceled plot installments
   * @returns {Promise} Installment summary
   */
  getCanceledPlotInstallmentSummary: async () => {
    const response = await api.get('/plot/installment-summary')
    return response.data
  }
}

export default api
