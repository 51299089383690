import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkActiveUser } from "../store/slices/authSlice";
import {
  fetchInstallmentsByPlotId,
  fetchPaidInstallmentsForUser,
  fetchOverdueInstallments,
} from "../store/slices/installmentSlice";
import { BookCopy, HandCoins, ReceiptText, Sparkles, X } from "lucide-react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import CompletedInstallmentsPrint from "./util/CompletedInstallmentsPaid";
import InstallmentBookingSlip from "./util/InstallmentBookingPaid";
import { getAllBookings } from "../store/slices/bookingSlice";
import { fetchSocieties } from "../store/slices/societySlice";

const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
  backdropFilter: "blur(4px)",
  padding: "1rem",
};

const modalStyle = {
  position: "relative",
  background: "#ffffff",
  borderRadius: "8px",
  padding: "20px",
  width: "100%",
  maxWidth: "800px",
  maxHeight: "90vh",
  overflow: "auto",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
  zIndex: 1001,
};

const printStyles = `
  @media print {
    .print-btn, .close-btn {
      display: none !important;
    }
  }
`;
const PaidInstallments = () => {
  const dispatch = useDispatch();
  const { activeUser, isAuthenticated, error } = useSelector(
    (state) => state.auth
  );
  const { bookings = [] } = useSelector((state) => state.booking);

  // console.log(activeUser);
  const { installments, error: installmentsError } = useSelector(
    (state) => state.installments
  );
  const [totals, setTotals] = useState({
    totalPaidAmount: 0,
    totalPendingInstallmentsAmount: 0,
    totalPlotAmount: 0,
  });
  const { societies } = useSelector((state) => state.societies);

  const [selectedPlot, setSelectedPlot] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [isSlipModalOpen, setIsSlipModalOpen] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [selectedInstallments, setSelectedInstallments] = useState([]);

  const [
    isCompletedInstallmentsPrintModalOpen,
    setIsCompletedInstallmentsPrintModalOpen,
  ] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(checkActiveUser());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    dispatch(getAllBookings());
    dispatch(fetchSocieties());
    dispatch(fetchOverdueInstallments());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated && activeUser && activeUser.id) {
      const customerId = activeUser.id;

      if (
        activeUser.plots &&
        Array.isArray(activeUser.plots) &&
        activeUser.plots.length > 0
      ) {
        activeUser.plots.forEach((plot) => {
          const plotId = plot._id;

          dispatch(fetchPaidInstallmentsForUser({ customerId, plotId }))
            .unwrap()
            .then((result) => {
              setTotals((prevTotals) => ({
                ...prevTotals,
                [plotId]: {
                  totalPaidAmount: result.totalPaidAmount || 0,
                  totalPendingInstallmentsAmount:
                    result.totalPendingInstallmentsAmount || 0,
                  totalPlotAmount: result.totalPlotAmount || 0,
                },
              }));
            })
            .catch((error) => {
              console.error("Detailed Dispatch Error:", error);
              alert("There was an issue fetching your data.");
            });
        });
      } else {
        console.error("No plots available for this user.", activeUser);
      }
    } else {
      console.error(
        "Selected customer ID is invalid or user data is incomplete.",
        {
          isAuthenticated,
          activeUser,
          userId: activeUser?.id,
        }
      );
    }
  }, [dispatch, isAuthenticated, activeUser]);

  const handleViewSlip = (installment) => {
    setSelectedInstallment(installment);

    setSelectedInstallments([installment]);

    setIsSlipModalOpen(true);
  };
  useEffect(() => {
    console.log("Updated Selected Installments:", selectedInstallments);
  }, [selectedInstallments]);

  const handleCloseModal = () => {
    setIsSlipModalOpen(false);
    setSelectedInstallment(null);
  };

  const enrichedInstallments = selectedInstallments.map((installment) => {
    const plotBooking = bookings.find(
      (booking) => booking.plot_id?._id === installment.plot_id
    );
    console.log("Matching Booking:", plotBooking);

    const customer = plotBooking?.customer_id || {};
    return {
      ...installment,
      plotDetails: {
        plotNumber: selectedPlot?.plot_number || "N/A",
        plotType: selectedPlot?.plot_type || "N/A",
        block: selectedPlot?.block || "N/A",
        size: selectedPlot?.size || "N/A",
      },
      customerDetails: {
        name: customer?.name || "N/A",
        phone: customer?.contactInfo?.phone || "N/A",
        cnic: customer?.contactInfo?.cnic || "N/A",
        address: customer?.contactInfo?.address || "N/A",
      },
    };
  });

  const handlePlotClick = (plotId) => {
    dispatch(fetchInstallmentsByPlotId(plotId));

    const plot = activeUser.plots.find((plot) => plot._id === plotId);

    if (plot) {
      const society = societies.find((soc) => soc._id === plot.society_id);
      setSelectedPlot({ ...plot, society });
    }
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-red-400 bg-red-400/10 px-6 py-3 rounded-lg backdrop-blur-sm border border-red-400/20">
          Error: {error}
        </div>
      </div>
    );
  }

  if (!activeUser) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-teal-300/70 bg-slate-800/50 px-6 py-3 rounded-lg backdrop-blur-sm">
          No user data available.
        </div>
      </div>
    );
  }

  // console.log(installments);

  const userPlots = activeUser.plots || [];

  const filteredInstallments = installments.filter(
    (installment) =>
      installment.plot_id === selectedPlot?._id &&
      ["Pending", "Partially Paid", "Overdue"].includes(installment.status)
  );

  const completeInstallments = installments.filter(
    (installment) =>
      installment.plot_id === selectedPlot?._id &&
      installment.status === "Completed"
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "text-yellow-400 bg-yellow-400/10 border-yellow-400/20";
      case "Partially Paid":
        return "text-blue-400 bg-blue-400/10 border-blue-400/20";
      case "Overdue":
        return "text-red-400 bg-red-400/10 border-red-400/20";
      case "Completed":
        return "text-emerald-400 bg-emerald-400/10 border-emerald-400/20";
      default:
        return "text-teal-400 bg-teal-400/10 border-teal-400/20";
    }
  };
  const getStatusColorR = (status) => {
    return "text-yellow-400 bg-yellow-400/10 border-yellow-400/20";
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-800 via-slate-900 to-slate-800 text-teal-300 p-6 md:p-8 lg:p-12">
      {activeUser && activeUser.role === "Customer" && (
        <div className="space-y-8">
          <div className="relative min-h-[200px] p-8 overflow-hidden rounded-2xl bg-gradient-to-br from-gray-900 to-gray-800">
            {/* Animated gradient orbs */}
            <div className="absolute inset-0 overflow-hidden">
              <div className="absolute -left-10 -top-10 w-96 h-96 bg-gradient-to-r from-teal-500/30 to-emerald-500/30 rounded-full blur-3xl animate-pulse"></div>
              <div className="absolute -right-10 -top-10 w-96 h-96 bg-gradient-to-r from-blue-500/30 to-indigo-500/30 rounded-full blur-3xl animate-pulse delay-1000"></div>
              <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full h-48 bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-rose-500/20 blur-3xl transform rotate-12 animate-pulse delay-700"></div>

              {/* Mesh gradient overlay */}
              <div className="absolute inset-0 bg-gradient-to-br from-black/10 to-black/30 backdrop-blur-sm"></div>
            </div>

            {/* Glass card effect */}
            <div className="relative z-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="backdrop-blur-xl bg-white/10 rounded-xl p-6 border border-white/10 shadow-2xl"
              >
                <div className="flex items-center justify-between gap-6">
                  <div className="flex items-center gap-6">
                    {/* Avatar section with glow effect */}
                    <div className="relative group">
                      <div className="absolute inset-0 bg-gradient-to-r from-teal-500 to-blue-500 rounded-full blur-xl opacity-75 group-hover:opacity-100 transition-opacity duration-300"></div>
                      <div className="relative h-16 w-16 rounded-full bg-gradient-to-r from-teal-500 to-blue-500 flex items-center justify-center border-2 border-white/20 shadow-xl">
                        <Sparkles className="w-8 h-8 text-white animate-pulse" />
                      </div>
                    </div>

                    {/* Text content with animations */}
                    <div className="space-y-2">
                      <motion.h2
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        className="text-5xl font-bold"
                      >
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-teal-400 via-white to-blue-400 animate-gradient">
                          Welcome, {activeUser?.additionalData?.name || "Guest"}
                        </span>
                      </motion.h2>

                      <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                        className="flex items-center gap-3"
                      >
                        <div className="px-4 py-1 rounded-full bg-gradient-to-r from-teal-500/20 to-blue-500/20 border border-teal-500/20 backdrop-blur-sm">
                          <p className="text-teal-300 font-medium">
                            Owner of {userPlots.length} plot
                            {userPlots.length !== 1 && "s"}
                          </p>
                        </div>
                        <div className="h-2 w-2 rounded-full bg-teal-400 animate-pulse"></div>
                        <span className="text-gray-400">Active Now</span>
                      </motion.div>
                    </div>
                  </div>

                  {/* Print Installments button moved to the right */}
                  <div className="flex items-center">
                    <button
                      onClick={() =>
                        setIsCompletedInstallmentsPrintModalOpen(true)
                      }
                      className="px-4 py-2 bg-teal-500/10 text-teal-300 hover:bg-teal-500/20 ring-1 ring-teal-400/30 rounded-lg transition-all flex items-center space-x-2"
                    >
                      <BookCopy size={16} />
                      <span>Print Installments</span>
                    </button>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>

          {userPlots.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {userPlots.map((plot) => {
                const society = activeUser.societies.find(
                  (society) => society._id === plot.society_id
                );
                const plotTotals = totals[plot._id] || {};
                console.log(
                  "Plot Total Amount for Plot ID:",
                  plot._id,
                  "=",
                  plotTotals.totalPlotAmount
                );

                return (
                  <div
                    key={plot._id}
                    className={`relative group cursor-pointer transition-all duration-500 ease-in-out ${
                      selectedPlot?._id === plot._id
                        ? "bg-gradient-to-r from-teal-600/30 to-blue-600/30 "
                        : "bg-gray-800/20 hover:bg-teal-800/30 "
                    }`}
                    onClick={() => handlePlotClick(plot._id)}
                    onMouseEnter={() => setHoveredCard(plot._id)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-teal-300/10 to-emerald-300/10 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    <div className="relative bg-slate-800/50 backdrop-blur-md p-6 rounded-xl border border-teal-300/10 shadow-lg">
                      <div className="absolute inset-0 bg-gradient-to-br from-teal-300/5 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-xl" />
                      <div className="flex items-center justify-between mb-4">
                        <h4 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-emerald-300">
                          Plot {plot.plot_number}
                        </h4>
                        <span
                          className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                            plot.status
                          )}`}
                        >
                          {(() => {
                            const booking = bookings.find(
                              (booking) => booking.plot_id?._id === plot._id
                            );
                            return booking?.booking_date ? (
                              <div>
                                Booking Date:{" "}
                                {new Date(
                                  booking.booking_date
                                ).toLocaleDateString()}
                              </div>
                            ) : (
                              <div>No Booking Date</div>
                            );
                          })()}
                        </span>
                      </div>
                      <div className="space-y-2 text-teal-300/80">
                        <p className="flex items-center justify-between">
                          <span>Category</span>
                          <span className="font-semibold">{plot.category}</span>
                        </p>
                        <p className="flex items-center justify-between">
                          <span>Price</span>
                          <span className="font-semibold">
                            PKR {plot.price.toLocaleString()}
                          </span>
                        </p>
                        <p className="flex items-center justify-between">
                          <span>Status</span>
                          <span
                            className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                              plot.status
                            )}`}
                          >
                            {plot.status}
                          </span>
                        </p>
                        <p className="flex items-center justify-between">
                          <span>Society</span>
                          <span className="font-semibold">
                            {society ? society.name : "Unknown"}
                          </span>
                        </p>
                        <p className="flex justify-between text-gray-300">
                          <span className="flex items-center">
                            <BookCopy
                              size={16}
                              className="mr-2 text-teal-400"
                            />
                            Total Paid Amount:
                          </span>
                          <span className="text-white font-semibold">
                            PKR{" "}
                            {Number(
                              plotTotals.totalPaidAmount || 0
                            ).toLocaleString()}
                          </span>
                        </p>
                        <p className="flex justify-between text-gray-300">
                          <span className="flex items-center">
                            <HandCoins
                              size={16}
                              className="mr-2 text-teal-400"
                            />
                            Total Pending Installments Amount:
                          </span>
                          <span className="text-white font-semibold">
                            PKR{" "}
                            {Number(
                              plotTotals.totalPendingInstallmentsAmount || 0
                            ).toLocaleString()}
                          </span>
                        </p>
                        <p className="flex justify-between text-gray-300">
                          <span className="flex items-center">
                            <ReceiptText
                              size={16}
                              className="mr-2 text-teal-400"
                            />
                            Total Plot Amount:
                          </span>
                          <span className="text-white font-semibold">
                            PKR{" "}
                            {Number(
                              plotTotals.totalPlotAmount || 0
                            ).toLocaleString()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-12 bg-slate-800/50 backdrop-blur-sm rounded-xl border border-teal-300/10">
              <p className="text-teal-300/70">
                No plots available for this customer.
              </p>
            </div>
          )}
          <div className="mt-12 space-y-8">
            {selectedPlot && (
              <h3 className="text-3xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-teal-300 to-emerald-300 mb-8">
                Installment Details for Plot {selectedPlot.plot_number}
              </h3>
            )}

            {filteredInstallments.length > 0 && (
              <div className="bg-slate-800/50 backdrop-blur-md p-6 rounded-xl border border-teal-300/10 shadow-lg">
                <h4 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-emerald-300 mb-6">
                  Current Installments
                </h4>
                <div className="overflow-x-auto">
                  <table className="w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 first:rounded-tl-lg last:rounded-tr-lg text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Sr No
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 first:rounded-tl-lg last:rounded-tr-lg text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Installment #
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Amount
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Status
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Due Date
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Remaining Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-teal-300/10">
                      {filteredInstallments.map((installment, index) => (
                        <tr
                          key={installment._id}
                          className="group hover:bg-slate-700/70 transition-all duration-200"
                        >
                          <td className="p-4 text-slate-300">{index + 1}</td>
                          <td className="p-4 text-slate-300">
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium text-slate-400 bg-slate-400/10 border-slate-400/20`}
                            >
                              Installment no.{installment.installment_number}
                            </span>
                          </td>
                          <td className="p-4 text-slate-300">
                            PKR {installment.amount.toLocaleString()}
                          </td>
                          <td className="p-4">
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                                installment.status
                              )}`}
                            >
                              {installment.status}
                            </span>
                          </td>
                          <td className="p-4 text-slate-300">
                            {new Date(
                              installment.due_date
                            ).toLocaleDateString()}
                          </td>
                          <td className="p-4 text-slate-300">
                            PKR{" "}
                            {installment.remaining_amount
                              ? installment.remaining_amount.toLocaleString()
                              : 0}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {completeInstallments.length > 0 && (
              <div className="bg-slate-800/50 backdrop-blur-md p-6 rounded-xl border border-teal-300/10 shadow-lg">
                <div className="flex justify-between items-center mb-8">
                  <h3 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-300 to-emerald-300 mb-6">
                    Paid Installments
                  </h3>
                </div>

                <div className="overflow-x-auto">
                  <table className="w-full border-separate border-spacing-0">
                    <thead>
                      <tr>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 first:rounded-tl-lg last:rounded-tr-lg text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Sr No
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 first:rounded-tl-lg last:rounded-tr-lg text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Installment #
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Paid Amount
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Status
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Paid Date
                        </th>

                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Receipt no
                        </th>
                        <th className="sticky top-0 bg-slate-800/90 backdrop-blur-sm text-left p-4 text-teal-300/80 font-semibold border-b border-teal-300/10">
                          Print
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-teal-300/10">
                      {completeInstallments.map((installment, index) => (
                        <tr
                          key={installment._id}
                          className="group hover:bg-slate-700/70 transition-all duration-200"
                        >
                          <td className="p-4 text-slate-300">{index + 1}</td>
                          <td className="p-4 text-slate-300">
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium text-slate-400 bg-slate-400/10 border-slate-400/20`}
                            >
                              Installment no.{installment.installment_number}
                            </span>
                          </td>
                          <td className="p-4 text-slate-300">
                            PKR{" "}
                            {installment.paid_amount
                              ? installment.paid_amount.toLocaleString()
                              : "N/A"}
                          </td>
                          <td className="p-4">
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                                installment.status
                              )}`}
                            >
                              {installment.status}
                            </span>
                          </td>
                          <td className="p-4 text-slate-300">
                            {new Date(
                              installment.due_date
                            ).toLocaleDateString()}
                          </td>

                          <td className="p-4">
                            <span
                              className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${getStatusColorR()}`}
                            >
                              {installment.receipt_no}
                            </span>
                          </td>
                          <td className="py-4 px-6">
                            <div className="flex gap-2">
                              <button
                                onClick={() => handleViewSlip(installment)}
                                className="px-4 py-2 rounded-lg text-sm font-medium bg-teal-500/10 text-teal-300 hover:bg-teal-500/20 ring-1 ring-teal-400/30 transition-all"
                              >
                                View Slip
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {installmentsError && (
        <div className="mt-8 text-center">
          <div className="inline-block text-red-400 bg-red-400/10 px-6 py-3 rounded-lg backdrop-blur-sm border border-red-400/20">
            Error fetching installments: {installmentsError}
          </div>
        </div>
      )}

      <>
        <style>{printStyles}</style>
        <Modal
          isOpen={isSlipModalOpen}
          onRequestClose={handleCloseModal}
          style={{ overlay: modalOverlayStyle, content: modalStyle }}
        >
          <div className="relative max-h-[90vh] overflow-y-auto bg-white rounded-lg">
            <button
              onClick={handleCloseModal}
              className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full"
            >
              <X size={20} />
            </button>
            <InstallmentBookingSlip
              installment={selectedInstallment}
              companyInfo={selectedPlot}
              payment={totals}
            />
          </div>
        </Modal>

        {/* Modal for Completed Installments Print */}
        <Modal
          isOpen={isCompletedInstallmentsPrintModalOpen}
          onRequestClose={() => setIsCompletedInstallmentsPrintModalOpen(false)}
          style={{
            overlay: modalOverlayStyle,
            content: modalStyle,
          }}
        >
          <div className="relative max-h-[90vh] overflow-y-auto">
            <button
              onClick={() => setIsCompletedInstallmentsPrintModalOpen(false)}
              className="absolute right-1 -top-0 p-2 bg-slate-800 hover:bg-slate-800 rounded-full"
            >
              <X size={20} />
            </button>
            {selectedPlot && (
              <CompletedInstallmentsPrint
                installments={installments}
                totalPlotAmount={
                  totals[selectedPlot?._id]?.totalPlotAmount || 0
                }
                companyInfo={selectedPlot}
              />
            )}
          </div>
        </Modal>
      </>
    </div>
  );
};

export default PaidInstallments;
