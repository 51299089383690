import React, { useEffect, useState } from "react";
import printJS from "print-js";
import {
  FileText,
  Building,
  CreditCard,
  Calendar,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

const CompletedInstallmentsPrint = ({
  installments,

  companyInfo,
  totalPlotAmount,
}) => {
  const [pendingAndOverdue, setPendingAndOverdue] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [activeTab, setActiveTab] = useState("completed");

  console.log("companyInfo", companyInfo);
  useEffect(() => {
    setPendingAndOverdue(
      installments.filter((inst) =>
        ["Pending", "Overdue"].includes(inst.status)
      )
    );
    setCompleted(installments.filter((inst) => inst.status === "Completed"));
  }, [installments]);
  console.log("Total Installment:", installments);

  const formatCNIC = (cnic) => {
    if (!cnic) return "";
    return cnic.replace(/^(\d{5})(\d{7})(\d{1})$/, "$1-$2-$3");
  };

  const societyImage =
    companyInfo?.society?.society_image || "default-image.jpg";

  const handlePrint = () => {
    const printContent = `
    <div style="font-family: 'Inter', system-ui, sans-serif; margin: 0; padding: 20px; max-width: 1000px; margin: 0 auto;">
      <!-- Modern Header with Company Info -->
      <div style="background: linear-gradient(135deg, #1e40af, #3b82f6); color: white; padding: 25px; border-radius: 16px; margin-bottom: 24px; box-shadow: 0 10px 25px rgba(59, 130, 246, 0.2);">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div style="display: flex; align-items: center; gap: 20px;">
            <img src="${societyImage}" alt="Society Logo" style="width: 80px; height: 80px; border-radius: 16px; object-fit: cover; border: 4px solid rgba(255,255,255,0.2);" />
            <div>
              <h1 style="margin: 0; font-size: 28px; font-weight: 800; letter-spacing: -0.5px;">${
                companyInfo?.society?.name || "Property Management"
              }</h1>
              <p style="margin: 5px 0 0; opacity: 0.9; font-size: 14px;">${
                companyInfo?.society?.location || ""
              }</p>
            </div>
          </div>
          <div style="text-align: right;">
            <div style="background: rgba(255,255,255,0.1); padding: 12px 20px; border-radius: 12px; backdrop-filter: blur(10px);">
              <p style="margin: 0; font-size: 13px; opacity: 0.9;">Total Plot Value</p>
              <p style="margin: 4px 0 0; font-size: 24px; font-weight: 700;">PKR ${totalPlotAmount.toLocaleString()}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Modern Info Cards Grid -->
      <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; margin-bottom: 24px;">
        <!-- Customer Details Card -->
        <div style="background: white; padding: 24px; border-radius: 16px; box-shadow: 0 4px 20px rgba(0,0,0,0.05); border: 1px solid #e5e7eb;">
          <h3 style="margin: 0 0 16px; font-size: 18px; color: #1e40af; display: flex; align-items: center; gap: 8px;">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            Customer Information
          </h3>
          ${renderDetailCard(installments[0]?.customer_id)}

        </div>

        <!-- Property Details Card -->
        <div style="background: white; padding: 24px; border-radius: 16px; box-shadow: 0 4px 20px rgba(0,0,0,0.05); border: 1px solid #e5e7eb;">
          <h3 style="margin: 0 0 16px; font-size: 18px; color: #1e40af; display: flex; align-items: center; gap: 8px;">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
            Property Details
          </h3>
          ${renderPropertyDetails(companyInfo)}
        </div>
      </div>

      <!-- Completed Installments Section -->
      <div style="margin-bottom: 24px;">
        <h3 style="margin: 0 0 16px; font-size: 20px; color: #1e40af; display: flex; align-items: center; gap: 8px;">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
          Completed Installments
        </h3>
        <div style="background: white; border-radius: 16px; overflow: hidden; box-shadow: 0 4px 20px rgba(0,0,0,0.05); border: 1px solid #e5e7eb;">
          <table style="width: 100%; border-collapse: separate; border-spacing: 0; font-size: 14px;">
            <thead>
              <tr style="background: #f8fafc;">
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Installment No.</th>
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Amount Paid</th>
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Payment Date</th>
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Status</th>
              </tr>
            </thead>
            <tbody>
              ${completed
                .map(
                  (inst, index) => `
                <tr style="background: ${
                  index % 2 === 0 ? "#fff" : "#f8fafc"
                };">
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb; text-align: center;">${
                    inst.installment_number
                  }</td>
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb;">PKR ${inst.paid_amount.toLocaleString()}</td>
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb;">${new Date(
                    inst.payment_date
                  ).toLocaleDateString()}</td>
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb;">
                    <span style="background: #dcfce7; color: #166534; padding: 6px 12px; border-radius: 20px; font-size: 12px; font-weight: 500;">
                      Completed
                    </span>
                  </td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </div>
      </div>

      <!-- Remaining Installments Section -->
      <div style="margin-bottom: 24px;">
        <h3 style="margin: 0 0 16px; font-size: 20px; color: #1e40af; display: flex; align-items: center; gap: 8px;">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <circle cx="12" cy="12" r="10"></circle>
            <polyline points="12 6 12 12 16 14"></polyline>
          </svg>
          Remaining Installments
        </h3>
        <div style="background: white; border-radius: 16px; overflow: hidden; box-shadow: 0 4px 20px rgba(0,0,0,0.05); border: 1px solid #e5e7eb;">
          <table style="width: 100%; border-collapse: separate; border-spacing: 0; font-size: 14px;">
            <thead>
              <tr style="background: #f8fafc;">
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Installment No.</th>
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Amount Due</th>
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Due Date</th>
                <th style="padding: 16px; text-align: left; border-bottom: 2px solid #e5e7eb; color: #1e40af; font-weight: 600;">Status</th>
              </tr>
            </thead>
            <tbody>
              ${pendingAndOverdue
                .map(
                  (inst, index) => `
                <tr style="background: ${
                  index % 2 === 0 ? "#fff" : "#f8fafc"
                };">
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb; text-align: center;">${
                    inst.installment_number
                  }</td>
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb;">PKR ${inst.remaining_amount.toLocaleString()}</td>
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb;">${new Date(
                    inst.due_date
                  ).toLocaleDateString()}</td>
                  <td style="padding: 16px; border-bottom: 1px solid #e5e7eb;">
                    <span style="background: ${
                      inst.status === "Pending" ? "#fef9c3" : "#fee2e2"
                    };
                                color: ${
                                  inst.status === "Pending"
                                    ? "#854d0e"
                                    : "#991b1b"
                                };
                                padding: 6px 12px;
                                border-radius: 20px;
                                font-size: 12px;
                                font-weight: 500;">
                      ${inst.status}
                    </span>
                  </td>
                </tr>
              `
                )
                .join("")}
            </tbody>
          </table>
        </div>
      </div>

      <!-- Summary Cards -->
      <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 20px; margin-bottom: 24px;">
        ${renderSummaryCard(
          "Total Installments",
          installments.length,
          "#3b82f6",
          "#eff6ff"
        )}
        ${renderSummaryCard(
          "Completed",
          completed.length,
          "#10b981",
          "#f0fdf4"
        )}
        ${renderSummaryCard(
          "Remaining",
          pendingAndOverdue.length,
          "#f59e0b",
          "#fef3c7"
        )}
      </div>

      <!-- Footer with Signatures -->
      <div style="display: flex; justify-content: space-between; align-items: flex-end; margin-top: 40px; padding-top: 20px; border-top: 2px solid #e5e7eb;">
        <div style="text-align: center; flex: 1;">
          <div style="width: 120px; height: 120px; margin: 0 auto; border: 3px solid #3b82f6; border-radius: 50%; display: flex; justify-content: center; align-items: center; opacity: 0.7;">
            <span style="color: #1e40af; font-size: 12px; font-weight: 500;">OFFICIAL STAMP</span>
          </div>
        </div>
        <div style="text-align: center; flex: 1;">
          <div style="width: 200px; margin: 0 auto; border-top: 2px solid #3b82f6; padding-top: 8px;">
            <p style="margin: 0; color: #1e40af; font-size: 12px; font-weight: 500;">Authorized Signature</p>
          </div>
        </div>
        <div style="text-align: center; flex: 1;">
          <div style="width: 200px; margin: 0 auto; border-top: 2px solid #3b82f6; padding-top: 8px;">
            <p style="margin: 0; color: #1e40af; font-size: 12px; font-weight: 500;">Customer Signature</p>
          </div>
        </div>
      </div>
    </div>
  `;

    // Helper functions remain the same but with enhanced styling
    function renderDetailCard(customerDetails) {
      console.log(customerDetails);
      return `
      <div style="display: grid; gap: 16px;">
        <div style="background: #f8fafc; padding: 16px; border-radius: 12px;">
          <p style="margin: 0; color: #64748b; font-size: 13px;">Full Name</p>
          <p style="margin: 4px 0 0; font-weight: 600; color: #1e293b;">${
            customerDetails?.name || "N/A"
          }</p>
        </div>
        <div style="background: #f8fafc; padding: 16px; border-radius: 12px;">
          <p style="margin: 0; color: #64748b; font-size: 13px;">Contact Number</p>
          <p style="margin: 4px 0 0; font-weight: 600; color: #1e293b;">${
            customerDetails?.contactInfo.phone || "N/A"
          }</p>
        </div>
        <div style="background: #f8fafc; padding: 16px; border-radius: 12px;">
          <p style="margin: 0; color: #64748b; font-size: 13px;">CNIC Number</p>
          <p style="margin: 4px 0 0; font-weight: 600; color: #1e293b;">${
            formatCNIC(customerDetails?.contactInfo.cnic) || "N/A"
          }</p>
        </div>
      </div>
    `;
    }

    function renderPropertyDetails(companyInfo) {
      return `
      <div style="display: grid; gap: 16px;">
        <div style="background: #f8fafc; padding: 16px; border-radius: 12px;">
          <p style="margin: 0; color: #64748b; font-size: 13px;">Plot Information</p>
          <p style="margin: 4px 0 0; font-weight: 600; color: #1e293b;">${
            companyInfo.plot_type
          } - Plot #${companyInfo.plot_number}</p>
          <p style="margin: 4px 0 0; color: #64748b;">${companyInfo.block}</p>
          <p style="margin: 4px 0 0; color: #64748b;">${
            companyInfo.size
          } Marla</p>
        </div>

        <div style="background: #f8fafc; padding: 16px; border-radius: 12px;">
          <p style="margin: 0; color: #64748b; font-size: 13px;">Payment Schedule</p>
          <div style="margin-top: 8px; display: grid; gap: 8px;">
            <div style="display: flex; justify-content: between; align-items: center;">
              <span style="color: #64748b;">Total Installments:</span>
              <span style="font-weight: 600; color: #1e293b;">${
                installments.length
              }</span>
            </div>
            <div style="display: flex; justify-content: between; align-items: center;">
              <span style="color: #64748b;">Completed:</span>
              <span style="font-weight: 600; color: #10b981;">${
                completed.length
              }</span>
            </div>
            <div style="display: flex; justify-content: between; align-items: center;">
              <span style="color: #64748b;">Remaining:</span>
              <span style="font-weight: 600; color: #f59e0b;">${
                pendingAndOverdue.length
              }</span>
            </div>
          </div>
        </div>

        <div style="background: #ecfdf5; padding: 16px; border-radius: 12px; border: 1px solid #6ee7b7;">
          <p style="margin: 0; color: #047857; font-size: 13px;">Total Amount</p>
          <p style="margin: 4px 0 0; font-weight: 700; font-size: 20px; color: #065f46;">
            PKR ${totalPlotAmount.toLocaleString()}
          </p>
          <p style="margin: 4px 0 0; color: #059669; font-size: 12px;">
            ${completed.length} of ${installments.length} installments paid
          </p>
        </div>
      </div>
    `;
    }

    function renderSummaryCard(
      label,
      value,
      accentColor = "#3b82f6",
      bgColor = "#eff6ff"
    ) {
      const formattedValue =
        typeof value === "number" ? value.toLocaleString() : value;

      return `
      <div style="background: ${bgColor}; padding: 20px; border-radius: 16px; text-align: center; box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1); border: 1px solid ${accentColor}20;">
        <p style="margin: 0; color: ${accentColor}; font-size: 14px; text-transform: uppercase; letter-spacing: 0.5px; font-weight: 500;">${label}</p>
        <p style="margin: 8px 0 0; font-size: 24px; font-weight: 700; color: ${accentColor};">${formattedValue}</p>
      </div>
    `;
    }

    // Print configuration
    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
      @page {
        size: A4 portrait;
        margin: 15mm;
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact !important;
          print-color-adjust: exact !important;
        }
      }
      body {
        margin: 0;
        font-family: 'Inter', system-ui, -apple-system, sans-serif;
        line-height: 1.5;
        color: #1e293b;
      }
      table {
        page-break-inside: auto;
      }
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      thead {
        display: table-header-group;
      }
      tfoot {
        display: table-footer-group;
      }
    `,
      targetStyles: ["*"],
      documentTitle: `Installments_Summary_${new Date().toLocaleDateString()}`,
    });
  };
  const installmentsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const getCurrentInstallments = () => {
    const currentArray =
      activeTab === "completed" ? completed : pendingAndOverdue;
    const indexOfLast = currentPage * installmentsPerPage;
    const indexOfFirst = indexOfLast - installmentsPerPage;
    return currentArray.slice(indexOfFirst, indexOfLast);
  };

  const totalPages = Math.ceil(
    (activeTab === "completed" ? completed.length : pendingAndOverdue.length) /
      installmentsPerPage
  );

  return (
    <div className="flex flex-col h-full max-h-screen">
      {/* Fixed Header Section */}
      <div className="flex-none">
        <div className="bg-gradient-to-r from-blue-600 via-blue-700 to-indigo-800 p-8 rounded-xl shadow-lg">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            <div className="flex items-center space-x-4">
              <div className="p-3 bg-white/10 rounded-xl">
                <CheckCircle className="w-8 h-8 text-white" strokeWidth={1.5} />
              </div>
              <div>
                <h1 className="text-3xl font-bold text-white tracking-tight">
                  Installments Dashboard
                </h1>
                <p className="text-blue-100 mt-1">
                  Track and manage your payment progress
                </p>
              </div>
            </div>
            <div className="bg-white/10 rounded-xl p-4">
              <p className="text-blue-100 text-sm">Total Plot Value</p>
              <p className="text-2xl font-bold text-white mt-1">
                PKR {totalPlotAmount.toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4 bg-white">
          <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl p-4">
            <Calendar
              className="w-5 h-5 text-blue-600 mb-4"
              strokeWidth={1.5}
            />
            <p className="text-blue-600 font-medium">Total Installments</p>
            <p className="text-lg font-bold text-blue-800 mt-2">
              {installments.length}
            </p>
          </div>
          <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-xl p-4">
            <CreditCard
              className="w-5 h-5 text-green-600 mb-4"
              strokeWidth={1.5}
            />
            <p className="text-green-600 font-medium">Amount Paid</p>
            <p className="text-lg font-bold text-green-800 mt-2">
              PKR{" "}
              {(
                totalPlotAmount *
                (completed.length / installments.length)
              ).toLocaleString()}
            </p>
          </div>
          <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-xl p-4">
            <Building
              className="w-5 h-5 text-purple-600 mb-4"
              strokeWidth={1.5}
            />
            <p className="text-purple-600 font-medium">Per Installment</p>
            <p className="text-lg font-bold text-purple-800 mt-2">
              PKR{" "}
              {(totalPlotAmount / installments.length || 0).toLocaleString()}
            </p>
          </div>
        </div>

        {/* Tabs */}
        <div className="bg-white p-6">
          <div className="flex justify-between items-center">
            <div className="flex space-x-4">
              <button
                onClick={() => {
                  setActiveTab("completed");
                  setCurrentPage(1);
                }}
                className={`px-6 py-3 rounded-lg font-medium transition-all duration-300 ${
                  activeTab === "completed"
                    ? "bg-blue-600 text-white shadow-lg"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
              >
                Completed Installments
              </button>
              <button
                onClick={() => {
                  setActiveTab("pending");
                  setCurrentPage(1);
                }}
                className={`px-6 py-3 rounded-lg font-medium transition-all duration-300 ${
                  activeTab === "pending"
                    ? "bg-blue-600 text-white shadow-lg"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
              >
                Remaining Installments
              </button>
            </div>

            <div className="bg-blue-50/30 p-4">
              <button
                onClick={handlePrint}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center space-x-2 shadow-md"
              >
                <FileText className="w-5 h-5" strokeWidth={1.5} />
                <span>Print Summary</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-grow overflow-auto">
        <div className="min-w-full">
          <table className="w-full">
            <thead className="sticky top-0 bg-gray-50">
              <tr>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600">
                  No.
                </th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600">
                  Amount
                </th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600">
                  Date
                </th>
                <th className="px-6 py-4 text-left text-sm font-semibold text-gray-600">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {getCurrentInstallments().map((inst) => (
                <tr
                  key={inst.installment_number}
                  className="border-b border-gray-100 hover:bg-blue-50/50 transition-colors"
                >
                  <td className="px-6 py-4 text-gray-800">
                    {inst.installment_number}
                  </td>
                  <td className="px-6 py-4 text-gray-800">
                    PKR{" "}
                    {(activeTab === "completed"
                      ? inst.paid_amount
                      : inst.remaining_amount
                    ).toLocaleString()}
                  </td>
                  <td className="px-6 py-4 text-gray-800">
                    {new Date(
                      activeTab === "completed"
                        ? inst.payment_date
                        : inst.due_date
                    ).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-medium ${
                        inst.status === "Completed"
                          ? "bg-green-100 text-green-700"
                          : inst.status === "Pending"
                          ? "bg-yellow-100 text-yellow-700"
                          : "bg-red-100 text-red-700"
                      }`}
                    >
                      {inst.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Fixed Footer */}
      <div className="flex-none bg-white border-t border-gray-200">
        <div className="flex justify-between items-center p-4">
          <p className="text-sm text-gray-600">
            Showing page {currentPage} of {totalPages}
          </p>
          <div className="flex space-x-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="p-2 rounded-lg disabled:opacity-50 hover:bg-blue-100 transition-colors"
            >
              <ChevronLeft className="w-5 h-5 text-blue-600" />
            </button>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              className="p-2 rounded-lg disabled:opacity-50 hover:bg-blue-100 transition-colors"
            >
              <ChevronRight className="w-5 h-5 text-blue-600" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedInstallmentsPrint;
